import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducers from './rootReducers';

const combineRootReducers = (state, action) => {
  // Logout olduğunda store sıfırlanmaktadır.
  if (action.type === 'LOGOUT_BEGIN') {
    state = undefined;
  }
  return rootReducers(state, action);
};

const store =
  process.env.NODE_ENV === 'development'
    ? createStore(
        combineRootReducers,
        composeWithDevTools(applyMiddleware(thunk.withExtraArgument()))
      )
    : createStore(
        combineRootReducers,
        applyMiddleware(thunk.withExtraArgument())
      );

export default store;
