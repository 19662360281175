import axios from 'axios';
import Cookies from 'js-cookie';
import { logOut } from '../../redux/authentication/actionCreator';
import reduxStore from '../../redux/store';

const qs = require('qs');

export const API_ENDPOINT =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_ENDPOINT_LOCAL
    : process.env.REACT_APP_API_ENDPOINT_PROD;

const authHeader = () => ({
  Authorization: `Bearer ${Cookies.get('access_token')}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${Cookies.get('access_token')}`,
    'Content-Type': 'application/json',
  },
});

class DataService {
  static get(path = '', params, responseType) {
    /* versionCheck(); */
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      responseType: responseType,
    });
  }

  static post(
    path = '',
    data = {},
    params,
    optionalHeader = {},
    timeout,
    responseType
  ) {
    return client({
      method: 'POST',
      url: path,
      data,
      params,
      timeout: timeout,
      responseType: responseType,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}, params) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      params,
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}, params) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      params,
      headers: { ...authHeader() },
    });
  }

  static delete(path = '', params, data = {}) {
    return client({
      method: 'DELETE',
      url: path,
      params,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ${Cookies.get('access_token')}`,
  };

  return requestConfig;
});

client.interceptors.response.use(
  response => response,
  error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 401 && !originalRequest._alreadyRefreshed) {
        originalRequest._alreadyRefreshed = true;
        reduxStore.dispatch(logOut());
        Cookies.remove('version');
        return { status: 401, statusText: response.statusText };
      } else if (response.status === 400) {
        // Array Buffer Kontrolü Yapılmakta
        if (!response.data.byteLength)
          return {
            status: 400,
            statusText: response.data.message,
          };
        else {
          const parsedResponse = JSON.parse(
            new TextDecoder().decode(response.data)
          );
          return {
            status: 400,
            statusText: parsedResponse.message,
          };
        }
      } else if (response.status === 403) {
        return { status: 403, statusText: response.data.message };
      } else if (response.status === 404) {
        return { status: 404, statusText: response.data.message };
      } else if (response.status === 500) {
        // Array Buffer Kontrolü Yapılmakta
        if (!response.data.byteLength)
          return {
            status: 500,
            statusText: response.data.message,
          };
        else {
          const parsedResponse = JSON.parse(
            new TextDecoder().decode(response.data)
          );
          return {
            status: 500,
            statusText: parsedResponse.message,
          };
        }
      } else if (response.status === 409) {
        return { status: 409, statusText: response.data.message };
      } else {
        return originalRequest;
      }
    } else {
      return { status: 500, statusText: error.message || 'Network Error' };
    }
  }
);
export { DataService };
