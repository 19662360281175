export default {
  gitReportTitle: 'GIT Report Upload',
  gitMoves: 'GIT Moves',
  addNewDocument: 'Add New Document',
  selectUploadFile: 'Please Select a File For Upload or Drag a File',
  anyUploadError: ' {{fileName}} Document is not in the supported format!',
  anyUploadSuccess: '{{fileName}} Document is uploaded succesfully.',
  ggbsProductDeclarationNo: 'GGBS Product Declaration No',
  ggbsProductDeclarationPassword: 'GGBS Product Declaration Password',
  componentListForm: 'Component List Form',
  lastServiceRuntime: 'Last Service Runtime :',
  ggbsCustomsApplyNo: 'GGBS Customs Apply No :',
  ggbsCustomsApplyDate: 'GGBS Customs Apply Date :',
  healthCertificate: 'Health Certificate :',
  gdoDeclaration: 'GDO Declaration :',
  analyzeResult: 'Analysis Result :',
  shipmentNotificationForm: 'Shipment Notification Form :',
  etaUpdate: 'Update ETA',
  consignmentNoteTitle: 'Consignmentnote',
  consignmentNoteNo: 'Consignmentnote No:',
  paymentDeclaration: 'Payment Declaration :',
  paymentDeclarationNo: 'Transfer ID:',
};
