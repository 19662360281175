import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
// import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Home = lazy(() => import('../../screen/Home/index'));
const Setting = lazy(() => import('../../screen/Setting/index'));

const DashboardRoutes = () => {
  return (
    <Switch>
      <Route exact path={'/'} component={Home} />
      <Route exact path={'/setting'} component={Setting} />
    </Switch>
  );
};

export default DashboardRoutes;
