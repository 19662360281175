import actions from './actions';

const {
  GET_DASHBOARDS_BEGIN,
  GET_DASHBOARDS_SUCCESS,
  GET_DASHBOARDS_ERROR,
  GET_INVOICESDATA_BEGIN,
  GET_INVOICESDATA_SUCCESS,
  GET_INVOICESDATA_ERROR,
  GET_SHIPMENTDATA_BEGIN,
  GET_SHIPMENTDATA_ERROR,
  GET_SHIPMENTDATA_SUCCESS,
  GET_PRODUCTDECLARATION_BEGIN,
  GET_PRODUCTDECLARATION_ERROR,
  GET_PRODUCTDECLARATION_SUCCESS,
  GET_ETA_BEGIN,
  GET_ETA_ERROR,
  GET_ETA_SUCCESS,
  GET_TALEP_BEGIN,
  GET_TALEP_ERROR,
  GET_TALEP_SUCCESS,
  GET_PRODUCT_BEGIN,
  GET_PRODUCT_ERROR,
  GET_PRODUCT_SUCCESS,
  GET_NEW_ORDERS_WIDGET_BEGIN,
  GET_NEW_ORDERS_WIDGET_ERROR,
  GET_NEW_ORDERS_WIDGET_SUCCESS,
  GET_DELAYED_ORDERS_WIDGET_BEGIN,
  GET_DELAYED_ORDERS_WIDGET_SUCCESS,
  GET_DELAYED_ORDERS_WIDGET_ERROR,
  GET_UNAPPROVED_PRODUCTS_WIDGET_BEGIN,
  GET_UNAPPROVED_PRODUCTS_WIDGET_SUCCESS,
  GET_UNAPPROVED_PRODUCTS_WIDGET_ERROR,
  GET_EXPIRED_DOCUMENTS_WIDGET_BEGIN,
  GET_EXPIRED_DOCUMENTS_WIDGET_SUCCESS,
  GET_EXPIRED_DOCUMENTS_WIDGET_ERROR,
  GET_ORDERS_RATE_CHART_BEGIN,
  GET_ORDERS_RATE_CHART_SUCCESS,
  GET_ORDERS_RATE_CHART_ERROR,
  GET_RATE_OF_VENDORS_CHART_BEGIN,
  GET_RATE_OF_VENDORS_CHART_SUCCESS,
  GET_RATE_OF_VENDORS_CHART_ERROR,
  GET_MOST_ORDERED_PRODUCTS_BEGIN,
  GET_MOST_ORDERED_PRODUCTS_SUCCESS,
  GET_MOST_ORDERED_PRODUCTS_ERROR,
  GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_BEGIN,
  GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_SUCCESS,
  GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_ERROR,
} = actions;

const initState = {
  loading: false,
  invoiceLoading: false,
  shipmentLoading: false,
  productDeclarationLoading: false,
  etaLoading: false,
  talepLoading: false,
  productLoading: false,
  dashboards: [],
  error: null,
  invoiceError: null,
  shipmentError: null,
  productDeclarationError: null,
  etaError: null,
  talepError: null,
  productError: null,
  invoices: {},
  shipment: {},
  productDeclaration: {},
  eta: {},
  talep: {},
  product: {},
  newOrders: {},
  delayedOrders: {},
  unapprovedProducts: {},
  expiredDocuments: {},
  ordersRate: {},
  rateOfVendors: {},
  orderedProducts: [],
  delayedDepartureAndArrival: [],
};

const dashboardsReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_INVOICESDATA_BEGIN:
      return {
        ...state,
        invoiceLoading: true,
        invoiceError: null,
      };
    case GET_SHIPMENTDATA_BEGIN:
      return {
        ...state,
        shipmentLoading: true,
        shipmentError: null,
      };
    case GET_PRODUCTDECLARATION_BEGIN:
      return {
        ...state,
        productDeclarationLoading: true,
        productDeclarationError: null,
      };
    case GET_ETA_BEGIN:
      return {
        ...state,
        etaLoading: true,
        etaError: null,
      };
    case GET_TALEP_BEGIN:
      return {
        ...state,
        talepLoading: true,
        talepError: null,
      };
    case GET_DASHBOARDS_BEGIN:
    case GET_NEW_ORDERS_WIDGET_BEGIN:
    case GET_DELAYED_ORDERS_WIDGET_BEGIN:
    case GET_UNAPPROVED_PRODUCTS_WIDGET_BEGIN:
    case GET_MOST_ORDERED_PRODUCTS_BEGIN:
    case GET_ORDERS_RATE_CHART_BEGIN:
    case GET_RATE_OF_VENDORS_CHART_BEGIN:
    case GET_EXPIRED_DOCUMENTS_WIDGET_BEGIN:
    case GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PRODUCT_BEGIN:
      return {
        ...state,
        productLoading: true,
        productError: null,
      };
    case GET_DASHBOARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboards: data,
      };
    case GET_NEW_ORDERS_WIDGET_SUCCESS:
      return {
        ...state,
        loading: false,
        newOrders: data,
      };
    case GET_DELAYED_ORDERS_WIDGET_SUCCESS:
      return {
        ...state,
        loading: false,
        delayedOrders: data,
      };
    case GET_UNAPPROVED_PRODUCTS_WIDGET_SUCCESS:
      return {
        ...state,
        loading: false,
        unapprovedProducts: data,
      };
    case GET_MOST_ORDERED_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderedProducts: data,
      };
    case GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        delayedDepartureAndArrival: data,
      };
    case GET_ORDERS_RATE_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        ordersRate: data,
      };
    case GET_RATE_OF_VENDORS_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        rateOfVendors: data,
      };
    case GET_EXPIRED_DOCUMENTS_WIDGET_SUCCESS:
      return {
        ...state,
        loading: false,
        expiredDocuments: data,
      };
    case GET_INVOICESDATA_SUCCESS:
      return {
        ...state,
        invoiceLoading: false,
        invoices: data,
      };
    case GET_SHIPMENTDATA_SUCCESS:
      return {
        ...state,
        shipmentLoading: false,
        shipment: data,
      };
    case GET_PRODUCTDECLARATION_SUCCESS:
      return {
        ...state,
        productDeclarationLoading: false,
        productDeclaration: data,
      };
    case GET_ETA_SUCCESS:
      return {
        ...state,
        etaLoading: false,
        eta: data,
      };
    case GET_TALEP_SUCCESS:
      return {
        ...state,
        talepLoading: false,
        talep: data,
      };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        productLoading: false,
        product: data,
      };
    case GET_DASHBOARDS_ERROR:
      return {
        ...state,
        dashboards: [],
        loading: false,
        error: err,
      };
    case GET_INVOICESDATA_ERROR:
      return {
        ...state,
        invoices: {},
        invoiceLoading: false,
        invoiceError: err,
      };
    case GET_SHIPMENTDATA_ERROR:
      return {
        ...state,
        shipmentLoading: false,
        shipmentError: err,
        shipment: {},
      };
    case GET_PRODUCTDECLARATION_ERROR:
      return {
        ...state,
        productDeclarationLoading: false,
        productDeclarationError: err,
        productDeclaration: {},
      };
    case GET_ETA_ERROR:
      return {
        ...state,
        etaLoading: false,
        etaError: err,
        eta: {},
      };
    case GET_TALEP_ERROR:
      return {
        ...state,
        talepLoading: false,
        talepError: err,
        talep: {},
      };
    case GET_PRODUCT_ERROR:
      return {
        ...state,
        productLoading: false,
        productError: err,
        product: {},
      };
    case GET_NEW_ORDERS_WIDGET_ERROR:
      return {
        ...state,
        newOrders: [],
        loading: false,
        error: err,
      };
    case GET_DELAYED_ORDERS_WIDGET_ERROR:
      return {
        ...state,
        delayedOrders: [],
        loading: false,
        error: err,
      };
    case GET_UNAPPROVED_PRODUCTS_WIDGET_ERROR:
      return {
        ...state,
        unapprovedProducts: [],
        loading: false,
        error: err,
      };
    case GET_MOST_ORDERED_PRODUCTS_ERROR:
      return {
        ...state,
        orderedProducts: [],
        loading: false,
        error: err,
      };
    case GET_EXPIRED_DOCUMENTS_WIDGET_ERROR:
      return {
        ...state,
        expiredDocuments: [],
        loading: false,
        error: err,
      };
    case GET_ORDERS_RATE_CHART_ERROR:
      return {
        ...state,
        ordersRate: [],
        loading: false,
        error: err,
      };
    case GET_RATE_OF_VENDORS_CHART_ERROR:
      return {
        ...state,
        rateOfVendors: [],
        loading: false,
        error: err,
      };
    case GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_ERROR:
      return {
        ...state,
        delayedDepartureAndArrival: [],
        loading: false,
        error: err,
      };
    default:
      return state;
  }
};

export { dashboardsReducer };
