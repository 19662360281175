import { combineReducers } from 'redux';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { userReducer } from './users/reducers';
import { reportReducer } from './report/reducers';
import { exportReducer } from './export/reducers';
import { acenteReducer } from './acente/reducers';
import { importReducer } from './import/reducers';
import { antrepoStokReducer } from './antrepoStok/reducers';
import { dashboardsReducer } from './dashboard/reducers';
import { commonReducer } from './common/reducers';
import { sbReducer } from './sb/reducers';
//import chartContentReducer from './chartContent/reducers';

// import { headerSearchReducer } from './headerSearch/reducers';

const rootReducers = combineReducers({
  // headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  users: userReducer,
  auth: authReducer,
  reportReducer: reportReducer,
  export: exportReducer,
  acente: acenteReducer,
  import: importReducer,
  antrepoStok: antrepoStokReducer,
  dashboard: dashboardsReducer,
  common: commonReducer,
  sb: sbReducer,
  ChangeLayoutMode,
});

export default rootReducers;
