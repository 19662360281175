import { lazy } from 'react';

const soList = lazy(() => import('../../../screen/AntrepoStok/SOList/SOList'));

const GitReport = lazy(() =>
  import('../../../screen/AntrepoStok/GitReport/GitReport')
);

const GitMoves = lazy(() =>
  import('../../../screen/AntrepoStok/GitReportMoves/GitReportMoves')
);

const GitInvoices = lazy(() =>
  import('../../../screen/AntrepoStok/GitInvoicesList/GitInvoicesList')
);

const GitMovesDetail = lazy(() =>
  import(
    '../../../screen/AntrepoStok/GitReportMovesDetail/GitReportMovesDetail'
  )
);

const GitInvoicesDetail = lazy(() =>
  import('../../../screen/AntrepoStok/GitInvoicesSettings/GitInvoicesSettings')
);

const ProductDeclaration = lazy(() =>
  import('../../../screen/AntrepoStok/ProductDeclaration/ProductDeclaration')
);

const ProductDeclarationDetail = lazy(() =>
  import(
    '../../../screen/AntrepoStok/ProductDeclarationDetail/ProductDeclarationDetail'
  )
);

const ShipmentDeclarationList = lazy(() =>
  import(
    '../../../screen/AntrepoStok/ShipmentDeclarationList/ShipmentDeclarationList'
  )
);

const BeyannameSorgula = lazy(() =>
  import('../../../screen/AntrepoStok/BeyannameSorgula/BeyannameSorgula')
);

const ShipmentDeclarationDetail = lazy(() =>
  import('../../../screen/AntrepoStok/ShipmentDeclaration/ShipmentDeclaration')
);

const ProductList = lazy(() =>
  import('../../../screen/AntrepoStok/ProductList/ProductList')
);

const PaymentDeclaration = lazy(() =>
  import('../../../screen/AntrepoStok/PaymentDeclaration/PaymentDeclaration')
);

const ProductDetail = lazy(() =>
  import('../../../screen/AntrepoStok/ProductDetail/ProductDetail')
);

const AddProduct = lazy(() =>
  import('../../../screen/AntrepoStok/AddProduct/AddProduct')
);

const ConsignmentNote = lazy(() =>
  import('../../../screen/AntrepoStok/ConsignmentNote/ConsignmentNote')
);

const AntrepoBeyannameler = lazy(() =>
  import('../../../screen/AntrepoStok/AntrepoBeyannameler/AntrepoBeyannameler')
);

const AntrepoStokRaporu = lazy(() =>
  import('../../../screen/AntrepoStok/AntrepoStokRaporu/AntrepoStokRaporu')
);

const IthalatBeyannameleri = lazy(() =>
  import(
    '../../../screen/AntrepoStok/IthalatBeyannameleri/IthalatBeyannameleri'
  )
);

const AntrepoStokTalep = lazy(() =>
  import('../../../screen/AntrepoStok/AntrepoStokTalep/AntrepoStokTalep')
);

const AntrepoStokTalepListesi = lazy(() =>
  import(
    '../../../screen/AntrepoStok/AntrepoStokTalepListesi/AntrepoStokTalepListesi'
  )
);

const AntrepoStokTalepYukle = lazy(() =>
  import(
    '../../../screen/AntrepoStok/AntrepoStokTalepYukle/AntrepoStokTalepYukle'
  )
);

const BeyannameDetaySorgula = lazy(() =>
  import(
    '../../../screen/AntrepoStok/BeyannameDetaySorgula/BeyannameDetaySorgula'
  )
);

const GenelRaporlar = lazy(() =>
  import('../../../screen/AntrepoStok/GenelRaporlar/GenelRaporlar')
);

const GenelRaporlarDetaylar = lazy(() =>
  import('../../../screen/AntrepoStok/GenelRaporlarDetay/GenelRaporlarDetay')
);

const GtipTanimlari = lazy(() =>
  import('../../../screen/AntrepoStok/GtipScreen/GtipScreen')
);

const GtipDetaylari = lazy(() =>
  import('../../../screen/AntrepoStok/GtipDetaylari/GtipDetaylari')
);

const SoBeyannameOnizleme = lazy(() =>
  import('../../../screen/AntrepoStok/BeyanDetayOnizleme/TalepBeyanOnizleme')
);

const EskiSolar = lazy(() =>
  import('../../../screen/AntrepoStok/OldSoList/OldSoList')
);

const PlannedJobList = lazy(() =>
  import('../../../screen/DemoSunChemical/PlannedJobList/PlannedJobList')
);

const AddPlanedJob = lazy(() =>
  import('../../../screen/DemoSunChemical/NewPlannedJob/NewPlannedJob')
);

const ContainerTracking = lazy(() =>
  import('../../../screen/DemoSunChemical/ContainerTracking/ContainerTracking')
);

const DemoExcel = lazy(() =>
  import('../../../screen/DemoSunChemical/DemoExcel/DemoExcel')
);

const DocumentTracking = lazy(() =>
  import('../../../screen/AntrepoStok/DocumentTracking/DocumentTracking')
);

const DocumentTrackingNew = lazy(() =>
  import('../../../screen/AntrepoStok/DocumentTrackingNew/DocumentTrackingNew')
);

const DocumentTrackingDetail = lazy(() =>
  import(
    '../../../screen/AntrepoStok/DocumentTrackingDetail/DocumentTrackingDetail'
  )
);

const Order = lazy(() => import('../../../screen/AntrepoStok/Order/Order'));

const OrderList = lazy(() =>
  import('../../../screen/AntrepoStok/OrderList/OrderList')
);

const GeciciIthalat = lazy(() =>
  import('../../../screen/AntrepoStok/GeciciIthalat/GeciciIthalat')
);

const IhbarTespit = lazy(() =>
  import('../../../screen/AntrepoStok/IhbarTespit/IhbarTespit')
);

const OrderMasraf = lazy(() =>
  import('../../../screen/AntrepoStok/OrderMasraf/OrderMasraf')
);

const ProductTariffList = lazy(() =>
  import('../../../screen/AntrepoStok/ProductTariffList/ProductTariffList')
);

const ProductTariffUpdateScreen = lazy(() =>
  import(
    '../../../screen/AntrepoStok/ProductTariffUpdateScreen/ProductTariffUpdateScreen'
  )
);

const IthalatBeyannamesi = lazy(() =>
  import('../../../screen/AntrepoStok/Beyanname/IthalatBeyannamesi.js')
);

const IthalatListe = lazy(() =>
  import('../../../screen/AntrepoStok/Beyanname/IthalatListe.js')
);

export {
  soList,
  GitReport,
  GitMoves,
  GitInvoices,
  GitMovesDetail,
  GitInvoicesDetail,
  ProductDeclaration,
  ShipmentDeclarationDetail,
  ProductDeclarationDetail,
  ShipmentDeclarationList,
  ProductList,
  ProductDetail,
  ConsignmentNote,
  AntrepoBeyannameler,
  AntrepoStokRaporu,
  IthalatBeyannameleri,
  AntrepoStokTalep,
  AntrepoStokTalepListesi,
  AntrepoStokTalepYukle,
  PaymentDeclaration,
  BeyannameDetaySorgula,
  BeyannameSorgula,
  GenelRaporlar,
  GenelRaporlarDetaylar,
  GtipTanimlari,
  GtipDetaylari,
  SoBeyannameOnizleme,
  EskiSolar,
  PlannedJobList,
  AddPlanedJob,
  ContainerTracking,
  DemoExcel,
  AddProduct,
  DocumentTracking,
  DocumentTrackingNew,
  DocumentTrackingDetail,
  Order,
  OrderList,
  GeciciIthalat,
  IhbarTespit,
  OrderMasraf,
  ProductTariffList,
  ProductTariffUpdateScreen,
  IthalatBeyannamesi,
  IthalatListe,
};
