import { DataService } from '../../config/dataService/dataService';
import { Endpoints } from '../../config/Endpoints';
import actions from './actions';

const {
  getMenuBegin,
  getMenuSuccess,
  getMenuError,
  getRoleBegin,
  getRoleSuccess,
  getRoleError,
  getUserBegin,
  getUserSuccess,
  getUserError,
} = actions;

const getMenu = () => {
  return async dispatch => {
    dispatch(getMenuBegin());
    const response = await DataService.get(Endpoints.MenuList);
    if (response.status === 200) {
      dispatch(getMenuSuccess(response.data));
    } else {
      dispatch(getMenuError(response));
    }
  };
};

const getRole = () => {
  return async dispatch => {
    dispatch(getRoleBegin());
    const response = await DataService.get(Endpoints.GetRole);
    if (response.status === 200) {
      dispatch(getRoleSuccess(response.data));
    } else {
      dispatch(getRoleError());
    }
  };
};

const getUser = () => {
  return async dispatch => {
    dispatch(getUserBegin());
    const response = await DataService.get(Endpoints.GetUser);
    if (response.status === 200) {
      dispatch(getUserSuccess(response.data));
    } else {
      dispatch(getUserError());
    }
  };
};

export { getMenu, getRole, getUser };
