const actions = {
  MERGE_FILE_BEGIN: 'MERGE_FILE_BEGIN',
  MERGE_FILE_SUCCESS: 'MERGE_FILE_SUCCESS',
  MERGE_FILE_ERROR: 'MERGE_FILE_ERROR',

  ADD_IMPORT_BEGIN: 'ADD_IMPORT_BEGIN',
  ADD_IMPORT_SUCCESS: 'ADD_IMPORT_SUCCESS',
  ADD_IMPORT_ERROR: 'ADD_IMPORT_ERROR',

  GET_IMPORT_BEGIN: 'GET_IMPORT_BEGIN',
  GET_IMPORT_SUCCESS: 'GET_IMPORT_SUCCESS',
  GET_IMPORT_ERROR: 'GET_IMPORT_ERROR',

  UPLOAD_FILE_BEGIN: 'UPLOAD_FILE_BEGIN',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: 'UPLOAD_FILE_ERROR',

  DOWNLOAD_IMPORT_BEGIN: 'DOWNLOAD_IMPORT_BEGIN',
  DOWNLOAD_IMPORT_SUCCESS: 'DOWNLOAD_IMPORT_SUCCESS',
  DOWNLOAD_IMPORT_ERROR: 'DOWNLOAD_IMPORT_ERROR',

  REGISTRY_IMPORT_BEGIN: 'REGISTRY_IMPORT_BEGIN',
  REGISTRY_IMPORT_SUCCESS: 'REGISTRY_IMPORT_SUCCESS',
  REGISTRY_IMPORT_ERROR: 'REGISTRY_IMPORT_ERROR',

  DELETE_REGISTRY_IMPORT_BEGIN: 'DELETE_REGISTRY_IMPORT_BEGIN',
  DELETE_REGISTRY_IMPORT_SUCCESS: 'DELETE_REGISTRY_IMPORT_SUCCESS',
  DELETE_REGISTRY_IMPORT_ERROR: 'DELETE_REGISTRY_IMPORT_ERROR',

  DELETE_IMPORT_BEGIN: 'DELETE_IMPORT_BEGIN',
  DELETE_IMPORT_SUCCESS: 'DELETE_IMPORT_SUCCESS',
  DELETE_IMPORT_ERROR: 'DELETE_IMPORT_ERROR',

  GET_CONTAINER_MOVE_BEGIN: 'GET_CONTAINER_MOVE_BEGIN',
  GET_CONTAINER_MOVE_SUCCESS: 'GET_CONTAINER_MOVE_SUCCESS',
  GET_CONTAINER_MOVE_ERROR: 'GET_CONTAINER_MOVE_ERROR',

  GET_EXIT_IMPORT_BEGIN: 'GET_EXIT_IMPORT_BEGIN',
  GET_EXIT_IMPORT_SUCCESS: 'GET_EXIT_IMPORT_SUCCESS',
  GET_EXIT_IMPORT_ERROR: 'GET_EXIT_IMPORT_ERROR',

  ADD_NOT_MATCH_IMPORT_BEGIN: 'ADD_NOT_MATCH_IMPORT_BEGIN',
  ADD_NOT_MATCH_IMPORT_SUCCESS: 'ADD_NOT_MATCH_IMPORT_SUCCESS',
  ADD_NOT_MATCH_IMPORT_ERROR: 'ADD_NOT_MATCH_IMPORT_ERROR',

  REGISTRY_EXIT_IMPORT_BEGIN: 'REGISTRY_EXIT_IMPORT_BEGIN',
  REGISTRY_EXIT_IMPORT_SUCCESS: 'REGISTRY_EXIT_IMPORT_SUCCESS',
  REGISTRY_EXIT_IMPORT_ERROR: 'REGISTRY_EXIT_IMPORT_ERROR',

  ADD_NOT_MATCH_CONTAINER_BEGIN: 'ADD_NOT_MATCH_CONTAINER_BEGIN',
  ADD_NOT_MATCH_CONTAINER_SUCCESS: 'ADD_NOT_MATCH_CONTAINER_SUCCESS',
  ADD_NOT_MATCH_CONTAINER_ERROR: 'ADD_NOT_MATCH_CONTAINER_ERROR',

  PATCH_OZET_BEYAN_BEGIN: 'PATCH_OZET_BEYAN_BEGIN',
  PATCH_OZET_BEYAN_SUCCESS: 'PATCH_OZET_BEYAN_SUCCESS',
  PATCH_OZET_BEYAN_ERROR: 'PATCH_OZET_BEYAN_ERROR',

  GET_ROAD_IMPORT_BEGIN: 'GET_ROAD_IMPORT_BEGIN',
  GET_ROAD_IMPORT_SUCCESS: 'GET_ROAD_IMPORT_SUCCESS',
  GET_ROAD_IMPORT_ERROR: 'GET_ROAD_IMPORT_ERROR',

  ADD_ROAD_IMPORT_BEGIN: 'ADD_ROAD_IMPORT_BEGIN',
  ADD_ROAD_IMPORT_SUCCESS: 'ADD_ROAD_IMPORT_SUCCESS',
  ADD_ROAD_IMPORT_ERROR: 'ADD_ROAD_IMPORT_ERROR',

  DOWNLOAD_ROAD_IMPORT_BEGIN: 'DOWNLOAD_ROAD_IMPORT_BEGIN',
  DOWNLOAD_ROAD_IMPORT_SUCCESS: 'DOWNLOAD_ROAD_IMPORT_SUCCESS',
  DOWNLOAD_ROAD_IMPORT_ERROR: 'DOWNLOAD_ROAD_IMPORT_ERROR',

  PATCH_CONTAINER_COMMENT_BEGIN: 'PATCH_CONTAINER_COMMENT_BEGIN',
  PATCH_CONTAINER_COMMENT_SUCCESS: 'PATCH_CONTAINER_COMMENT_SUCCESS',
  PATCH_CONTAINER_COMMENT_ERROR: 'PATCH_CONTAINER_COMMENT_ERROR',

  PATCH_CONTAINER_TIME_BEGIN: 'PATCH_CONTAINER_TIME_BEGIN',
  PATCH_CONTAINER_TIME_SUCCESS: 'PATCH_CONTAINER_TIME_SUCCESS',
  PATCH_CONTAINER_TIME_ERROR: 'PATCH_CONTAINER_TIME_ERROR',

  GET_RULES_IMPORT_BEGIN: 'GET_RULES_IMPORT_BEGIN',
  GET_RULES_IMPORT_SUCCESS: 'GET_RULES_IMPORT_SUCCESS',
  GET_RULES_IMPORT_ERROR: 'GET_RULES_IMPORT_ERROR',

  GET_TURLIM_LIST_BEGIN: 'GET_TURLIM_LIST_BEGIN',
  GET_TURLIM_LIST_SUCCESS: 'GET_TURLIM_LIST_SUCCESS',
  GET_TURLIM_LIST_ERROR: 'GET_TURLIM_LIST_ERROR',

  PATCH_LINE_BEGIN: 'PATCH_LINE_BEGIN',
  PATCH_LINE_SUCCESS: 'PATCH_LINE_SUCCESS',
  PATCH_LINE_ERROR: 'PATCH_LINE_ERROR',

  GET_IMPORT_CONTAINER_BEGIN: 'GET_IMPORT_CONTAINER_BEGIN',
  GET_IMPORT_CONTAINER_SUCCESS: 'GET_IMPORT_CONTAINER_SUCCESS',
  GET_IMPORT_CONTAINER_ERROR: 'GET_IMPORT_CONTAINER_ERROR',

  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',

  mergeFileBegin: () => {
    return {
      type: actions.MERGE_FILE_BEGIN,
    };
  },

  mergeFileSuccess: data => {
    return {
      type: actions.MERGE_FILE_SUCCESS,
      data,
    };
  },

  mergeFileError: err => {
    return {
      type: actions.MERGE_FILE_ERROR,
      err,
    };
  },

  addImportBegin: () => {
    return {
      type: actions.ADD_IMPORT_BEGIN,
    };
  },

  addImportSuccess: () => {
    return {
      type: actions.ADD_IMPORT_SUCCESS,
    };
  },

  addImportError: err => {
    return {
      type: actions.ADD_IMPORT_ERROR,
      err,
    };
  },

  getImportBegin: () => {
    return {
      type: actions.GET_IMPORT_BEGIN,
    };
  },

  getImportSuccess: data => {
    return {
      type: actions.GET_IMPORT_SUCCESS,
      data,
    };
  },

  getImportError: err => {
    return {
      type: actions.GET_IMPORT_ERROR,
      err,
    };
  },

  downloadImportBegin: () => {
    return {
      type: actions.DOWNLOAD_IMPORT_BEGIN,
    };
  },

  downloadImportSuccess: data => {
    return {
      type: actions.DOWNLOAD_IMPORT_SUCCESS,
      data,
    };
  },

  downloadImportError: err => {
    return {
      type: actions.DOWNLOAD_IMPORT_ERROR,
      err,
    };
  },

  downloadRoadImportBegin: () => {
    return {
      type: actions.DOWNLOAD_ROAD_IMPORT_BEGIN,
    };
  },

  downloadRoadImportSuccess: data => {
    return {
      type: actions.DOWNLOAD_ROAD_IMPORT_SUCCESS,
      data,
    };
  },

  downloadRoadImportError: err => {
    return {
      type: actions.DOWNLOAD_ROAD_IMPORT_ERROR,
      err,
    };
  },

  registryImportBegin: () => {
    return {
      type: actions.REGISTRY_IMPORT_BEGIN,
    };
  },

  registryImportSuccess: data => {
    return {
      type: actions.REGISTRY_IMPORT_SUCCESS,
      data,
    };
  },

  registryImportError: err => {
    return {
      type: actions.REGISTRY_IMPORT_ERROR,
      err,
    };
  },

  deleteImportBegin: () => {
    return {
      type: actions.DELETE_IMPORT_BEGIN,
    };
  },

  deleteImportSuccess: data => {
    return {
      type: actions.DELETE_IMPORT_SUCCESS,
      data,
    };
  },

  deleteImportError: err => {
    return {
      type: actions.DELETE_IMPORT_ERROR,
      err,
    };
  },

  deleteRegistryImportBegin: () => {
    return {
      type: actions.DELETE_REGISTRY_IMPORT_BEGIN,
    };
  },

  deleteRegistryImportSuccess: data => {
    return {
      type: actions.DELETE_REGISTRY_IMPORT_SUCCESS,
      data,
    };
  },

  deleteRegistryImportError: err => {
    return {
      type: actions.DELETE_REGISTRY_IMPORT_ERROR,
      err,
    };
  },

  getExitImportBegin: () => {
    return {
      type: actions.GET_EXIT_IMPORT_BEGIN,
    };
  },

  getExitImportSuccess: data => {
    return {
      type: actions.GET_EXIT_IMPORT_SUCCESS,
      data,
    };
  },

  getExitImportError: err => {
    return {
      type: actions.GET_EXIT_IMPORT_ERROR,
      err,
    };
  },

  addNotMatchImportBegin: () => {
    return {
      type: actions.ADD_NOT_MATCH_IMPORT_BEGIN,
    };
  },

  addNotMatchImportSuccess: data => {
    return {
      type: actions.ADD_NOT_MATCH_IMPORT_SUCCESS,
      data,
    };
  },

  addNotMatchImportError: err => {
    return {
      type: actions.ADD_NOT_MATCH_IMPORT_ERROR,
      err,
    };
  },

  registryExitImportBegin: () => {
    return {
      type: actions.REGISTRY_EXIT_IMPORT_BEGIN,
    };
  },

  registryExitImportSuccess: data => {
    return {
      type: actions.REGISTRY_EXIT_IMPORT_SUCCESS,
      data,
    };
  },

  registryExitImportError: err => {
    return {
      type: actions.REGISTRY_EXIT_IMPORT_ERROR,
      err,
    };
  },

  getContainerMoveBegin: () => {
    return {
      type: actions.GET_CONTAINER_MOVE_BEGIN,
    };
  },

  getContainerMoveSuccess: (data, page) => {
    return {
      type: actions.GET_CONTAINER_MOVE_SUCCESS,
      data,
      page,
    };
  },

  getContainerMoveError: err => {
    return {
      type: actions.GET_CONTAINER_MOVE_ERROR,
      err,
    };
  },

  patchOzetBeyanBegin: () => {
    return {
      type: actions.PATCH_OZET_BEYAN_BEGIN,
    };
  },

  patchOzetBeyanSuccess: data => {
    return {
      type: actions.PATCH_OZET_BEYAN_SUCCESS,
      data,
    };
  },

  patchOzetBeyanError: err => {
    return {
      type: actions.PATCH_OZET_BEYAN_ERROR,
      err,
    };
  },

  getRoadImportBegin: () => {
    return {
      type: actions.GET_ROAD_IMPORT_BEGIN,
    };
  },

  getRoadImportSuccess: data => {
    return {
      type: actions.GET_ROAD_IMPORT_SUCCESS,
      data,
    };
  },

  getRoadImportError: err => {
    return {
      type: actions.GET_ROAD_IMPORT_ERROR,
      err,
    };
  },

  addRoadImportBegin: () => {
    return {
      type: actions.ADD_ROAD_IMPORT_BEGIN,
    };
  },

  addRoadImportSuccess: data => {
    return {
      type: actions.ADD_ROAD_IMPORT_SUCCESS,
      data,
    };
  },

  addRoadImportError: err => {
    return {
      type: actions.ADD_ROAD_IMPORT_ERROR,
      err,
    };
  },

  patchContainerCommentBegin: () => {
    return {
      type: actions.PATCH_CONTAINER_COMMENT_BEGIN,
    };
  },

  patchContainerCommentSuccess: data => {
    return {
      type: actions.PATCH_CONTAINER_COMMENT_SUCCESS,
      data,
    };
  },

  patchContainerCommentError: err => {
    return {
      type: actions.PATCH_CONTAINER_COMMENT_ERROR,
      err,
    };
  },

  patchContainerTimeBegin: () => {
    return {
      type: actions.PATCH_CONTAINER_TIME_BEGIN,
    };
  },

  patchContainerTimeSuccess: data => {
    return {
      type: actions.PATCH_CONTAINER_TIME_SUCCESS,
      data,
    };
  },

  patchContainerTimeError: err => {
    return {
      type: actions.PATCH_CONTAINER_TIME_ERROR,
      err,
    };
  },

  getRulesImportBegin: () => {
    return {
      type: actions.GET_RULES_IMPORT_BEGIN,
    };
  },

  getRulesImportSuccess: data => {
    return {
      type: actions.GET_RULES_IMPORT_SUCCESS,
      data,
    };
  },

  getRulesImportError: err => {
    return {
      type: actions.GET_RULES_IMPORT_ERROR,
      err,
    };
  },

  uploadFileBegin: () => {
    return {
      type: actions.UPLOAD_FILE_BEGIN,
    };
  },

  uploadFileSuccess: data => {
    return {
      type: actions.UPLOAD_FILE_SUCCESS,
      data,
    };
  },

  uploadFileError: err => {
    return {
      type: actions.UPLOAD_FILE_ERROR,
      err,
    };
  },

  getTurlimBegin: () => {
    return {
      type: actions.GET_TURLIM_LIST_BEGIN,
    };
  },

  getTurlimSuccess: data => {
    return {
      type: actions.GET_TURLIM_LIST_SUCCESS,
      data,
    };
  },

  getTurlimError: err => {
    return {
      type: actions.GET_TURLIM_LIST_ERROR,
      err,
    };
  },

  getImportContainerBegin: () => {
    return {
      type: actions.GET_IMPORT_CONTAINER_BEGIN,
    };
  },

  getImportContainerSuccess: data => {
    return {
      type: actions.GET_IMPORT_CONTAINER_SUCCESS,
      data,
    };
  },

  getImportContainerError: err => {
    return {
      type: actions.GET_IMPORT_CONTAINER_ERROR,
      err,
    };
  },

  patchLineBegin: () => {
    return {
      type: actions.PATCH_LINE_BEGIN,
    };
  },

  patchLineSuccess: data => {
    return {
      type: actions.PATCH_LINE_SUCCESS,
      data,
    };
  },

  patchLineError: err => {
    return {
      type: actions.PATCH_LINE_ERROR,
      err,
    };
  },

  resetErrorMessage: () => {
    return {
      type: actions.RESET_ERROR_MESSAGE,
    };
  },
};

export default actions;
