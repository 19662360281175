const actions = {
  GET_TASK_TRACKING_BEGIN: 'GET_TASK_TRACKING_BEGIN',
  GET_TASK_TRACKING_SUCCESS: 'GET_TASK_TRACKING_SUCCESS',
  GET_TASK_TRACKING_ERROR: 'GET_TASK_TRACKING_ERROR',

  POST_TASK_TRACKING_BEGIN: 'POST_TASK_TRACKING_BEGIN',
  POST_TASK_TRACKING_SUCCESS: 'POST_TASK_TRACKING_SUCCESS',
  POST_TASK_TRACKING_ERROR: 'POST_TASK_TRACKING_ERROR',

  UPDATE_TASK_TRACKING_BEGIN: 'UPDATE_TASK_TRACKING_BEGIN',
  UPDATE_TASK_TRACKING_SUCCESS: 'UPDATE_TASK_TRACKING_SUCCESS',
  UPDATE_TASK_TRACKING_ERROR: 'UPDATE_TASK_TRACKING_ERROR',

  CREATE_USER_BEGIN: 'CREATE_USER_BEGIN',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',

  PATCH_USER_STATUS_BEGIN: 'PATCH_USER_STATUS_BEGIN',
  PATCH_USER_STATUS_SUCCESS: 'PATCH_USER_STATUS_SUCCESS',
  PATCH_USER_STATUS_ERROR: 'PATCH_USER_STATUS_ERROR',

  PATCH_RESET_PASSWORD_BEGIN: 'PATCH_RESET_PASSWORD_BEGIN',
  PATCH_RESET_PASSWORD_SUCCESS: 'PATCH_RESET_PASSWORD_SUCCESS',
  PATCH_RESET_PASSWORD_ERROR: 'PATCH_RESET_PASSWORD_ERROR',

  GET_USERS_BEGIN: 'GET_USERS_BEGIN',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_ERROR: 'GET_USERS_ERROR',

  GET_TASK_TRACKING_MOVE_BEGIN: 'GET_TASK_TRACKING_MOVE_BEGIN',
  GET_TASK_TRACKING_MOVE_SUCCESS: 'GET_TASK_TRACKING_MOVE_SUCCESS',
  GET_TASK_TRACKING_MOVE_ERROR: 'GET_TASK_TRACKING_MOVE_ERROR',

  POST_TASK_TRACKING_MOVE_BEGIN: 'POST_TASK_TRACKING_MOVE_BEGIN',
  POST_TASK_TRACKING_MOVE_SUCCESS: 'POST_TASK_TRACKING_MOVE_SUCCESS',
  POST_TASK_TRACKING_MOVE_ERROR: 'POST_TASK_TRACKING_MOVE_ERROR',

  GET_PUBLIC_HOLIDAYS_BEGIN: 'GET_PUBLIC_HOLIDAYS_BEGIN',
  GET_PUBLIC_HOLIDAYS_SUCCESS: 'GET_PUBLIC_HOLIDAYS_SUCCESS',
  GET_PUBLIC_HOLIDAYS_ERROR: 'GET_PUBLIC_HOLIDAYS_ERROR',

  ADD_OR_UPDATE_PUBLIC_HOLIDAYS_BEGIN: 'ADD_OR_UPDATE_PUBLIC_HOLIDAYS_BEGIN',
  ADD_OR_UPDATE_PUBLIC_HOLIDAYS_SUCCESS:
    'ADD_OR_UPDATE_PUBLIC_HOLIDAYS_SUCCESS',
  ADD_OR_UPDATE_PUBLIC_HOLIDAYS_ERROR: 'ADD_OR_UPDATE_PUBLIC_HOLIDAYS_ERROR',

  DELETE_PUBLIC_HOLIDAYS_BEGIN: 'DELETE_PUBLIC_HOLIDAYS_BEGIN',
  DELETE_PUBLIC_HOLIDAYS_SUCCESS: 'DELETE_PUBLIC_HOLIDAYS_SUCCESS',
  DELETE_PUBLIC_HOLIDAYS_ERROR: 'DELETE_PUBLIC_HOLIDAYS_ERROR',

  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE', // Reduxdan Hata Temizlemektedir.

  GET_BANK_DEFINITIONS_BEGIN: 'GET_BANK_DEFINITIONS_BEGIN',
  GET_BANK_DEFINITIONS_ERROR: 'GET_BANK_DEFINITIONS_ERROR',
  GET_BANK_DEFINITIONS_SUCCESS: 'GET_BANK_DEFINITIONS_SUCCESS',

  UPDATE_BANK_DEFINITIONS_BEGIN: 'UPDATE_BANK_DEFINITIONS_BEGIN',
  UPDATE_BANK_DEFINITIONS_ERROR: 'UPDATE_BANK_DEFINITIONS_ERROR',
  UPDATE_BANK_DEFINITIONS_SUCCESS: 'UPDATE_BANK_DEFINITIONS_SUCCESS',

  SAYAC_GET_BEGIN: 'SAYAC_GET_BEGIN',
  SAYAC_GET_SUCCESS: 'SAYAC_GET_SUCCESS',
  SAYAC_GET_ERROR: 'SAYAC_GET_ERROR',

  GET_FIRMA_USERS_BEGIN: 'GET_FIRMA_USERS_BEGIN',
  GET_FIRMA_USERS_SUCCESS: 'GET_FIRMA_USERS_SUCCESS',
  GET_FIRMA_USERS_ERROR: 'GET_FIRMA_USERS_ERROR',

  GET_JOB_TRACKING_LIST_BY_USER_BEGIN: 'GET_JOB_TRACKING_LIST_BY_USER_BEGIN',
  GET_JOB_TRACKING_LIST_BY_USER_SUCCESS:
    'GET_JOB_TRACKING_LIST_BY_USER_SUCCESS',
  GET_JOB_TRACKING_LIST_BY_USER_ERROR: 'GET_JOB_TRACKING_LIST_BY_USER_ERROR',

  GET_MASRAF_ETIKET_BEGIN: 'GET_MASRAF_ETIKET_BEGIN',
  GET_MASRAF_ETIKET_SUCCESS: 'GET_MASRAF_ETIKET_SUCCESS',
  GET_MASRAF_ETIKET_ERROR: 'GET_MASRAF_ETIKET_ERROR',

  UPDATE_MASRAF_TIP_KEYS_BEGIN: 'UPDATE_MASRAF_TIP_KEYS_BEGIN',
  UPDATE_MASRAF_TIP_KEYS_SUCCESS: 'UPDATE_MASRAF_TIP_KEYS_SUCCESS',
  UPDATE_MASRAF_TIP_KEYS_ERROR: 'UPDATE_MASRAF_TIP_KEYS_ERROR',

  ADD_MASRAF_TIP_KEYS_BEGIN: 'ADD_MASRAF_TIP_KEYS_BEGIN',
  ADD_MASRAF_TIP_KEYS_SUCCESS: 'ADD_MASRAF_TIP_KEYS_SUCCESS',
  ADD_MASRAF_TIP_KEYS_ERROR: 'ADD_MASRAF_TIP_KEYS_ERROR',

  UPDATE_USER_PASSWORD_BEGIN: 'UPDATE_USER_PASSWORD_BEGIN',
  UPDATE_USER_PASSWORD_SUCCESS: 'UPDATE_USER_PASSWORD_SUCCESS',
  UPDATE_USER_PASSWORD_ERROR: 'UPDATE_USER_PASSWORD_ERROR',

  getTaskTrackingBegin: () => {
    return {
      type: actions.GET_TASK_TRACKING_BEGIN,
    };
  },

  getTaskTrackingSuccess: data => {
    return {
      type: actions.GET_TASK_TRACKING_SUCCESS,
      data,
    };
  },

  getTaskTrackingError: err => {
    return {
      type: actions.GET_TASK_TRACKING_ERROR,
      err,
    };
  },

  postTaskTrackingBegin: () => {
    return {
      type: actions.POST_TASK_TRACKING_BEGIN,
    };
  },

  postTaskTrackingSuccess: data => {
    return {
      type: actions.POST_TASK_TRACKING_SUCCESS,
      data,
    };
  },

  postTaskTrackingError: err => {
    return {
      type: actions.POST_TASK_TRACKING_ERROR,
      err,
    };
  },

  updateTaskTrackingBegin: () => {
    return {
      type: actions.UPDATE_TASK_TRACKING_BEGIN,
    };
  },

  updateTaskTrackingSuccess: data => {
    return {
      type: actions.UPDATE_TASK_TRACKING_SUCCESS,
      data,
    };
  },

  updateTaskTrackingError: err => {
    return {
      type: actions.UPDATE_TASK_TRACKING_ERROR,
      err,
    };
  },

  createUserBegin: () => {
    return {
      type: actions.CREATE_USER_BEGIN,
    };
  },

  createUserSuccess: data => {
    return {
      type: actions.CREATE_USER_SUCCESS,
      data,
    };
  },

  createUserError: err => {
    return {
      type: actions.CREATE_USER_ERROR,
      err,
    };
  },

  patchUserStatusBegin: () => {
    return {
      type: actions.PATCH_USER_STATUS_BEGIN,
    };
  },

  patchUserStatusSuccess: data => {
    return {
      type: actions.PATCH_USER_STATUS_SUCCESS,
      data,
    };
  },

  patchUserStatusError: err => {
    return {
      type: actions.PATCH_USER_STATUS_ERROR,
      err,
    };
  },

  patchResetPasswordBegin: () => {
    return {
      type: actions.PATCH_RESET_PASSWORD_BEGIN,
    };
  },

  patchResetPasswordSuccess: data => {
    return {
      type: actions.PATCH_RESET_PASSWORD_SUCCESS,
      data,
    };
  },

  patchResetPasswordError: err => {
    return {
      type: actions.PATCH_RESET_PASSWORD_ERROR,
      err,
    };
  },

  getUsersBegin: () => {
    return {
      type: actions.GET_USERS_BEGIN,
    };
  },

  getUsersSuccess: data => {
    return {
      type: actions.GET_USERS_SUCCESS,
      data,
    };
  },

  getUsersError: err => {
    return {
      type: actions.GET_USERS_ERROR,
      err,
    };
  },

  getTaskTrackingMoveBegin: () => {
    return {
      type: actions.GET_TASK_TRACKING_MOVE_BEGIN,
    };
  },

  getTaskTrackingMoveSuccess: data => {
    return {
      type: actions.GET_TASK_TRACKING_MOVE_SUCCESS,
      data,
    };
  },

  getTaskTrackingMoveError: err => {
    return {
      type: actions.GET_TASK_TRACKING_MOVE_ERROR,
      err,
    };
  },

  postTaskTrackingMoveBegin: () => {
    return {
      type: actions.POST_TASK_TRACKING_MOVE_BEGIN,
    };
  },

  postTaskTrackingMoveSuccess: data => {
    return {
      type: actions.POST_TASK_TRACKING_MOVE_SUCCESS,
      data,
    };
  },

  postTaskTrackingMoveError: err => {
    return {
      type: actions.POST_TASK_TRACKING_MOVE_ERROR,
      err,
    };
  },

  getPublicHolidaysBegin: () => {
    return {
      type: actions.GET_PUBLIC_HOLIDAYS_BEGIN,
    };
  },

  getPublicHolidaysSuccess: data => {
    return {
      type: actions.GET_PUBLIC_HOLIDAYS_SUCCESS,
      data,
    };
  },

  getPublicHolidaysError: err => {
    return {
      type: actions.GET_PUBLIC_HOLIDAYS_ERROR,
      err,
    };
  },

  addOrUpdatePublicHolidaysBegin: () => {
    return {
      type: actions.ADD_OR_UPDATE_PUBLIC_HOLIDAYS_BEGIN,
    };
  },

  addOrUpdatePublicHolidaysSuccess: data => {
    return {
      type: actions.ADD_OR_UPDATE_PUBLIC_HOLIDAYS_SUCCESS,
      data,
    };
  },

  addOrUpdatePublicHolidaysError: err => {
    return {
      type: actions.ADD_OR_UPDATE_PUBLIC_HOLIDAYS_ERROR,
      err,
    };
  },

  deletePublicHolidaysBegin: () => {
    return {
      type: actions.DELETE_PUBLIC_HOLIDAYS_BEGIN,
    };
  },

  deletePublicHolidaysSuccess: data => {
    return {
      type: actions.DELETE_PUBLIC_HOLIDAYS_SUCCESS,
      data,
    };
  },

  deletePublicHolidaysError: err => {
    return {
      type: actions.DELETE_PUBLIC_HOLIDAYS_ERROR,
      err,
    };
  },

  resetErrorMessage: () => {
    return {
      type: actions.RESET_ERROR_MESSAGE,
    };
  },

  updateBankDefinitionsBegin: () => {
    return {
      type: actions.UPDATE_BANK_DEFINITIONS_BEGIN,
    };
  },

  updateBankDefinitionsSuccess: data => {
    return {
      type: actions.UPDATE_BANK_DEFINITIONS_SUCCESS,
      data,
    };
  },

  updateBankDefinitionsError: err => {
    return {
      type: actions.UPDATE_BANK_DEFINITIONS_ERROR,
      err,
    };
  },

  getBankDefinitionsBegin: () => {
    return {
      type: actions.GET_BANK_DEFINITIONS_BEGIN,
    };
  },

  getBankDefinitionsSuccess: data => {
    return {
      type: actions.GET_BANK_DEFINITIONS_SUCCESS,
      data,
    };
  },

  sayacGetBegin: () => {
    return {
      type: actions.SAYAC_GET_BEGIN,
    };
  },

  sayacGetSuccess: data => {
    return {
      type: actions.SAYAC_GET_SUCCESS,
      data,
    };
  },

  sayacGetError: () => {
    return {
      type: actions.SAYAC_GET_ERROR,
    };
  },

  getFirmaUsersBegin: () => {
    return {
      type: actions.GET_FIRMA_USERS_BEGIN,
    };
  },

  getFirmaUsersSuccess: data => {
    return {
      type: actions.GET_FIRMA_USERS_SUCCESS,
      data,
    };
  },

  getBankDefinitionsError: err => {
    return {
      type: actions.GET_BANK_DEFINITIONS_ERROR,
      err,
    };
  },

  getFirmaUsersError: err => {
    return {
      type: actions.GET_FIRMA_USERS_ERROR,
      err,
    };
  },

  getJobTrackingListByUserBegin: () => {
    return {
      type: actions.GET_JOB_TRACKING_LIST_BY_USER_BEGIN,
    };
  },

  getJobTrackingListByUserSuccess: data => {
    return {
      type: actions.GET_JOB_TRACKING_LIST_BY_USER_SUCCESS,
      data,
    };
  },

  getJobTrackingListByUserError: err => {
    return {
      type: actions.GET_JOB_TRACKING_LIST_BY_USER_ERROR,
      err,
    };
  },

  getMasrafEtiketBegin: () => {
    return {
      type: actions.GET_MASRAF_ETIKET_BEGIN,
    };
  },

  getMasrafEtiketSuccess: data => {
    return {
      type: actions.GET_MASRAF_ETIKET_SUCCESS,
      data,
    };
  },

  getMasrafEtiketError: err => {
    return {
      type: actions.GET_MASRAF_ETIKET_ERROR,
      err,
    };
  },

  updateMasrafTipKeysBegin: () => {
    return {
      type: actions.UPDATE_MASRAF_TIP_KEYS_BEGIN,
    };
  },

  updateMasrafTipKeysSuccess: data => {
    return {
      type: actions.UPDATE_MASRAF_TIP_KEYS_SUCCESS,
      data,
    };
  },

  updateMasrafTipKeysError: err => {
    return {
      type: actions.UPDATE_MASRAF_TIP_KEYS_ERROR,
      err,
    };
  },

  addMasrafTipKeysBegin: () => {
    return {
      type: actions.ADD_MASRAF_TIP_KEYS_BEGIN,
    };
  },

  addMasrafTipKeysSuccess: data => {
    return {
      type: actions.ADD_MASRAF_TIP_KEYS_SUCCESS,
      data,
    };
  },

  addMasrafTipKeysError: err => {
    return {
      type: actions.ADD_MASRAF_TIP_KEYS_ERROR,
      err,
    };
  },

  updateUserPasswordBegin: () => {
    return {
      type: actions.UPDATE_USER_PASSWORD_BEGIN,
    };
  },

  updateUserPasswordSuccess: data => {
    return {
      type: actions.UPDATE_USER_PASSWORD_SUCCESS,
      data,
    };
  },

  updateUserPasswordError: err => {
    return {
      type: actions.UPDATE_USER_PASSWORD_ERROR,
      err,
    };
  },
};

export default actions;
