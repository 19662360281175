const actions = {
  POST_PRIVATE_GATES_BEGIN: 'POST_PRIVATE_GATES_BEGIN',
  POST_PRIVATE_GATES_SUCCESS: 'POST_PRIVATE_GATES_SUCCESS',
  POST_PRIVATE_GATES_ERROR: 'POST_PRIVATE_GATES_ERROR',

  GET_PRIVATE_GATES_DOWNLOAD_BEGIN: 'GET_PRIVATE_GATES_DOWNLOAD_BEGIN',
  GET_PRIVATE_GATES_DOWNLOAD_SUCCESS: 'GET_PRIVATE_GATES_DOWNLOAD_SUCCESS',
  GET_PRIVATE_GATES_DOWNLOAD_ERROR: 'GET_PRIVATE_GATES_DOWNLOAD_ERROR',

  postPrivateGatesBegin: () => {
    return {
      type: actions.POST_PRIVATE_GATES_BEGIN,
    };
  },

  postPrivateGatesSucces: data => {
    return {
      type: actions.POST_PRIVATE_GATES_SUCCESS,
      data,
    };
  },

  postPrivateGatesError: err => {
    return {
      type: actions.POST_PRIVATE_GATES_ERROR,
      err,
    };
  },

  getPrivateGatesDownloadBegin: () => {
    return {
      type: actions.GET_PRIVATE_GATES_DOWNLOAD_BEGIN,
    };
  },

  getPrivateGatesDownloadSuccess: () => {
    return {
      type: actions.GET_PRIVATE_GATES_DOWNLOAD_SUCCESS,
    };
  },

  getPrivateGatesDownloadError: err => {
    return {
      type: actions.GET_PRIVATE_GATES_DOWNLOAD_ERROR,
      err,
    };
  },
};

export default actions;
