export default {
  logout: 'Log Out',
  setting: 'Setting',
  profile: 'Profile',
  english: 'English',
  turkey: 'Turkish',
  notification: 'Notification',
  allActivity: 'See all incoming activity',
  emailAdressLabel: 'Email Address',
  password: 'Password',
  emailAddresMessage: 'Please input your Email!',
  login: 'Login',
  forgotPassword: 'Forgot password?',
  loading: 'Loading...',
  remember: 'Me Remember',
  signUp: 'Sign up now',
  signUpLabel: "Don't have an account?",
  companyLabel: 'Company',
  fullnameLabel: 'Fullname',
  phoneLabel: 'Phone',
  taxNoLabel: 'Tax No',
  createAccount: 'Create Account',
  signUpTitle: 'Sign Up',
  back: 'Back',
  noData: 'No Data',
  find: 'Find',
  clear: 'Clear',
  dashboard: 'Dashboard',
  send: 'Send',
  sideBarMenuTitle: 'Filter Menu',
  uploading: 'Uploading',
  download: 'Download',
  selectFile: 'Select File',
  uploadFile: 'Upload File',
  ok: 'Ok',
  cancel: 'Cancel',
  complete: 'Complete',
  uploadTitle: 'Click or drag file to this area to upload',
  error: 'Error',
  select: 'Select',
  yes: 'Yes',
  no: 'No',
  tableIslem: 'Action', // table içinde düzenleme butonları var ise kolon başlık adı
  edit: 'Edit',
  warning: 'Warning!',
  save: 'Save',
  saveAndDownload: 'Save And Download',
  downloadExcel: 'Download Excel',
  successUploaded: 'Successfully Uploaded',
  delete: 'Delete',
  search: 'Search',
  continue: 'Continue',
  noRows: 'No Rows',
  success: 'Success',
  transfer: 'Transfer',
  add: 'Add',
  update: 'Update',
  selectDate: 'Select Date',
  selectPort: 'Select Port',
  selectCustoms: 'Select Customs',
  bookingNo: 'Booking No',
  containerNo: 'Container No',
  searchPlaceholder: 'Search {{ name }}',
  startDate: 'Start Date',
  finishDate: 'Finish Date',
  etaDate: 'ETA Date :',
  deliveryDate: 'Delivery Date :',
  currency: 'Currency :',
  description: 'Description',
  productCode: 'Product Code',
  document: 'Document',
  documentType: 'Document Type :',
  selectDocumentType: 'Select Document Type',
  view: 'View',
  label: 'Label',
  antrepo: 'Antrepo :',
  deliveryType: 'Delivery Type :',
  netKg: 'Net Weight :',
  grossKg: 'Gross Weight :',
};
