import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Calendar, Badge, Row, Radio, message } from 'antd';
import { Popover } from '../../popup/popup';
import { ButtonComponent } from '../../../components/buttons/buttons';
import { TooltipView } from '../../../components/tooltipView/tooltipView';
import { TimePickerView } from '../../timePickerView/timePickerView';
import { InputTextAreaView } from '../../../components/inputTextAreaView/inputTextAreaView';
import { Permission } from '../../../components/permission/permission';
import FeatherIcon from 'feather-icons-react';
import {
  getPublicHolidays,
  addOrUpdatePublicHolidays,
  deletePublicHolidays,
} from '../../../redux/common/actionCreator';
import moment from 'moment';
import 'moment/locale/tr';
import dayjs from 'dayjs';
import _ from 'lodash';
// DatePicker Hatasını düzeltmek için extend edilmekte
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(weekday);
dayjs.extend(localeData);
///
moment.locale('tr', {
  week: {
    dow: 1,
  },
});

const CalendarBox = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(() => dayjs());
  const [selectedValue, setSelectedValue] = useState(() => dayjs());
  const [isDisable, setDisable] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [addPopupVisible, setAddPopupVisible] = useState(false);
  const [modalState, setModalState] = useState({
    type: 1,
    selectedInfo: [],
    info: [],
  });

  const publicHolidays = useSelector(state => state.common.publicHolidays);

  const onSelect = newValue => {
    setValue(newValue);
    setSelectedValue(newValue);
  };

  const onPanelChange = newValue => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getPublicHolidays());
  }, [dispatch]);

  useEffect(() => {
    if (selectedValue) {
      const filterSelectedInfo = publicHolidays.filter(day => {
        let formatted = dayjs(day.basTar).format('MM.DD.YYYY');
        return dayjs(formatted).isSame(
          dayjs(selectedValue).format('MM.DD.YYYY')
        );
      });
      setModalState(prevState => ({
        ...prevState,
        selectedInfo: filterSelectedInfo,
      }));
    }
  }, [selectedValue, publicHolidays]);

  const dateCellRender = value => {
    let formattedValue = dayjs(value).format('MM.DD.YYYY');
    let selected = dayjs(selectedValue).format('MM.DD.YYYY');

    let item = publicHolidays.find(day => {
      let formattedDate =
        day.tip === 1 && dayjs(day.basTar).format('MM.DD.YYYY');

      return dayjs(formattedValue).isSame(formattedDate);
    });

    let item2 = publicHolidays.find(day => {
      let formattedDate =
        day.tip === 2 && dayjs(day.basTar).format('MM.DD.YYYY');

      return dayjs(formattedValue).isSame(formattedDate);
    });

    // Hafta Sonları Düzenlenemez
    if (dayjs(selected).day() === 6 || dayjs(selected).day() === 0) {
      setDisable(true);
    }

    if (dayjs(selected).isSame(formattedValue) && !item && !item2) {
      setDisable(true);
    }

    if (dayjs(selected).isSame(formattedValue) && (item || item2)) {
      setDisable(false);
    }

    // Tip 1 Resmi Tip 2 Mesai
    if (
      dayjs(value).day() === 6 ||
      dayjs(value).day() === 0 ||
      (item && !item2)
    ) {
      return (
        <TooltipView
          placement={'topLeft'}
          title={item?.aciklama || 'Resmi Tatil'}
          content={
            <div>
              <Badge status="error" text="Resmi Tatil" />
            </div>
          }
        />
      );
    }

    if (!item && item2) {
      return (
        <TooltipView
          placement={'topLeft'}
          title={
            `${item2?.aciklama}  ${dayjs(item2.basTar).format(
              'HH:mm'
            )} - ${dayjs(item2.bitTar).format('HH:mm')}` ||
            'Mesai Saati Düzenlendi'
          }
          content={
            <div>
              <Badge status="warning" text="Mesai" />
            </div>
          }
        />
      );
    }

    if (item && item2) {
      return (
        <>
          <TooltipView
            placement={'topLeft'}
            title={item?.aciklama || 'Resmi Tatil'}
            content={
              (dayjs(value).day() === 6 ||
                dayjs(value).day() === 0 ||
                item) && (
                <div>
                  <Badge status="error" text="Resmi Tatil" />
                </div>
              )
            }
          />
          <TooltipView
            placement={'topLeft'}
            title={
              `${item2?.aciklama}  ${dayjs(item2.basTar).format(
                'HH:mm'
              )} - ${dayjs(item2.bitTar).format('HH:mm')}` ||
              'Mesai Saati Düzenlendi'
            }
            content={
              item2 && (
                <div>
                  <Badge status="warning" text="Mesai" />
                </div>
              )
            }
          />
        </>
      );
    }
  };

  const onChangeRadio = e => {
    setModalState({ ...modalState, type: e.target.value });
  };

  const onChange = (type, name, value) => {
    if (name !== 'tarih') {
      let newInfo = modalState.info.find(info => info.tip === type);

      if (newInfo) {
        newInfo[name] = value;
      } else {
        newInfo = {
          tip: type,
          aciklama: value,
        };
      }

      setModalState(prevState => ({
        ...prevState,
        info: _.unionBy(prevState.info, [newInfo], 'tip'),
      }));
    } else {
      if (value) {
        let newInfo = modalState.info.find(info => info.tip === type);
        const date1 = dayjs(
          `${selectedValue.format('YYYY-MM-DD')}T${value[0].format('HH:mm:ss')}`
        ).format('YYYY-MM-DD[T]HH:mm:ss');

        const date2 = dayjs(
          `${selectedValue.format('YYYY-MM-DD')}T${value[1].format('HH:mm:ss')}`
        ).format('YYYY-MM-DD[T]HH:mm:ss');

        if (newInfo) {
          newInfo['basTar'] = date1;
          newInfo['bitTar'] = date2;
        } else {
          newInfo = {
            tip: type,
            basTar: date1,
            bitTar: date2,
          };
        }
        setModalState(prevState => ({
          ...prevState,
          info: _.unionBy(prevState.info, [newInfo], 'tip'),
        }));
      } else {
        setModalState(prevState => ({
          ...prevState,
          info: [],
        }));
      }
    }
  };

  const onAddOrUpdate = async () => {
    const data = modalState.info.filter(info => info.tip === modalState.type);
    if (data.length !== 0) {
      const { status } = await dispatch(addOrUpdatePublicHolidays(data[0]));
      if (status === 200) {
        const filterSelectedInfo = publicHolidays.filter(day => {
          let formatted = dayjs(day.basTar).format('MM.DD.YYYY');
          return dayjs(formatted).isSame(
            dayjs(selectedValue).format('MM.DD.YYYY')
          );
        });
        setModalState(prevState => ({
          ...prevState,
          selectedInfo: filterSelectedInfo,
        }));
        setAddPopupVisible(false);
      }
    }
  };

  const onDelete = async () => {
    if (modalState.selectedInfo.length === 1) {
      const { status } = await dispatch(
        deletePublicHolidays(modalState.selectedInfo[0].id)
      );
      if (status === 200) {
        const filterSelectedInfo = publicHolidays.filter(day => {
          let formatted = dayjs(day.basTar).format('MM.DD.YYYY');
          return dayjs(formatted).isSame(
            dayjs(selectedValue).format('MM.DD.YYYY')
          );
        });
        setModalState(prevState => ({
          ...prevState,
          selectedInfo: filterSelectedInfo,
        }));
        message.success('İşlem Başarılı');
      } else {
        message.error('İşlem Başarısız');
      }
      setPopupVisible(false);
    } else {
      const holidays = modalState.selectedInfo.find(
        item => item.tip === modalState.type
      );
      const { status } = await dispatch(deletePublicHolidays(holidays.id));
      if (status === 200) {
        const filterSelectedInfo = publicHolidays.filter(day => {
          let formatted = dayjs(day.basTar).format('MM.DD.YYYY');
          return dayjs(formatted).isSame(
            dayjs(selectedValue).format('MM.DD.YYYY')
          );
        });
        setModalState(prevState => ({
          ...prevState,
          selectedInfo: filterSelectedInfo,
        }));
        message.success('İşlem Başarılı');
      } else {
        message.error('İşlem Başarısız');
      }
      setPopupVisible(false);
    }
  };

  /* console.log('modalState', modalState); */

  const content = (
    <div
      style={{
        overflowY: 'scroll',
        maxHeight: window.innerHeight - 100,
      }}
    >
      <div className="d-flex justify-content-end m-10">
        <Popover
          title={dayjs(selectedValue).format('MM.DD.YYYY')}
          placement="bottomLeft"
          content={
            <>
              <Row className="mt-10  justify-content-end">
                <Radio.Group
                  onChange={onChangeRadio}
                  value={modalState.type}
                  disabled={
                    publicHolidays.filter(day => {
                      let formatted = dayjs(day.basTar).format('MM.DD.YYYY');
                      return dayjs(formatted).isSame(
                        dayjs(selectedValue).format('MM.DD.YYYY')
                      );
                    }).length === 1
                  }
                >
                  <Radio value={1}>Resmi Tatil</Radio>
                  <Radio value={2}>Mesai</Radio>
                </Radio.Group>
              </Row>
              <Row className="mt-10  justify-content-end">
                <Button
                  type="primary"
                  size="middle"
                  onClick={() => {
                    onDelete();
                  }}
                >
                  Kaydet
                </Button>
              </Row>
            </>
          }
          action="click"
          visible={popupVisible}
          onVisibleChange={() => {
            setPopupVisible(!popupVisible);
          }}
        >
          <>
            <Permission roleGroupId={4} roleLevel={2}>
              <ButtonComponent
                size="small"
                type="danger"
                disabled={isDisable}
                onHandle={() => {
                  setPopupVisible(!popupVisible);
                }}
              >
                Sil
              </ButtonComponent>
            </Permission>
          </>
        </Popover>
        <Popover
          title={dayjs(selectedValue).format('MM.DD.YYYY')}
          placement="bottomLeft"
          content={
            <>
              <Row className="mt-10 justify-content-end">
                <Radio.Group onChange={onChangeRadio} value={modalState.type}>
                  <Radio
                    value={1}
                    disabled={
                      modalState.info.length !== 0 &&
                      modalState.info.filter(info => info.tip === 1).length ===
                        0
                    }
                  >
                    Resmi Tatil
                  </Radio>
                  <Radio
                    value={2}
                    disabled={
                      modalState.info.length !== 0 &&
                      modalState.info.filter(info => info.tip === 2).length ===
                        0
                    }
                  >
                    Mesai
                  </Radio>
                </Radio.Group>
              </Row>
              <Row className="mt-10">
                <TimePickerView
                  name="tarih"
                  label="Tarih"
                  mode="range"
                  onChange={(name, value) => {
                    onChange(modalState.type, name, value);
                  }}
                  defaultValue={
                    modalState.info.filter(info => info.tip === modalState.type)
                      .length !== 0
                      ? [
                          dayjs(
                            modalState.info.filter(
                              info => info.tip === modalState.type
                            )[0].basTar
                          ),
                          dayjs(
                            modalState.info.filter(
                              info => info.tip === modalState.type
                            )[0].bitTar
                          ),
                        ]
                      : []
                  }
                  style={{ height: 38, maxWidth: 180 }}
                />
              </Row>
              <Row className="mt-10">
                <InputTextAreaView
                  rows={2}
                  label={'Açıklama'}
                  value={
                    modalState.info.filter(
                      info => info.tip === modalState.type
                    )[0]?.aciklama
                  }
                  placeholder={'Açıklama Giriniz'}
                  name="aciklama"
                  style={{
                    marginTop: 5,
                    width: 180,
                    marginLeft: 20,
                  }}
                  onChange={(name, value) => {
                    onChange(modalState.type, name, value);
                  }}
                />
              </Row>
              <Row className="mt-10 justify-content-end">
                <Button
                  type="primary"
                  size="middle"
                  onClick={() => onAddOrUpdate()}
                >
                  Kaydet
                </Button>
              </Row>
            </>
          }
          action="click"
          visible={addPopupVisible}
          onVisibleChange={val => {
            if (val) {
              const info = modalState.selectedInfo.map(item => ({
                ...item,
              }));
              if (info.length !== 0) {
                setModalState(prevState => ({
                  ...prevState,
                  info: info,
                  type: info[0].tip,
                }));
                setAddPopupVisible(!addPopupVisible);
              }
            } else {
              setModalState(prevState => ({
                ...prevState,
                info: [],
              }));
              setAddPopupVisible(!addPopupVisible);
            }
          }}
        >
          <>
            <Permission roleGroupId={4} roleLevel={4}>
              <ButtonComponent
                className="ml-10"
                type={
                  publicHolidays.some(day => {
                    let formatted = dayjs(day.basTar).format('MM.DD.YYYY');
                    return dayjs(formatted).isSame(
                      dayjs(selectedValue).format('MM.DD.YYYY')
                    );
                  })
                    ? 'warning'
                    : 'success'
                }
                size="small"
                onHandle={() => {
                  if (!addPopupVisible) {
                    const info = modalState.selectedInfo.map(item => ({
                      ...item,
                    }));
                    if (info.length !== 0) {
                      setModalState(prevState => ({
                        ...prevState,
                        info: info,
                        type: info[0].tip,
                      }));
                    }
                    setAddPopupVisible(!addPopupVisible);
                  } else {
                    setModalState(prevState => ({
                      ...prevState,
                      info: [],
                    }));
                    setAddPopupVisible(!addPopupVisible);
                  }
                }}
                disabled={
                  isDisable &&
                  (dayjs(selectedValue).day() === 6 ||
                    dayjs(selectedValue).day() === 0)
                }
              >
                {publicHolidays.some(day => {
                  let formatted = dayjs(day.basTar).format('MM.DD.YYYY');
                  return dayjs(formatted).isSame(
                    dayjs(selectedValue).format('MM.DD.YYYY')
                  );
                })
                  ? 'Düzenle'
                  : 'Ekle'}
              </ButtonComponent>
            </Permission>
          </>
        </Popover>
      </div>
      <Calendar
        style={{
          width: 700,
          height: 800,
          border: '1px solid #f0f0f0',
          borderRadius: 2,
          margin: 10,
        }}
        value={value}
        dateCellRender={dateCellRender}
        onSelect={onSelect}
        onPanelChange={onPanelChange}
      />
    </div>
  );

  return (
    <div className="notification">
      <Popover placement="bottomLeft" content={content} action="click">
        <Link to="#" className="head-example">
          <FeatherIcon icon="calendar" size={20} />
        </Link>
      </Popover>
    </div>
  );
};

export default CalendarBox;
