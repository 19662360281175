import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import _ from 'lodash';

const AlertView = ({ title, content, style, onHandleOk, onHandleCancel }) => {
  const { t } = useTranslation();
  function error(title, content) {
    Modal.error({
      bodyStyle: {
        maxHeight: 400,
        overflowX: 'hidden',
        overflowY: 'scroll',
      },
      style: style
        ? { ...style, whiteSpace: 'pre-line' }
        : { whiteSpace: 'pre-line' },
      okText: t('ok'),
      title: title,
      content: _.isObject(content) ? content.title : content,
      onOk: onHandleOk,
      onCancel: onHandleCancel,
    });
  }

  return <>{error(title, content)}</>;
};

AlertView.defaultProps = {
  title: 'Error',
  content: 'Network Error',
};

AlertView.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  style: PropTypes.object,
  onHandleOk: PropTypes.func,
  onHandleCancel: PropTypes.func,
};

export { AlertView };
