import * as CryptoJS from 'crypto-js';

const Encryption = (text, secretKey) => {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
};

const Decryption = (text, secretKey) => {
  return CryptoJS.AES.decrypt(text, secretKey).toString(CryptoJS.enc.Utf8);
};

const generatePassword = passwordLength => {
  const numberChars = '0123456789';
  const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerChars = 'abcdefghijklmnopqrstuvwxyz';

  const allChars = numberChars + upperChars + lowerChars;
  let randPasswordArray = Array(passwordLength);

  randPasswordArray[0] = numberChars;
  randPasswordArray[1] = upperChars;
  randPasswordArray[2] = lowerChars;
  randPasswordArray = randPasswordArray.fill(allChars, 3);

  return shuffleArray(
    randPasswordArray.map(x => {
      return x[Math.floor(Math.random() * x.length)];
    })
  ).join('');
};

const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export { Encryption, Decryption, generatePassword };
