import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  ExportBookingScreen,
  ExportScreen,
  ImportUploadScreen,
  ImportEntryScreen,
  ImportExitScreen,
  ImportRoad,
  ContainerMove,
  VesselInformations,
  VesselMove,
  AddVesselMove,
  PoUploadScreen,
  PoListScreen,
} from './index';

const acenteRoutes = {
  ihracatBookingYukleme: ExportBookingScreen,
  ihracatGiris: ExportScreen,
  ithalatYukleme: ImportUploadScreen,
  ithalatGiris: ImportEntryScreen,
  ithalatCikis: ImportExitScreen,
  ithalatKarayolu: ImportRoad,
  ithalatKonteynerHareket: ContainerMove,
  gemiAdetleri: VesselInformations,
  gemiHareket: VesselMove,
  gemiHareketEkle: AddVesselMove,
  gemiHareketDuzenle: AddVesselMove,
  acentePoYukle: PoUploadScreen,
  acentePoListesi: PoListScreen,
};

const AcenteRoutes = ({ menu }) => {
  return (
    <Switch>
      {/** Burada children menüye göre düzenleme yapılacak  */}
      {/** Menüde görünen routeları render etmektedir.  */}
      {menu.map(({ id, link, key }) => {
        return (
          <Route key={id} path={link} component={acenteRoutes[key]} exact />
        );
      })}
      {/**  Child Routeları render etmektedir.Menude listelenmemektedir. */}
      {menu.map(({ childRoute }) => {
        return (
          childRoute.length !== 0 &&
          childRoute.map(({ id, link, key }) => {
            return (
              <Route key={id} path={link} component={acenteRoutes[key]} exact />
            );
          })
        );
      })}
    </Switch>
  );
};

export default AcenteRoutes;
