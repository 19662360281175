import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'antd';

const TimePickerView = ({
  mode,
  name,
  onChange,
  defaultValue,
  format,
  label,
  style,
  labelStyle,
  containerStyle,
}) => {
  const { RangePicker } = TimePicker;
  const keys = ['single', 'range'];
  const [isValidKey, setValidKey] = useState(false);

  useEffect(() => {
    if (keys.includes(mode)) {
      setValidKey(true);
    }
  }, [keys, mode]);

  const onHandle = dateString => {
    return onChange(name, dateString);
  };

  return (
    isValidKey &&
    (mode === 'single' ? (
      <TimePicker />
    ) : (
      <div
        style={containerStyle}
        className="flex align-center-v justify-content-between"
      >
        <span style={labelStyle}>{label}</span>
        <RangePicker
          style={style ? style : { maxWidth: 180 }}
          onChange={onHandle}
          format={format || 'HH:mm'}
          defaultValue={defaultValue}
          value={defaultValue}
          showNow={false}
        />
      </div>
    ))
  );
};

TimePickerView.propTypes = {
  mode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  format: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  containerStyle: PropTypes.object,
};

export { TimePickerView };
