export default {
  logout: 'Çıkış',
  setting: 'Ayarlar',
  profile: 'Profil',
  english: 'Ingilizce',
  turkey: 'Türkçe',
  notification: 'Bildirim',
  allActivity: 'Gelen Tüm Bildirimleri Gör',
  emailAdressLabel: 'E-posta Adresi',
  password: 'Şifre',
  emailAddresMessage: 'Lütfen E-posta Giriniz!',
  login: 'Giriş',
  forgotPassword: 'Şifremi Unuttum',
  loading: 'Yükleniyor...',
  remember: 'Beni Hatırla',
  signUp: 'Şimdi Kayıt Ol',
  signUpLabel: 'Hesabınız Yok Mu?',
  companyLabel: 'Firma',
  fullnameLabel: 'Adı Soyadı',
  phoneLabel: 'Telefon',
  taxNoLabel: 'Vergi No',
  createAccount: 'Hesap Oluştur',
  signUpTitle: 'Kayıt Ol',
  back: 'Geri',
  noData: 'Veri Bulunmamakta',
  find: 'Ara',
  clear: 'Temizle',
  dashboard: 'Anasayfa',
  send: 'Gönder',
  sideBarMenuTitle: 'Filtre Menüsü',
  uploading: 'Yükleniyor',
  download: 'İndir',
  selectFile: 'Dosya Seçiniz',
  uploadFile: 'Dosya Yükle',
  ok: 'Tamam',
  cancel: 'Vazgeç',
  complete: 'Tamamla',
  uploadTitle: 'Yüklemek için dosyayı tıklayın veya bu alana sürükleyin',
  error: 'Hata',
  select: 'Seçiniz',
  yes: 'Evet',
  no: 'Hayır',
  tableIslem: 'İşlem', // table içinde düzenleme butonları var ise kolon başlık adı
  edit: 'Düzenle',
  warning: 'Uyarı!',
  save: 'Kaydet',
  saveAndDownload: 'Kaydet ve Indir',
  downloadExcel: 'Excel Indir',
  successUploaded: 'Başarıyla Yüklenmiştir',
  delete: 'Sil',
  search: 'Ara',
  continue: 'Devam Et',
  noRows: 'Satır Yok',
  success: 'İşlem Başarılı',
  transfer: 'Aktar',
  add: 'Ekle',
  update: 'Güncelle',
  selectDate: 'Tarih Seçiniz',
  selectPort: 'Liman Seçiniz',
  selectCustoms: 'Gümrük Seçiniz',
  bookingNo: 'Booking No',
  containerNo: 'Konteyner No',
  searchPlaceholder: 'Ara {{ name }}',
  startDate: 'Başlangıç Tarihi',
  finishDate: 'Bitiş Tarihi',
  etaDate: 'ETA Tarihi :',
  deliveryDate: 'Teslim Tarihi :',
  currency: 'Döviz :',
  description: 'Açıklama',
  productCode: 'Ürün Kodu ',
  document: 'Evrak',
  documentType: 'Evrak Türü :',
  selectDocumentType: 'Evrak Türü Seçiniz',
  view: 'İncele',
  label: 'Etiket',
  antrepo: 'Antrepo :',
  deliveryType: 'Teslim Şekli :',
  netKg: ' Net KG :',
  grossKg: 'Brüt KG :',
};
