import actions from './actions';

const {
  UPLOAD_GIT_BEGIN,
  UPLOAD_GIT_SUCCESS,
  UPLOAD_GIT_ERROR,
  ADD_GIT_BEGIN,
  ADD_GIT_SUCCESS,
  ADD_GIT_ERROR,
  GET_GIT_INVOICES_BEGIN,
  GET_GIT_INVOICES_SUCCESS,
  GET_GIT_INVOICES_ERROR,
  ADD_GIT_CONSIGNMENTNOTE_BEGIN,
  ADD_GIT_CONSIGNMENTNOTE_SUCCESS,
  ADD_GIT_CONSIGNMENTNOTE_ERROR,
  UPDATE_GIT_CONSIGNMENTNOTE_BEGIN,
  UPDATE_GIT_CONSIGNMENTNOTE_SUCCESS,
  UPDATE_GIT_CONSIGNMENTNOTE_ERROR,
  UPDATE_INVOICES_ETA_BEGIN,
  UPDATE_INVOICES_ETA_SUCCESS,
  UPDATE_INVOICES_ETA_ERROR,
  GET_SO_LIST_BEGIN,
  GET_SO_LIST_SUCCESS,
  GET_SO_LIST_ERROR,
  ADD_SO_LIST_BEGIN,
  ADD_SO_LIST_SUCCESS,
  ADD_SO_LIST_ERROR,
  PATCH_SO_LIST_BEGIN,
  PATCH_SO_LIST_SUCCESS,
  PATCH_SO_LIST_ERROR,
  GET_SO_PRODUCT_BEGIN,
  GET_SO_PRODUCT_SUCCESS,
  GET_SO_PRODUCT_ERROR,
  DOWNLOAD_DOCUMENT_BEGIN,
  DOWNLOAD_DOCUMENT_SUCCESS,
  DOWNLOAD_DOCUMENT_ERROR,
  CREATE_DOCUMENT_RELATION_BEGIN,
  CREATE_DOCUMENT_RELATION_SUCCESS,
  CREATE_DOCUMENT_RELATION_ERROR,
  GET_DOCUMENT_RELATION_BEGIN,
  GET_DOCUMENT_RELATION_SUCCESS,
  GET_DOCUMENT_RELATION_ERROR,
  DELETE_DOCUMENT_RELATION_BEGIN,
  DELETE_DOCUMENT_RELATION_SUCCESS,
  DELETE_DOCUMENT_RELATION_ERROR,
  GET_CURRENCY_BEGIN,
  GET_CURRENCY_SUCCESS,
  GET_CURRENCY_ERROR,
  GET_PRODUCT_CATEGORY_BEGIN,
  GET_PRODUCT_CATEGORY_SUCCESS,
  GET_PRODUCT_CATEGORY_ERROR,
  GET_MEASUREMENT_UNIT_BEGIN,
  GET_MEASUREMENT_UNIT_SUCCESS,
  GET_MEASUREMENT_UNIT_ERROR,
  GET_DELIVERY_BEGIN,
  GET_DELIVERY_SUCCESS,
  GET_DELIVERY_ERROR,
  GET_TRANSPORT_BEGIN,
  GET_TRANSPORT_SUCCESS,
  GET_TRANSPORT_ERROR,
  UPDATE_INVOICES_DETAIL_BEGIN,
  UPDATE_INVOICES_DETAIL_SUCCESS,
  UPDATE_INVOICES_DETAIL_ERROR,
  GET_WAREHOUSE_STOCK_BEGIN,
  GET_WAREHOUSE_STOCK_SUCCESS,
  GET_WAREHOUSE_STOCK_ERROR,
  CREATE_DEMAND_BEGIN,
  CREATE_DEMAND_SUCCESS,
  CREATE_DEMAND_ERROR,
  DELETE_DEMAND_BEGIN,
  DELETE_DEMAND_SUCCESS,
  DELETE_DEMAND_ERROR,
  GET_DEMAND_BEGIN,
  GET_DEMAND_SUCCESS,
  GET_DEMAND_ERROR,
  UPDATE_DEMAND_EVRIM_BEGIN,
  UPDATE_DEMAND_EVRIM_SUCCESS,
  UPDATE_DEMAND_EVRIM_ERROR,
  GET_DEMAND_INFO_BEGIN,
  GET_DEMAND_INFO_SUCCESS,
  GET_DEMAND_INFO_ERROR,
  UPLOAD_DEMAND_BEGIN,
  UPLOAD_DEMAND_SUCCESS,
  UPLOAD_DEMAND_ERROR,
  CREATE_DEMAND_EXCEL_BEGIN,
  CREATE_DEMAND_EXCEL_SUCCESS,
  CREATE_DEMAND_EXCEL_ERROR,
  GET_ANTREPO_BEYANNAME_BEGIN,
  GET_ANTREPO_BEYANNAME_SUCCESS,
  GET_ANTREPO_BEYANNAME_ERROR,
  GET_ITHALAT_BEYANNAME_BEGIN,
  GET_ITHALAT_BEYANNAME_SUCCESS,
  GET_ITHALAT_BEYANNAME_ERROR,
  GET_ANTREPO_STOK_BEGIN,
  GET_ANTREPO_STOK_SUCCESS,
  GET_ANTREPO_STOK_ERROR,
  GET_BEYANNAME_IS_TAKIP_DETAY_BEGIN,
  GET_BEYANNAME_IS_TAKIP_DETAY_SUCCESS,
  GET_BEYANNAME_IS_TAKIP_DETAY_ERROR,
  GET_BEYANNAME_DETAY_SORGULA_BEGIN,
  GET_BEYANNAME_DETAY_SORGULA_SUCCESS,
  GET_BEYANNAME_DETAY_SORGULA_ERROR,
  PATCH_SO_NO_BEGIN,
  PATCH_SO_NO_SUCCESS,
  PATCH_SO_NO_ERROR,
  RESET_ERROR_MESSAGE,
  GET_GIT_DATA,
  GET_ALL_DATA_ERROR,
  GET_GIT_ONE_DOCUMENT_DATA,
  DOWNLOAD_GİT_REPORT,
  DOWNLOAD_GİT_REPORT_SUCCESS,
  GET_INVOICES_DATA,
  DELETE_DOCUMENT_BEGIN,
  DELETE_DOCUMENT_ERROR,
  DELETE_DOCUMENT_SUCCESS,
  GET_COUNTRIES_BEGIN,
  GET_COUNTRIES_ERROR,
  GET_COUNTRIES_SUCCESS,
  GET_WEBGUMRUK_BEYANNAME_BEGIN,
  GET_WEBGUMRUK_BEYANNAME_ERROR,
  GET_WEBGUMRUK_BEYANNAME_SUCCESS,
  GET_PAYMENT_BEGIN,
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_ERROR,
  ADD_PAYMENT_DECLARATION_BEGIN,
  ADD_PAYMENT_DECLARATION_ERROR,
  ADD_PAYMENT_DECLARATION_SUCCESS,
  UPDATE_PAYMENT_DECLARATION_BEGIN,
  UPDATE_PAYMENT_DECLARATION_ERROR,
  UPDATE_PAYMENT_DECLARATION_SUCCESS,
  GET_EVRIM_ARCHIVE_BEGIN,
  GET_EVRIM_ARCHIVE_ERROR,
  GET_EVRIM_ARCHIVE_SUCCESS,
  GET_EVRIM_ARCHIVE_DOCUMENT_BEGIN,
  GET_EVRIM_ARCHIVE_DOCUMENT_ERROR,
  GET_EVRIM_ARCHIVE_DOCUMENT_SUCCESS,
  GET_GENEL_RAPORLAR_BEGIN,
  GET_GENEL_RAPORLAR_ERROR,
  GET_GENEL_RAPORLAR_SUCCESS,
  GET_GENEL_RAPORLAR_DETAY_BEGIN,
  GET_GENEL_RAPORLAR_DETAY_ERROR,
  GET_GENEL_RAPORLAR_DETAY_SUCCESS,
  GET_GENEL_RAPOR_DETAYI_BEGIN,
  GET_GENEL_RAPOR_DETAYI_SUCCESS,
  GET_GENEL_RAPOR_DETAYI_ERROR,
  GET_COMPANY_BEGIN,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  GET_PRODUCT_ALL_BEGIN,
  GET_PRODUCT_ALL_SUCCESS,
  GET_PRODUCT_ALL_ERROR,
  UPDATE_COMPANY_BEGIN,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_SUCCESS,
  GET_TALEP_LISTESI_BEGIN,
  GET_TALEP_LISTESI_ERROR,
  GET_TALEP_LISTESI_SUCCESS,
  DELETE_TALEP_BEGIN,
  DELETE_TALEP_ERROR,
  DELETE_TALEP_SUCCESS,
  GET_GTIP_LIST_BEGIN,
  GET_GTIP_LIST_SUCCESS,
  GET_GTIP_LIST_ERROR,
  ADD_GTIP_BEGIN,
  ADD_GTIP_SUCCESS,
  ADD_GTIP_ERROR,
  GET_GTIP_DETAY_BEGIN,
  GET_GTIP_DETAY_SUCCESS,
  GET_GTIP_DETAY_ERROR,
  ADD_GTIP_DETAY_BEGIN,
  ADD_GTIP_DETAY_ERROR,
  ADD_GTIP_DETAY_SUCCESS,
  GET_BEYAN_ONIZLEME_BEGIN,
  GET_BEYAN_ONIZLEME_ERROR,
  GET_BEYAN_ONIZLEME_SUCCESS,
  GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_BEGIN,
  GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_ERROR,
  GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_SUCCESS,
  GET_OLD_SO_LIST_BEGIN,
  GET_OLD_SO_LIST_ERROR,
  GET_OLD_SO_LIST_SUCCESS,
  PUT_OLD_SO_BEGIN,
  PUT_OLD_SO_SUCCESS,
  PUT_OLD_SO_ERROR,
  GET_DEMAND_DOCUMENT_INFO_BEGIN,
  GET_DEMAND_DOCUMENT_INFO_ERROR,
  GET_DEMAND_DOCUMENT_INFO_SUCCESS,
  GET_ORIGIN_BEGIN,
  GET_ORIGIN_SUCCESS,
  GET_ORIGIN_ERROR,
  GET_PLANT_BEGIN,
  GET_PLANT_SUCCESS,
  GET_PLANT_ERROR,
  GET_GTIP_DOCUMENT_TYPE_BEGIN,
  GET_GTIP_DOCUMENT_TYPE_SUCCESS,
  GET_GTIP_DOCUMENT_TYPE_ERROR,
  GET_PRODUCT_DOCUMENT_CONTROL_BEGIN,
  GET_PRODUCT_DOCUMENT_CONTROL_SUCCESS,
  GET_PRODUCT_DOCUMENT_CONTROL_ERROR,
  GET_DOCUMENT_TRACKING_ERROR,
  GET_DOCUMENT_TRACKING_SUCCESS,
  GET_DOCUMENT_DETAIL_BEGIN,
  GET_DOCUMENT_DETAIL_ERROR,
  GET_DOCUMENT_DETAIL_SUCCESS,
  GET_DOCUMENT_PRODUCT_ERROR,
  GET_DOCUMENT_PRODUCT_SUCCESS,
  ADD_DOCUMENT_PRODUCT_BEGIN,
  ADD_DOCUMENT_PRODUCT_SUCCESS,
  ADD_DOCUMENT_PRODUCT_ERROR,
  UPDATE_DOCUMENT_PRODUCT_BEGIN,
  UPDATE_DOCUMENT_PRODUCT_SUCCESS,
  UPDATE_DOCUMENT_PRODUCT_ERROR,
  UPDATE_DOCUMENT_TRACKING_BEGIN,
  UPDATE_DOCUMENT_TRACKING_SUCCESS,
  UPDATE_DOCUMENT_TRACKING_ERROR,
  GET_ORDER_BEGIN,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  GET_GECICI_BEGIN,
  GET_GECICI_SUCCESS,
  GET_GECICI_ERROR,
  GET_WAREHOUSE_DEFINITIONS_BEGIN,
  GET_WAREHOUSE_DEFINITIONS_SUCCESS,
  GET_WAREHOUSE_DEFINITIONS_ERROR,
  UPDATE_WAREHOUSE_DEFINITIONS_BEGIN,
  UPDATE_WAREHOUSE_DEFINITIONS_ERROR,
  UPDATE_WAREHOUSE_DEFINITIONS_SUCCESS,
  GET_AGENT_DEFINITIONS_BEGIN,
  GET_AGENT_DEFINITIONS_SUCCESS,
  GET_AGENT_DEFINITIONS_ERROR,
  UPDATE_AGENT_DEFINITIONS_BEGIN,
  UPDATE_AGENT_DEFINITIONS_ERROR,
  UPDATE_AGENT_DEFINITIONS_SUCCESS,
  UPDATE_ORDER_BEGIN,
  UPDATE_ORDER_ERROR,
  UPDATE_ORDER_SUCCESS,
  ADD_ORDER_NOTES_BEGIN,
  ADD_ORDER_NOTES_ERROR,
  ADD_ORDER_NOTES_SUCCESS,
  GET_ORDER_NOTES_BEGIN,
  GET_ORDER_NOTES_SUCCESS,
  GET_ORDER_NOTES_ERROR,
  ADD_ORDER_DECLARATION_BEGIN,
  ADD_ORDER_DECLARATION_SUCCESS,
  ADD_ORDER_DECLARATION_ERROR,
  GET_ORDER_DECLARATION_BEGIN,
  GET_ORDER_DECLARATION_SUCCESS,
  GET_ORDER_DECLARATION_ERROR,
  GET_DOCUMENT_REZERVED_BEGIN,
  GET_DOCUMENT_REZERVED_SUCCESS,
  GET_DOCUMENT_REZERVED_ERROR,
  ORDER_REQUIRED_DOCUMENTS_BEGIN,
  ORDER_REQUIRED_DOCUMENTS_SUCCESS,
  ORDER_REQUIRED_DOCUMENTS_ERROR,
  DOCUMENT_TRACKING_SEARCH_BEGIN,
  DOCUMENT_TRACKING_SEARCH_SUCCESS,
  DOCUMENT_TRACKING_SEARCH_ERROR,
  DOCUMENT_REZERVE_SAVE_BEGIN,
  DOCUMENT_REZERVE_SAVE_SUCCESS,
  DOCUMENT_REZERVE_SAVE_ERROR,
  DOCUMENT_REZERVE_DELETE_BEGIN,
  DOCUMENT_REZERVE_DELETE_SUCCESS,
  DOCUMENT_REZERVE_DELETE_ERROR,
  GET_ORDER_LOGS_BEGIN,
  GET_ORDER_LOGS_ERROR,
  GET_ORDER_LOGS_SUCCESS,
  GET_GECICI_ITHALAT_BEGIN,
  GET_GECICI_ITHALAT_SUCCESS,
  GET_GECICI_ITHALAT_ERROR,
  UPDATE_GECICI_ITHALAT_BEGIN,
  UPDATE_GECICI_ITHALAT_SUCCESS,
  UPDATE_GECICI_ITHALAT_ERROR,
  GET_ALL_SHIPMENT_DECLARATIONS_ERROR,
  GET_ALL_SHIPMENT_DECLARATIONS_SUCCESS,
  GET_ALL_SHIPMENT_DECLARATIONS_BEGIN,
  GET_ORIGINAL_DOCUMENT_TRACKING_LIST_BEGIN,
  GET_ORIGINAL_DOCUMENT_TRACKING_LIST_SUCCESS,
  GET_ORIGINAL_DOCUMENT_TRACKING_LIST_ERROR,
  GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_BEGIN,
  GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_SUCCESS,
  GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_ERROR,
  GET_ORIGINAL_DOCUMENT_TYPE_LIST_BEGIN,
  GET_ORIGINAL_DOCUMENT_TYPE_LIST_SUCCESS,
  GET_ORIGINAL_DOCUMENT_TYPE_LIST_ERROR,
  GET_PRODUCT_MASTAR_DATA_BEGIN,
  GET_PRODUCT_MASTAR_DATA_SUCCESS,
  GET_PRODUCT_MASTER_DATA_ERROR,
  UPDATE_PRODUCT_MASTER_DATA_BEGIN,
  UPDATE_PRODUCT_MASTER_DATA_SUCCESS,
  UPDATE_PRODUCT_MASTER_DATA_ERROR,
  ADD_PRODUCT_MASTER_DATA_BEGIN,
  ADD_PRODUCT_MASTER_DATA_SUCCESS,
  ADD_PRODUCT_MASTER_DATA_ERROR,
  GET_ORDER_AGENT_INFORMATION_BEGIN,
  GET_ORDER_AGENT_INFORMATION_SUCCESS,
  GET_ORDER_AGENT_INFORMATION_ERROR,
  GET_GECICI_EXPORT_BEGIN,
  GET_GECICI_EXPORT_SUCCESS,
  GET_GECICI_EXPORT_ERROR,
  GET_WANTED_PRODUCTS_SUCCESS,
  GET_WANTED_PRODUCTS_ERROR,
  GET_SHIPPING_LINE_BEGIN,
  GET_SHIPPING_LINE_SUCCESS,
  GET_SHIPPING_LINE_ERROR,
  GET_DAHILDE_ISLEME_BEGIN,
  GET_DAHILDE_ISLEME_SUCCESS,
  GET_DAHILDE_ISLEME_ERROR,
  GET_ORDER_FILTER_BEGIN,
  GET_ORDER_FILTER_SUCCESS,
  GET_ORDER_FILTER_ERROR,
  GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_BEGIN,
  GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_SUCCESS,
  GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_ERROR,
  GET_FILTERING_PRODUCTS_BEGIN,
  GET_FILTERING_PRODUCTS_SUCCESS,
  GET_FILTERING_PRODUCTS_ERROR,
  GET_EVRIM_CEZA_BY_ORDER_BEGIN,
  GET_EVRIM_CEZA_BY_ORDER_SUCCESS,
  GET_EVRIM_CEZA_BY_ORDER_ERROR,
  ADD_IHBAR_TAKIP_BEGIN,
  ADD_IHBAR_TAKIP_SUCCESS,
  ADD_IHBAR_TAKIP_ERROR,
  UPDATE_IHBAR_TAKIP_BEGIN,
  UPDATE_IHBAR_TAKIP_SUCCESS,
  UPDATE_IHBAR_TAKIP_ERROR,
  GET_IHBAR_TAKIP_BEGIN,
  GET_IHBAR_TAKIP_SUCCESS,
  GET_IHBAR_TAKIP_ERROR,
  GET_ORDER_REQUIRED_BEGIN,
  GET_ORDER_REQUIRED_SUCCESS,
  GET_ORDER_REQUIRED_ERROR,
  GET_DONUS_DEPO_BEGIN,
  GET_DONUS_DEPO_SUCCESS,
  GET_DONUS_DEPO_ERROR,
  GET_MASRAF_LIST_BEGIN,
  GET_MASRAF_LIST_SUCCESS,
  GET_MASRAF_LIST_ERROR,
  GET_MASRAF_DETAY_BEGIN,
  GET_MASRAF_DETAY_SUCCESS,
  GET_MASRAF_DETAY_ERROR,
  GET_MASRAF_TIP_BEGIN,
  GET_MASRAF_TIP_SUCCESS,
  GET_MASRAF_TIP_ERROR,
  GET_MASRAF_BEYANNAME_BEGIN,
  GET_MASRAF_BEYANNAME_SUCCESS,
  GET_MASRAF_BEYANNAME_ERROR,
  GET_FIRMA_EVRIM_BEGIN,
  GET_FIRMA_EVRIM_SUCCESS,
  GET_FIRMA_EVRIM_ERROR,
  GET_PRODUCT_TARIFF_LIST_BEGIN,
  GET_PRODUCT_TARIFF_LIST_SUCCESS,
  GET_PRODUCT_TARIFF_LIST_ERROR,
  GET_PRODUCT_TARIFF_MOVE_LIST_BEGIN,
  GET_PRODUCT_TARIFF_MOVE_LIST_SUCCESS,
  GET_PRODUCT_TARIFF_MOVE_LIST_ERROR,
  GET_ONE_PRODUCT_TARIFF_BEGIN,
  GET_ONE_PRODUCT_TARIFF_SUCCESS,
  GET_ONE_PRODUCT_TARIFF_ERROR,
  POST_PRODUCT_TARIFF_BEGIN,
  POST_PRODUCT_TARIFF_SUCCESS,
  POST_PRODUCT_TARIFF_ERROR,
  PATCH_PRODUCT_TARIFF_BEGIN,
  PATCH_PRODUCT_TARIFF_SUCCESS,
  PATCH_PRODUCT_TARIFF_ERROR,
  DELETE_PRODUCT_TARIFF_BEGIN,
  DELETE_PRODUCT_TARIFF_SUCCESS,
  DELETE_PRODUCT_TARIFF_ERROR,
  GET_MUSAVIR_LIST_BEGIN,
  GET_MUSAVIR_LIST_SUCCESS,
  GET_MUSAVIR_LIST_ERROR,
  GET_BEYANNAME_TASLAK_BEGIN,
  GET_BEYANNAME_TASLAK_SUCCESS,
  GET_BEYANNAME_TASLAK_ERROR,
  GET_BEYANNAME_LIST_BEGIN,
  GET_BEYANNAME_LIST_SUCCESS,
  GET_BEYANNAME_LIST_ERROR,
} = actions;

const initState = {
  loading: false,
  fileLoading: false,
  error: null,
  payload: {},
  documentData: {},
  gitFile: null,
  invoices: [],
  solist: [],
  soProduct: [],
  antrepoStok: [],
  antrepoStokRapor: [],
  talepListesi: [],
  talepBilgiListesi: [],
  currency: [],
  delivery: [],
  transport: [],
  countries: [],
  categories: [],
  wgBeyanname: [],
  payment: [],
  archiveDocument: null,
  genelRaporlar: [],
  genelRaporDetay: {},
  wantedGenelRaporDetay: [],
  companies: [],
  products: [],
  productsAll: [],
  talepler: [],
  gtipList: [],
  gtipDetay: [],
  beyanOnizleme: {},
  oldSoList: [],
  demandDocumentInfo: [],
  measurementUnits: [],
  origins: [],
  plants: [],
  documentTracking: [],
  documentDetail: [],
  documentProduct: [],
  orderList: [],
  orderNotesList: [],
  orderDeclaration: [],
  warehouses: [],
  agents: [],
  documentRezerved: [],
  productDocuments: [], // Tüm Belgeleri döner true / false değerlerini gösterir  / Ürün ID ile çalışır.
  orderRequiredDocuments: [], // Sadece True olan Belgeleri Döner + Varsa Belge Numarasını Döner / Order ID ile çalışır.
  documentTrackingResult: [],
  documentRezerveSave: [],
  orderLogs: [],
  geciciList: [],
  geciciOrderList: [],
  originalDocumentList: [],
  originalDocumentsByInvoiceNo: [],
  originalDocumentTypeList: [],
  productMasterData: [],
  gtipDocumentList: [],
  geciciExport: [],
  shippingList: [],
  dahildeIsleme: [],
  groupDetails: [],
  cezaList: [],
  ihbarTakipList: [],
  requiredList: [],
  donusDepo: [],
  masrafList: [],
  masrafDetay: [],
  masrafTip: [],
  masrafBeyanname: [],
  firmaEvrim: [],
  productTariffList: [],
  musavir: [],
  beyannameTaslak: [],
  beyannameList: [],
};

const antrepoStokReducer = (state = initState, action) => {
  const { type, data, err, page } = action;

  switch (type) {
    case UPLOAD_GIT_BEGIN:
    case ADD_GIT_BEGIN:
    case GET_GIT_INVOICES_BEGIN:
    case GET_GTIP_DOCUMENT_TYPE_BEGIN:
    case ADD_GIT_CONSIGNMENTNOTE_BEGIN:
    case UPDATE_GIT_CONSIGNMENTNOTE_BEGIN:
    case UPDATE_INVOICES_ETA_BEGIN:
    case GET_SO_LIST_BEGIN:
    case ADD_SO_LIST_BEGIN:
    case PATCH_SO_LIST_BEGIN:
    case GET_SO_PRODUCT_BEGIN:
    case GET_CURRENCY_BEGIN:
    case GET_DELIVERY_BEGIN:
    case GET_TRANSPORT_BEGIN:
    case GET_PLANT_BEGIN:
    case GET_PRODUCT_CATEGORY_BEGIN:
    case UPDATE_INVOICES_DETAIL_BEGIN:
    case GET_WAREHOUSE_STOCK_BEGIN:
    case CREATE_DEMAND_BEGIN:
    case DELETE_DEMAND_BEGIN:
    case GET_DEMAND_BEGIN:
    case UPDATE_DEMAND_EVRIM_BEGIN:
    case GET_DEMAND_INFO_BEGIN:
    case GET_PRODUCT_DOCUMENT_CONTROL_BEGIN:
    case UPLOAD_DEMAND_BEGIN:
    case CREATE_DEMAND_EXCEL_BEGIN:
    case GET_PAYMENT_BEGIN:
    case ADD_PAYMENT_DECLARATION_BEGIN:
    case UPDATE_PAYMENT_DECLARATION_BEGIN:
    case GET_ANTREPO_BEYANNAME_BEGIN:
    case GET_ITHALAT_BEYANNAME_BEGIN:
    case GET_ANTREPO_STOK_BEGIN:
    case GET_BEYANNAME_IS_TAKIP_DETAY_BEGIN:
    case GET_BEYANNAME_DETAY_SORGULA_BEGIN:
    case DELETE_DOCUMENT_BEGIN:
    case PATCH_SO_NO_BEGIN:
    case GET_COUNTRIES_BEGIN:
    case GET_WEBGUMRUK_BEYANNAME_BEGIN:
    case GET_EVRIM_ARCHIVE_BEGIN:
    case GET_GENEL_RAPORLAR_BEGIN:
    case GET_GENEL_RAPORLAR_DETAY_BEGIN:
    case GET_GENEL_RAPOR_DETAYI_BEGIN:
    case GET_COMPANY_BEGIN:
    case UPDATE_COMPANY_BEGIN:
    case GET_WAREHOUSE_DEFINITIONS_BEGIN:
    case UPDATE_WAREHOUSE_DEFINITIONS_BEGIN:
    case GET_AGENT_DEFINITIONS_BEGIN:
    case UPDATE_AGENT_DEFINITIONS_BEGIN:
    case GET_TALEP_LISTESI_BEGIN:
    case DELETE_TALEP_BEGIN:
    case GET_GTIP_LIST_BEGIN:
    case ADD_GTIP_BEGIN:
    case GET_GTIP_DETAY_BEGIN:
    case ADD_GTIP_DETAY_BEGIN:
    case GET_BEYAN_ONIZLEME_BEGIN:
    case GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_BEGIN:
    case GET_OLD_SO_LIST_BEGIN:
    case PUT_OLD_SO_BEGIN:
    case GET_MEASUREMENT_UNIT_BEGIN:
    case GET_ORIGIN_BEGIN:
    case GET_ALL_SHIPMENT_DECLARATIONS_BEGIN:
    case GET_DEMAND_DOCUMENT_INFO_BEGIN:
    case ADD_DOCUMENT_PRODUCT_BEGIN:
    case UPDATE_DOCUMENT_TRACKING_BEGIN:
    case UPDATE_DOCUMENT_PRODUCT_BEGIN:
    case GET_ORDER_BEGIN:
    case GET_GECICI_BEGIN:
    case UPDATE_ORDER_BEGIN:
    case GET_ORDER_NOTES_BEGIN:
    case ADD_ORDER_NOTES_BEGIN:
    case ADD_ORDER_DECLARATION_BEGIN:
    case GET_ORDER_DECLARATION_BEGIN:
    case GET_DOCUMENT_REZERVED_BEGIN:
    case ORDER_REQUIRED_DOCUMENTS_BEGIN:
    case DOCUMENT_TRACKING_SEARCH_BEGIN:
    case DOCUMENT_REZERVE_SAVE_BEGIN:
    case DOCUMENT_REZERVE_DELETE_BEGIN:
    case GET_ORDER_LOGS_BEGIN:
    case GET_ORIGINAL_DOCUMENT_TRACKING_LIST_BEGIN:
    case GET_ORIGINAL_DOCUMENT_TYPE_LIST_BEGIN:
    case GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_BEGIN:
    case GET_GECICI_ITHALAT_BEGIN:
    case UPDATE_GECICI_ITHALAT_BEGIN:
    case GET_PRODUCT_MASTAR_DATA_BEGIN:
    case GET_PRODUCT_ALL_BEGIN:
    case GET_GECICI_EXPORT_BEGIN:
    case UPDATE_PRODUCT_MASTER_DATA_BEGIN:
    case GET_ORDER_AGENT_INFORMATION_BEGIN:
    case ADD_PRODUCT_MASTER_DATA_BEGIN:
    case GET_SHIPPING_LINE_BEGIN:
    case GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_BEGIN:
    case GET_FILTERING_PRODUCTS_BEGIN:
    case GET_DAHILDE_ISLEME_BEGIN:
    case GET_EVRIM_CEZA_BY_ORDER_BEGIN:
    case GET_ORDER_FILTER_BEGIN:
    case ADD_IHBAR_TAKIP_BEGIN:
    case UPDATE_IHBAR_TAKIP_BEGIN:
    case GET_IHBAR_TAKIP_BEGIN:
    case GET_ORDER_REQUIRED_BEGIN:
    case GET_DONUS_DEPO_BEGIN:
    case GET_MASRAF_LIST_BEGIN:
    case GET_MASRAF_DETAY_BEGIN:
    case GET_MASRAF_TIP_BEGIN:
    case GET_MASRAF_BEYANNAME_BEGIN:
    case CREATE_DOCUMENT_RELATION_BEGIN:
    case GET_DOCUMENT_RELATION_BEGIN:
    case DELETE_DOCUMENT_RELATION_BEGIN:
    case GET_FIRMA_EVRIM_BEGIN:
    case GET_PRODUCT_TARIFF_LIST_BEGIN:
    case GET_ONE_PRODUCT_TARIFF_BEGIN:
    case POST_PRODUCT_TARIFF_BEGIN:
    case PATCH_PRODUCT_TARIFF_BEGIN:
    case GET_MUSAVIR_LIST_BEGIN:
    case DELETE_PRODUCT_TARIFF_BEGIN:
    case GET_PRODUCT_TARIFF_MOVE_LIST_BEGIN:
    case GET_BEYANNAME_TASLAK_BEGIN:
    case GET_BEYANNAME_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_EVRIM_ARCHIVE_DOCUMENT_BEGIN:
      return {
        ...state,
        fileLoading: true,
        error: null,
      };
    case DOWNLOAD_DOCUMENT_BEGIN:
      return {
        ...state,
        fileLoading: true,
        error: null,
      };
    case GET_DOCUMENT_DETAIL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        documentDetail: [],
      };
    case UPLOAD_GIT_SUCCESS:
    case ADD_GIT_SUCCESS:
      return {
        ...state,
        loading: false,
        gitFile: data,
      };
    case GET_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        payment: data,
      };
    case GET_EVRIM_CEZA_BY_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        cezaList: data,
      };
    case GET_EVRIM_ARCHIVE_SUCCESS:
    case GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_ORDER_AGENT_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_GIT_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: data,
      };
    case GET_GENEL_RAPORLAR_SUCCESS:
      return {
        ...state,
        loading: false,
        genelRaporlar: data,
      };
    case GET_SHIPPING_LINE_SUCCESS:
      return {
        ...state,
        loading: false,
        shippingList: data,
      };
    case GET_DAHILDE_ISLEME_SUCCESS:
      return {
        ...state,
        loading: false,
        dahildeIsleme: data,
      };
    case GET_ORDER_REQUIRED_SUCCESS:
      return {
        ...state,
        loading: false,
        requiredList: data,
      };
    case GET_GTIP_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        gtipDocumentList: data,
      };
    case GET_GENEL_RAPORLAR_DETAY_SUCCESS:
      return {
        ...state,
        loading: false,
        genelRaporDetay: data,
      };
    case GET_GENEL_RAPOR_DETAYI_SUCCESS:
      return {
        ...state,
        loading: false,
        wantedGenelRaporDetay: data,
      };
    case GET_TALEP_LISTESI_SUCCESS:
      return {
        ...state,
        loading: false,
        talepler: data,
      };
    case ADD_GIT_CONSIGNMENTNOTE_SUCCESS:
    case UPDATE_GIT_CONSIGNMENTNOTE_SUCCESS:
    case UPDATE_INVOICES_ETA_SUCCESS:
      const newInvoices = state.invoices.map(invoice => {
        invoice.etaTarihi = data;
        return invoice;
      });
      return {
        ...state,
        invoices: newInvoices,
        loading: false,
      };
    case UPDATE_PAYMENT_DECLARATION_SUCCESS:
    case ADD_PAYMENT_DECLARATION_SUCCESS:
    case UPDATE_COMPANY_SUCCESS:
    case UPDATE_WAREHOUSE_DEFINITIONS_SUCCESS:
    case UPDATE_AGENT_DEFINITIONS_SUCCESS:
    case PUT_OLD_SO_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_SO_LIST_SUCCESS:
      return {
        ...state,
        solist: data,
        loading: false,
      };
    case ADD_SO_LIST_SUCCESS:
    case PATCH_SO_LIST_SUCCESS:
    case UPDATE_INVOICES_DETAIL_SUCCESS:
    case CREATE_DEMAND_SUCCESS:
    case DELETE_DEMAND_SUCCESS:
    case UPDATE_DEMAND_EVRIM_SUCCESS:
    case UPLOAD_DEMAND_SUCCESS:
    case CREATE_DEMAND_EXCEL_SUCCESS:
    case GET_ANTREPO_BEYANNAME_SUCCESS:
    case GET_ALL_SHIPMENT_DECLARATIONS_SUCCESS:
    case GET_ITHALAT_BEYANNAME_SUCCESS:
    case GET_BEYANNAME_IS_TAKIP_DETAY_SUCCESS:
    case GET_BEYANNAME_DETAY_SORGULA_SUCCESS:
    case PATCH_SO_NO_SUCCESS:
    case DELETE_TALEP_SUCCESS:
    case ADD_GTIP_SUCCESS:
    case ADD_GTIP_DETAY_SUCCESS:
    case CREATE_DOCUMENT_RELATION_SUCCESS:
    case GET_DOCUMENT_RELATION_SUCCESS:
    case DELETE_DOCUMENT_RELATION_SUCCESS:
    case GET_ONE_PRODUCT_TARIFF_SUCCESS:
    case DELETE_PRODUCT_TARIFF_SUCCESS:
    case GET_PRODUCT_TARIFF_MOVE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ADD_DOCUMENT_PRODUCT_SUCCESS:
      const newArr = [...state.documentProduct];
      newArr.push(data);
      return {
        ...state,
        loading: false,
        error: null,
        documentProduct: newArr,
      };
    case ADD_ORDER_NOTES_SUCCESS:
      const notArr = [...state.orderNotesList];
      notArr.push(data);
      return {
        ...state,
        loading: false,
        error: null,
        orderNotesList: notArr,
      };
    case ADD_ORDER_DECLARATION_SUCCESS:
      const declarationArr = [...state.orderDeclaration];
      declarationArr.push(data);
      return {
        ...state,
        loading: false,
        error: null,
        orderDeclaration: declarationArr,
      };

    case UPDATE_DOCUMENT_TRACKING_SUCCESS:
      const newArr3 = [];
      newArr3.push(data);
      return {
        ...state,
        loading: false,
        error: null,
        documentDetail: newArr3,
      };
    case UPDATE_DOCUMENT_PRODUCT_SUCCESS:
      const Index = state.documentProduct.findIndex(s => s.id === data.id);
      state.documentProduct[Index] = data;
      return {
        ...state,
        loading: false,
        error: null,
        documentProduct: state.documentProduct,
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        orderList: state.orderList,
      };
    case DOCUMENT_TRACKING_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        documentTrackingResult: data,
      };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case GET_ANTREPO_STOK_SUCCESS:
      return {
        ...state,
        antrepoStokRapor: data,
        loading: false,
      };
    case DOWNLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        fileLoading: false,
      };
    case GET_SO_PRODUCT_SUCCESS:
      return {
        ...state,
        soProduct: data,
        loading: false,
      };
    case GET_CURRENCY_SUCCESS:
      return {
        ...state,
        currency: data,
        loading: false,
      };
    case GET_PRODUCT_DOCUMENT_CONTROL_SUCCESS:
      return {
        ...state,
        loading: false,
        productDocuments: data,
      };
    case ORDER_REQUIRED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderRequiredDocuments: data,
      };
    case GET_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: data,
        loading: false,
      };
    case GET_ORIGIN_SUCCESS:
      return {
        ...state,
        origins: data,
        loading: false,
      };
    case GET_PLANT_SUCCESS:
      return {
        ...state,
        plants: data,
        loading: false,
      };
    case GET_MEASUREMENT_UNIT_SUCCESS:
      return {
        ...state,
        measurementUnits: data,
        loading: false,
      };
    case GET_DELIVERY_SUCCESS:
      return {
        ...state,
        delivery: data,
        loading: false,
      };
    case GET_TRANSPORT_SUCCESS:
      return {
        ...state,
        transport: data,
        loading: false,
      };
    case GET_WEBGUMRUK_BEYANNAME_SUCCESS:
      return {
        ...state,
        wgBeyanname: data,
        loading: false,
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: data,
        loading: false,
      };
    case GET_WAREHOUSE_STOCK_SUCCESS:
      return {
        ...state,
        antrepoStok: data,
        loading: false,
      };
    case GET_DEMAND_SUCCESS:
      return {
        ...state,
        talepListesi: data,
        loading: false,
      };
    case GET_DEMAND_INFO_SUCCESS:
      return {
        ...state,
        talepBilgiListesi: data,
        loading: false,
      };
    case GET_EVRIM_ARCHIVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        fileLoading: false,
        archiveDocument: data,
      };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: data,
      };
    case GET_WAREHOUSE_DEFINITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        warehouses: data,
      };
    case GET_AGENT_DEFINITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        agents: data,
      };
    case GET_WANTED_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: {
          productList: data,
        },
      };
    case GET_PRODUCT_SUCCESS:
      if (page !== undefined && page.page > 1) {
        return {
          ...state,
          loading: false,
          products: {
            productList: [...state.products.productList, ...data.productList],
            count: data.count,
          },
        };
      } else {
        return {
          ...state,
          loading: false,
          products: {
            productList: data.productList,
            count: data.count,
          },
        };
      }
    case GET_PRODUCT_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        productsAll: {
          productList: data.data.productList,
        },
      };
    case GET_GTIP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        gtipList: data,
      };
    case GET_GTIP_DETAY_SUCCESS:
      return {
        ...state,
        loading: false,
        gtipDetay: data,
      };
    case GET_DOCUMENT_TRACKING_SUCCESS:
      return {
        ...state,
        loading: false,
        documentTracking: data,
      };
    case GET_DOCUMENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        documentDetail: data,
      };
    case GET_DOCUMENT_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        documentProduct: data,
      };
    case GET_BEYAN_ONIZLEME_SUCCESS:
      return {
        ...state,
        loading: false,
        beyanOnizleme: data,
      };
    case GET_OLD_SO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        oldSoList: data,
      };
    case GET_DEMAND_DOCUMENT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        demandDocumentInfo: data,
      };
    case GET_ORDER_SUCCESS:
      // if (page.page > 1) {
      //   return {
      //     ...state,
      //     loading: false,
      //     orderList: {
      //       orderList: [...state.orderList.orderList, ...data.orderList],
      //       count: data.count,
      //       sumCount: data.sumCount,
      //     },
      //   };
      // } else {
      return {
        ...state,
        loading: false,
        orderList: data,
      };
    // }

    case GET_ORDER_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        orderList: {
          orderList: data.orderList,
          count: data.count,
          sumCount: data.sumCount,
        },
      };

    case GET_GECICI_SUCCESS:
      return {
        ...state,
        loading: false,
        geciciOrderList: {
          geciciOrderList: data.orderList,
          count: data.count,
        },
      };

    case GET_ORDER_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        orderNotesList: data,
      };
    case GET_ORDER_DECLARATION_SUCCESS:
      return {
        ...state,
        loading: false,
        orderDeclaration: data,
      };

    case GET_DOCUMENT_REZERVED_SUCCESS:
      return {
        ...state,
        loading: false,
        documentRezerved: data,
      };
    case GET_ORDER_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderLogs: data,
      };
    case DOCUMENT_REZERVE_SAVE_SUCCESS:
      return {
        ...state,
        documentRezerveSave: data,
        loading: false,
      };
    case DOCUMENT_REZERVE_DELETE_SUCCESS:
      return {
        ...state,
        documentRezerveSave: data,
        loading: false,
      };

    case GET_GECICI_ITHALAT_SUCCESS:
      return {
        ...state,
        geciciList: data,
        loading: false,
      };

    case UPDATE_GECICI_ITHALAT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_ORIGINAL_DOCUMENT_TRACKING_LIST_SUCCESS:
      return {
        ...state,
        originalDocumentList: data,
        loading: false,
      };
    case GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_SUCCESS:
      return {
        ...state,
        originalDocumentsByInvoiceNo: data,
        loading: false,
      };
    case GET_ORIGINAL_DOCUMENT_TYPE_LIST_SUCCESS:
      return {
        ...state,
        originalDocumentTypeList: data,
        loading: false,
      };

    case GET_PRODUCT_MASTAR_DATA_SUCCESS:
      return {
        ...state,
        productMasterData: data,
        loading: false,
      };

    case UPDATE_PRODUCT_MASTER_DATA_SUCCESS:
      const DataIndex = state.productMasterData.findIndex(
        s => s.id === data.id
      );
      state.productMasterData[DataIndex] = data;
      return {
        ...state,
        loading: false,
        error: null,
        productMasterData: state.productMasterData,
      };

    case ADD_PRODUCT_MASTER_DATA_SUCCESS:
      const newMasterData = [...state.productMasterData];
      newMasterData.push(data);

      return {
        ...state,
        loading: false,
        error: null,
        productMasterData: newMasterData,
      };

    case GET_GECICI_EXPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        geciciExport: data,
      };
    case GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        groupDetails: data,
      };
    case GET_FILTERING_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        products: {
          productList: data,
        },
      };
    case GET_IHBAR_TAKIP_SUCCESS:
      return {
        ...state,
        loading: false,
        ihbarTakipList: data,
      };
    case ADD_IHBAR_TAKIP_SUCCESS:
      const newData = [...state.ihbarTakipList];
      newData.push(data);
      return {
        ...state,
        loading: false,
        ihbarTakipList: newData,
      };

    case UPDATE_IHBAR_TAKIP_SUCCESS:
      const updatedIhbarIndex = state.ihbarTakipList.findIndex(
        s => s.id === data.id
      );
      state.ihbarTakipList[updatedIhbarIndex] = data;
      return {
        ...state,
        loading: false,
        error: null,
        ihbarTakipList: state.ihbarTakipList,
      };

    case GET_DONUS_DEPO_SUCCESS:
      return {
        ...state,
        loading: false,
        donusDepo: data,
      };

    case GET_MASRAF_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        masrafList: data,
      };

    case GET_MASRAF_DETAY_SUCCESS:
      return {
        ...state,
        loading: false,
        masrafDetay: data,
      };

    case GET_MASRAF_TIP_SUCCESS:
      return {
        ...state,
        loading: false,
        masrafTip: data,
      };

    case GET_MASRAF_BEYANNAME_SUCCESS:
      return {
        ...state,
        loading: false,
        masrafBeyanname: data,
      };
    case GET_FIRMA_EVRIM_SUCCESS:
      return {
        ...state,
        loading: false,
        firmaEvrim: data,
      };
    case GET_PRODUCT_TARIFF_LIST_SUCCESS:
      if (page !== undefined && page.page > 1) {
        return {
          ...state,
          loading: false,
          productTariffList: {
            productTariffList: [
              ...state.productTariffList.productTariffList,
              ...data.productTariffList,
            ],
            count: data.count,
          },
        };
      } else {
        return {
          ...state,
          loading: false,
          productTariffList: {
            productTariffList: data.productTariffList,
            count: data.count,
          },
        };
      }
    case POST_PRODUCT_TARIFF_SUCCESS:
      state.productTariffList.productTariffList.push(data);
      return {
        ...state,
        loading: false,
        error: null,
        productTariffList: {
          productTariffList: state.productTariffList.productTariffList,
          count: state.productTariffList.count + 1,
        },
      };
    case PATCH_PRODUCT_TARIFF_SUCCESS:
      const productTariffIndex = state.productTariffList.productTariffList.findIndex(
        x => x.id === data.id
      );
      state.productTariffList.productTariffList[productTariffIndex] = data;
      return {
        ...state,
        loading: false,
        error: null,
        productTariffList: {
          productTariffList: state.productTariffList.productTariffList,
          count: state.productTariffList.count,
        },
      };
    case GET_MUSAVIR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        musavir: data,
      };

    case GET_BEYANNAME_TASLAK_SUCCESS:
      return {
        ...state,
        loading: false,
        beyannameTaslak: data,
      };

    case GET_BEYANNAME_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        beyannameList: data,
      };
    case UPLOAD_GIT_ERROR:
    case ADD_GIT_ERROR:
    case GET_GIT_INVOICES_ERROR:
    case ADD_GIT_CONSIGNMENTNOTE_ERROR:
    case UPDATE_GIT_CONSIGNMENTNOTE_ERROR:
    case UPDATE_INVOICES_ETA_ERROR:
    case GET_SO_LIST_ERROR:
    case ADD_SO_LIST_ERROR:
    case GET_CURRENCY_ERROR:
    case GET_PRODUCT_CATEGORY_ERROR:
    case GET_DELIVERY_ERROR:
    case GET_TRANSPORT_ERROR:
    case UPDATE_INVOICES_DETAIL_ERROR:
    case GET_WAREHOUSE_STOCK_ERROR:
    case CREATE_DEMAND_ERROR:
    case DELETE_DEMAND_ERROR:
    case GET_DEMAND_ERROR:
    case UPDATE_DEMAND_EVRIM_ERROR:
    case GET_DEMAND_INFO_ERROR:
    case UPLOAD_DEMAND_ERROR:
    case CREATE_DEMAND_EXCEL_ERROR:
    case GET_ANTREPO_BEYANNAME_ERROR:
    case GET_ITHALAT_BEYANNAME_ERROR:
    case GET_ANTREPO_STOK_ERROR:
    case GET_BEYANNAME_IS_TAKIP_DETAY_ERROR:
    case GET_BEYANNAME_DETAY_SORGULA_ERROR:
    case GET_ALL_SHIPMENT_DECLARATIONS_ERROR:
    case DELETE_DOCUMENT_ERROR:
    case PATCH_SO_NO_ERROR:
    case GET_COUNTRIES_ERROR:
    case GET_MEASUREMENT_UNIT_ERROR:
    case GET_ORIGIN_ERROR:
    case GET_GTIP_DOCUMENT_TYPE_ERROR:
    case UPDATE_DOCUMENT_TRACKING_ERROR:
    case ADD_ORDER_NOTES_ERROR:
    case GET_ORDER_NOTES_ERROR:
    case GET_ORDER_DECLARATION_ERROR:
    case GET_DOCUMENT_REZERVED_ERROR:
    case GET_ORDER_LOGS_ERROR:
    case GET_GECICI_ITHALAT_ERROR:
    case UPDATE_GECICI_ITHALAT_ERROR:
    case GET_GECICI_EXPORT_ERROR:
    case DELETE_TALEP_ERROR:
    case POST_PRODUCT_TARIFF_ERROR:
    case PATCH_PRODUCT_TARIFF_ERROR:
      return {
        ...state,
        talepBilgiListesi: [],
        loading: false,
        error: err,
      };
    case GET_SHIPPING_LINE_ERROR:
      return {
        ...state,
        shippingList: [],
        error: err,
      };
    case GET_DAHILDE_ISLEME_ERROR:
      return {
        ...state,
        dahildeIsleme: [],
        error: err,
      };
    case GET_ORDER_REQUIRED_ERROR:
      return {
        ...state,
        requiredList: [],
        error: err,
      };
    case GET_PRODUCT_DOCUMENT_CONTROL_ERROR:
      return {
        ...state,
        productDocuments: [],
        loading: false,
        error: err,
      };
    case ORDER_REQUIRED_DOCUMENTS_ERROR:
      return {
        ...state,
        orderRequiredDocuments: [],
        loading: false,
        error: err,
      };
    case GET_EVRIM_ARCHIVE_DOCUMENT_ERROR:
      return {
        ...state,
        fileLoading: false,
        error: err,
      };
    case GET_WEBGUMRUK_BEYANNAME_ERROR:
      return {
        ...state,
        wgBeyanname: [],
        loading: false,
        error: err,
      };
    case UPDATE_COMPANY_ERROR:
    case UPDATE_WAREHOUSE_DEFINITIONS_ERROR:
    case UPDATE_AGENT_DEFINITIONS_ERROR:
    case GET_EVRIM_ARCHIVE_ERROR:
    case GET_PERNORD_ANTREPO_BEYAN_OLUSTUR_ERROR:
    case GET_GENEL_RAPORLAR_ERROR:
    case GET_GENEL_RAPORLAR_DETAY_ERROR:
    case GET_GENEL_RAPOR_DETAYI_ERROR:
    case ADD_GTIP_ERROR:
    case ADD_GTIP_DETAY_ERROR:
    case GET_PLANT_ERROR:
    case PUT_OLD_SO_ERROR:
    case UPDATE_DOCUMENT_PRODUCT_ERROR:
    case ADD_DOCUMENT_PRODUCT_ERROR:
    case GET_ORDER_ERROR:
    case GET_ORDER_FILTER_ERROR:
    case GET_GECICI_ERROR:
    case UPDATE_ORDER_ERROR:
    case ADD_ORDER_DECLARATION_ERROR:
    case GET_ORIGINAL_DOCUMENT_TRACKING_LIST_ERROR:
    case GET_ORIGINAL_DOCUMENT_TYPE_LIST_ERROR:
    case GET_ORIGINAL_DOCUMENT_TRACKING_BY_INVOICE_NO_ERROR:
    case DOCUMENT_TRACKING_SEARCH_ERROR:
    case UPDATE_PRODUCT_MASTER_DATA_ERROR:
    case GET_ORDER_AGENT_INFORMATION_ERROR:
    case ADD_PRODUCT_MASTER_DATA_ERROR:
    case ADD_IHBAR_TAKIP_ERROR:
    case UPDATE_IHBAR_TAKIP_ERROR:
    case GET_IHBAR_TAKIP_ERROR:
    case PATCH_SO_LIST_ERROR:
    case CREATE_DOCUMENT_RELATION_ERROR:
    case GET_DOCUMENT_RELATION_ERROR:
    case DELETE_DOCUMENT_RELATION_ERROR:
    case GET_FIRMA_EVRIM_ERROR:
    case GET_PRODUCT_TARIFF_LIST_ERROR:
    case GET_ONE_PRODUCT_TARIFF_ERROR:
    case DELETE_PRODUCT_TARIFF_ERROR:
    case GET_MUSAVIR_LIST_ERROR:
    case GET_PRODUCT_TARIFF_MOVE_LIST_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_SO_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        soProduct: [],
        error: err,
      };
    case DOWNLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        fileLoading: false,
        error: err,
      };
    case RESET_ERROR_MESSAGE:
      return {
        ...state,
        error: null,
      };
    case GET_GIT_DATA:
      return {
        ...state,
        payload: [action.payload],
      };
    case GET_ALL_DATA_ERROR:
      return {
        ...state,
        err,
      };
    case UPDATE_PAYMENT_DECLARATION_ERROR:
    case ADD_PAYMENT_DECLARATION_ERROR:
      return {
        ...state,
        error: err,
      };
    case GET_PAYMENT_ERROR:
      return {
        ...state,
        payment: [],
        error: err,
      };
    case GET_GIT_ONE_DOCUMENT_DATA:
      return {
        ...state,
        documentData: [action.payload],
      };
    case DOWNLOAD_GİT_REPORT:
      return {
        ...state,
        gitDocument: [action.payload],
      };
    case DOWNLOAD_GİT_REPORT_SUCCESS:
      return {
        ...state,
        gitDocument: [action.payload],
      };
    case GET_INVOICES_DATA:
      return {
        ...state,
        invoices: [action.invoices],
      };
    case GET_COMPANY_ERROR:
      return {
        ...state,
        companies: [],
        error: err,
      };
    case GET_WAREHOUSE_DEFINITIONS_ERROR:
      return {
        ...state,
        warehouses: [],
        error: err,
      };
    case GET_AGENT_DEFINITIONS_ERROR:
      return {
        ...state,
        agents: [],
        error: err,
      };

    case GET_PRODUCT_ERROR:
      return {
        ...state,
        products: [],
        error: err,
      };
    case GET_PRODUCT_ALL_ERROR:
      return {
        ...state,
        productsAll: [],
        error: err,
      };
    case GET_WANTED_PRODUCTS_ERROR:
      return {
        ...state,
        products: [],
        error: err,
      };
    case GET_TALEP_LISTESI_ERROR:
      return {
        ...state,
        talepler: [],
        error: err,
      };
    case GET_GTIP_LIST_ERROR:
      return {
        ...state,
        gtipList: [],
        error: err,
        loading: false,
      };
    case GET_GTIP_DETAY_ERROR:
      return {
        ...state,
        gtipDetay: [],
        error: err,
        loading: false,
      };
    case GET_DOCUMENT_TRACKING_ERROR:
      return {
        ...state,
        documentTracking: [],
        error: err,
        loading: false,
      };
    case GET_DOCUMENT_DETAIL_ERROR:
      return {
        ...state,
        documentDetail: [],
        error: err,
        loading: false,
      };
    case GET_DOCUMENT_PRODUCT_ERROR:
      return {
        ...state,
        documentProduct: [],
        error: err,
        loading: false,
      };
    case GET_BEYAN_ONIZLEME_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
        beyanOnizleme: {},
      };
    case GET_OLD_SO_LIST_ERROR:
    case GET_DEMAND_DOCUMENT_INFO_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
        oldSoList: [],
        demandDocumentInfo: [],
      };
    case DOCUMENT_REZERVE_SAVE_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
        documentRezerveSave: [],
      };
    case DOCUMENT_REZERVE_DELETE_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_PRODUCT_MASTER_DATA_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
        productMasterData: [],
      };
    case GET_GROUP_DETAIL_IN_PRODUCT_DETAIL_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
        groupDetails: [],
      };
    case GET_FILTERING_PRODUCTS_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_EVRIM_CEZA_BY_ORDER_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_DONUS_DEPO_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_MASRAF_LIST_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_MASRAF_DETAY_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_MASRAF_TIP_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_MASRAF_BEYANNAME_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_BEYANNAME_TASLAK_ERROR:
      return {
        ...state,
        beyannameTaslak: [],
        error: err,
      };
    case GET_BEYANNAME_LIST_ERROR:
      return {
        ...state,
        beyannameList: [],
        error: err,
      };
    default:
      return state;
  }
};

export { antrepoStokReducer };
