import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  ShipScreen,
  ContainerScreen,
  DocumentScreen,
  CompanyScreen,
  TaskTrackingScreen,
  UserListScreen,
  WarehouseDefinitions,
  BankDefinitions,
  AgentDefinitions,
  MasrafEtiket,
  DonusDepoList,
} from './index';

const commonRoutes = {
  gemiIslemleri: ShipScreen,
  konteynerIslemleri: ContainerScreen,
  evraklar: DocumentScreen,
  firmaIslemleri: CompanyScreen,
  isTakip: TaskTrackingScreen,
  personel: UserListScreen,
  warehouseDefinitions: WarehouseDefinitions,
  bankDefinitions: BankDefinitions,
  agentDefinitions: AgentDefinitions,
  masrafEtiket: MasrafEtiket,
  donusDepoList:DonusDepoList
};

const CommonRoutes = ({ menu }) => {
  return (
    <Switch>
      {/** Burada children menüye göre düzenleme yapılacak  */}
      {/** Menüde görünen routeları render etmektedir.  */}
      {menu.map(({ id, link, key }) => {
        return (
          <Route key={id} path={link} component={commonRoutes[key]} exact />
        );
      })}
      {/**  Child Routeları render etmektedir.Menude listelenmemektedir. */}
      {menu.map(({ childRoute }) => {
        return (
          childRoute.length !== 0 &&
          childRoute.map(({ id, link, key }) => {
            return (
              <Route key={id} path={link} component={commonRoutes[key]} exact />
            );
          })
        );
      })}
    </Switch>
  );
};

export default CommonRoutes;
