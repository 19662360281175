import actions from './actions';

const {
  MERGE_FILE_BEGIN,
  MERGE_FILE_SUCCESS,
  MERGE_FILE_ERROR,
  ADD_IMPORT_BEGIN,
  ADD_IMPORT_SUCCESS,
  ADD_IMPORT_ERROR,
  GET_IMPORT_BEGIN,
  GET_IMPORT_SUCCESS,
  GET_IMPORT_ERROR,
  DELETE_IMPORT_BEGIN,
  DELETE_IMPORT_SUCCESS,
  DELETE_IMPORT_ERROR,
  DOWNLOAD_IMPORT_BEGIN,
  DOWNLOAD_IMPORT_SUCCESS,
  DOWNLOAD_IMPORT_ERROR,
  REGISTRY_IMPORT_BEGIN,
  REGISTRY_IMPORT_SUCCESS,
  REGISTRY_IMPORT_ERROR,
  DELETE_REGISTRY_IMPORT_BEGIN,
  DELETE_REGISTRY_IMPORT_SUCCESS,
  DELETE_REGISTRY_IMPORT_ERROR,
  REGISTRY_EXIT_IMPORT_BEGIN,
  REGISTRY_EXIT_IMPORT_SUCCESS,
  REGISTRY_EXIT_IMPORT_ERROR,
  GET_CONTAINER_MOVE_BEGIN,
  GET_CONTAINER_MOVE_SUCCESS,
  GET_CONTAINER_MOVE_ERROR,
  GET_EXIT_IMPORT_BEGIN,
  GET_EXIT_IMPORT_SUCCESS,
  GET_EXIT_IMPORT_ERROR,
  ADD_NOT_MATCH_IMPORT_BEGIN,
  ADD_NOT_MATCH_IMPORT_SUCCESS,
  ADD_NOT_MATCH_IMPORT_ERROR,
  ADD_NOT_MATCH_CONTAINER_BEGIN,
  ADD_NOT_MATCH_CONTAINER_SUCCESS,
  ADD_NOT_MATCH_CONTAINER_ERROR,
  PATCH_OZET_BEYAN_BEGIN,
  PATCH_OZET_BEYAN_SUCCESS,
  PATCH_OZET_BEYAN_ERROR,
  GET_ROAD_IMPORT_BEGIN,
  GET_ROAD_IMPORT_SUCCESS,
  GET_ROAD_IMPORT_ERROR,
  ADD_ROAD_IMPORT_BEGIN,
  ADD_ROAD_IMPORT_SUCCESS,
  ADD_ROAD_IMPORT_ERROR,
  GET_RULES_IMPORT_BEGIN,
  GET_RULES_IMPORT_SUCCESS,
  GET_RULES_IMPORT_ERROR,
  PATCH_CONTAINER_COMMENT_BEGIN,
  PATCH_CONTAINER_COMMENT_SUCCESS,
  PATCH_CONTAINER_COMMENT_ERROR,
  PATCH_CONTAINER_TIME_BEGIN,
  PATCH_CONTAINER_TIME_SUCCESS,
  PATCH_CONTAINER_TIME_ERROR,
  GET_TURLIM_LIST_BEGIN,
  GET_TURLIM_LIST_SUCCESS,
  GET_TURLIM_LIST_ERROR,
  GET_IMPORT_CONTAINER_BEGIN,
  GET_IMPORT_CONTAINER_SUCCESS,
  GET_IMPORT_CONTAINER_ERROR,
  PATCH_LINE_BEGIN,
  PATCH_LINE_SUCCESS,
  PATCH_LINE_ERROR,
  DOWNLOAD_ROAD_IMPORT_BEGIN,
  DOWNLOAD_ROAD_IMPORT_SUCCESS,
  DOWNLOAD_ROAD_IMPORT_ERROR,
  UPLOAD_FILE_BEGIN,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
  RESET_ERROR_MESSAGE,
} = actions;

const initState = {
  mergedFile: [],
  list: [],
  containerMoveList: [],
  rules: [],
  loading: false,
  error: null,
};

const importReducer = (state = initState, action) => {
  const { type, data, page, err } = action;

  switch (type) {
    case ADD_IMPORT_BEGIN:
    case MERGE_FILE_BEGIN:
    case GET_IMPORT_BEGIN:
    case DOWNLOAD_IMPORT_BEGIN:
    case REGISTRY_IMPORT_BEGIN:
    case DELETE_REGISTRY_IMPORT_BEGIN:
    case REGISTRY_EXIT_IMPORT_BEGIN:
    case GET_CONTAINER_MOVE_BEGIN:
    case GET_EXIT_IMPORT_BEGIN:
    case ADD_NOT_MATCH_CONTAINER_BEGIN:
    case PATCH_OZET_BEYAN_BEGIN:
    case GET_ROAD_IMPORT_BEGIN:
    case ADD_ROAD_IMPORT_BEGIN:
    case DOWNLOAD_ROAD_IMPORT_BEGIN:
    case GET_RULES_IMPORT_BEGIN:
    case UPLOAD_FILE_BEGIN:
    case DELETE_IMPORT_BEGIN:
    case PATCH_CONTAINER_COMMENT_BEGIN:
    case PATCH_CONTAINER_TIME_BEGIN:
    case GET_TURLIM_LIST_BEGIN:
    case PATCH_LINE_BEGIN:
    case ADD_NOT_MATCH_IMPORT_BEGIN:
    case GET_IMPORT_CONTAINER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MERGE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        mergedFile: data,
      };
    case ADD_IMPORT_SUCCESS:
    case DOWNLOAD_IMPORT_SUCCESS:
    case REGISTRY_IMPORT_SUCCESS:
    case DELETE_REGISTRY_IMPORT_SUCCESS:
    case ADD_NOT_MATCH_CONTAINER_SUCCESS:
    case PATCH_OZET_BEYAN_SUCCESS:
    case GET_ROAD_IMPORT_SUCCESS:
    case ADD_ROAD_IMPORT_SUCCESS:
    case DOWNLOAD_ROAD_IMPORT_SUCCESS:
    case UPLOAD_FILE_SUCCESS:
    case DELETE_IMPORT_SUCCESS:
    case PATCH_CONTAINER_TIME_SUCCESS:
    case GET_TURLIM_LIST_SUCCESS:
    case GET_IMPORT_CONTAINER_SUCCESS:
    case PATCH_LINE_SUCCESS:
    case ADD_NOT_MATCH_IMPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PATCH_CONTAINER_COMMENT_SUCCESS:
      const manipulatedContainerMoveList = state.containerMoveList.list.map(
        item => ({
          ...item,
          ...data.find(newItem => newItem.id === item.id),
        })
      );
      return {
        ...state,
        loading: false,
        containerMoveList: {
          list: manipulatedContainerMoveList,
        },
      };
    case REGISTRY_EXIT_IMPORT_SUCCESS:
      const tempList = state.list;
      const result = state.list.existContainer.filter(
        ({ id }) => !data.includes(id)
      );
      tempList.existContainer = result;
      return {
        ...state,
        list: tempList,
        loading: false,
      };
    case GET_IMPORT_SUCCESS:
    case GET_EXIT_IMPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: data,
      };
    case GET_CONTAINER_MOVE_SUCCESS:
      if (page > 1) {
        return {
          ...state,
          loading: false,
          containerMoveList: {
            list: [...state.containerMoveList.list, ...data.list],
            count: data.count,
          },
        };
      } else {
        return {
          ...state,
          loading: false,
          containerMoveList: data,
        };
      }
    case GET_RULES_IMPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        rules: data,
      };
    case MERGE_FILE_ERROR:
    case ADD_IMPORT_ERROR:
    case GET_IMPORT_ERROR:
    case DOWNLOAD_IMPORT_ERROR:
    case REGISTRY_IMPORT_ERROR:
    case DELETE_REGISTRY_IMPORT_ERROR:
    case REGISTRY_EXIT_IMPORT_ERROR:
    case GET_CONTAINER_MOVE_ERROR:
    case GET_EXIT_IMPORT_ERROR:
    case ADD_NOT_MATCH_IMPORT_ERROR:
    case ADD_NOT_MATCH_CONTAINER_ERROR:
    case PATCH_OZET_BEYAN_ERROR:
    case GET_ROAD_IMPORT_ERROR:
    case ADD_ROAD_IMPORT_ERROR:
    case DOWNLOAD_ROAD_IMPORT_ERROR:
    case UPLOAD_FILE_ERROR:
    case DELETE_IMPORT_ERROR:
    case PATCH_CONTAINER_COMMENT_ERROR:
    case PATCH_CONTAINER_TIME_ERROR:
    case GET_TURLIM_LIST_ERROR:
    case GET_IMPORT_CONTAINER_ERROR:
    case PATCH_LINE_ERROR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_RULES_IMPORT_ERROR:
      return {
        ...state,
        loading: false,
        list: [],
        rules: [],
        error: err,
      };
    case RESET_ERROR_MESSAGE:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export { importReducer };
