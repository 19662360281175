const actions = {
  CREATE_INVOICE_BEGIN: 'CREATE_INVOICE_BEGIN',
  CREATE_INVOICE_SUCCESS: 'CREATE_INVOICE_SUCCESS',
  CREATE_INVOICE_ERROR: 'CREATE_INVOICE_ERROR',

  PATCH_INVOICE_BEGIN: 'PATCH_INVOICE_BEGIN',
  PATCH_INVOICE_SUCCESS: 'PATCH_INVOICE_SUCCESS',
  PATCH_INVOICE_ERROR: 'PATCH_INVOICE_ERROR',

  UPDATE_INVOICE_BEGIN: 'UPDATE_INVOICE_BEGIN',
  UPDATE_INVOICE_SUCCESS: 'UPDATE_INVOICE_SUCCESS',
  UPDATE_INVOICE_ERROR: 'UPDATE_INVOICE_ERROR',

  GET_INVOICES_BEGIN: 'GET_INVOICES_BEGIN',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  GET_INVOICES_ERROR: 'GET_INVOICES_ERROR',

  GET_ONE_INVOICE_BEGIN: 'GET_ONE_INVOICE_BEGIN',
  GET_ONE_INVOICE_SUCCESS: 'GET_ONE_INVOICE_SUCCESS',
  GET_ONE_INVOICE_ERROR: 'GET_ONE_INVOICE_ERROR',

  DELETE_INVOICE_BEGIN: 'DELETE_INVOICE_BEGIN',
  DELETE_INVOICE_SUCCESS: 'DELETE_INVOICE_SUCCESS',
  DELETE_INVOICE_ERROR: 'DELETE_INVOICE_ERROR',

  DOWNLOAD_WORK_ORDER_BEGIN: 'DOWNLOAD_WORK_ORDER_BEGIN',
  DOWNLOAD_WORK_ORDER_SUCCESS: 'DOWNLOAD_WORK_ORDER_SUCCESS',
  DOWNLOAD_WORK_ORDER_ERROR: 'DOWNLOAD_WORK_ORDER_ERROR',

  DOWNLOAD_WORK_ORDER_BY_PDF_BEGIN: 'DOWNLOAD_WORK_ORDER_BY_PDF_BEGIN',
  DOWNLOAD_WORK_ORDER_BY_PDF_SUCCESS: 'DOWNLOAD_WORK_ORDER_BY_PDF_SUCCESS',
  DOWNLOAD_WORK_ORDER_BY_PDF_ERROR: 'DOWNLOAD_WORK_ORDER_BY_PDF_ERROR',

  DOWNLOAD_DRAFT_WINSBBUP_BEGIN: 'DOWNLOAD_DRAFT_WINSBBUP_BEGIN',
  DOWNLOAD_DRAFT_WINSBBUP_SUCCESS: 'DOWNLOAD_DRAFT_WINSBBUP_SUCCESS',
  DOWNLOAD_DRAFT_WINSBBUP_ERROR: 'DOWNLOAD_DRAFT_WINSBBUP_ERROR',

  UPDATE_WINSBBUP_INVOICE_BEGIN: 'UPDATE_WINSBBUP_INVOICE_BEGIN',
  UPDATE_WINSBBUP_INVOICE_SUCCESS: 'UPDATE_WINSBBUP_INVOICE_SUCCESS',
  UPDATE_WINSBBUP_INVOICE_ERROR: 'UPDATE_WINSBBUP_INVOICE_ERROR',

  CREATE_FILE_EVRIM_BEGIN: 'CREATE_FILE_EVRIM_BEGIN',
  CREATE_FILE_EVRIM_SUCCESS: 'CREATE_FILE_EVRIM_SUCCESS',
  CREATE_FILE_EVRIM_ERROR: 'CREATE_FILE_EVRIM_ERROR',

  GET_SHIPPING_BEGIN: 'GET_SHIPPING_BEGIN',
  GET_SHIPPING_SUCCESS: 'GET_SHIPPING_SUCCESS',
  GET_SHIPPING_ERROR: 'GET_SHIPPING_ERROR',

  CREATE_SHIPPING_BEGIN: 'CREATE_SHIPPING_BEGIN',
  CREATE_SHIPPING_SUCCESS: 'CREATE_SHIPPING_SUCCESS',
  CREATE_SHIPPING_ERROR: 'CREATE_SHIPPING_ERROR',

  GET_SB_STOCK_BEGIN: 'GET_SB_STOCK_BEGIN',
  GET_SB_STOCK_SUCCESS: 'GET_SB_STOCK_SUCCESS',
  GET_SB_STOCK_ERROR: 'GET_SB_STOCK_ERROR',

  CREATE_SB_STOCK_BEGIN: 'CREATE_SB_STOCK_BEGIN',
  CREATE_SB_STOCK_SUCCESS: 'CREATE_SB_STOCK_SUCCESS',
  CREATE_SB_STOCK_ERROR: 'CREATE_SB_STOCK_ERROR',

  CREATE_REF_INVOICE_BEGIN: 'CREATE_REF_INVOICE_BEGIN',
  CREATE_REF_INVOICE_SUCCESS: 'CREATE_REF_INVOICE_SUCCESS',
  CREATE_REF_INVOICE_ERROR: 'CREATE_REF_INVOICE_ERROR',

  UPDATE_REF_INVOICE_BEGIN: 'UPDATE_REF_INVOICE_BEGIN',
  UPDATE_REF_INVOICE_SUCCESS: 'UPDATE_REF_INVOICE_SUCCESS',
  UPDATE_REF_INVOICE_ERROR: 'UPDATE_REF_INVOICE_ERROR',

  CREATE_MANUEL_INVOICE_BEGIN: 'CREATE_MANUEL_INVOICE_BEGIN',
  CREATE_MANUEL_INVOICE_SUCCESS: 'CREATE_MANUEL_INVOICE_SUCCESS',
  CREATE_MANUEL_INVOICE_ERROR: 'CREATE_MANUEL_INVOICE_ERROR',

  UPDATE_INVOICE_ITEM_BEGIN: 'UPDATE_INVOICE_ITEM_BEGIN',
  UPDATE_INVOICE_ITEM_SUCCESS: 'UPDATE_INVOICE_ITEM_SUCCESS',
  UPDATE_INVOICE_ITEM_ERROR: 'UPDATE_INVOICE_ITEM_ERROR',

  GET_INVOICE_ITEM_BEGIN: 'GET_INVOICE_ITEM_BEGIN',
  GET_INVOICE_ITEM_SUCCESS: 'GET_INVOICE_ITEM_SUCCESS',
  GET_INVOICE_ITEM_ERROR: 'GET_INVOICE_ITEM_ERROR',

  CHECK_INVOICES_BEGIN: 'CHECK_INVOICES_BEGIN',
  CHECK_INVOICES_SUCCESS: 'CHECK_INVOICES_SUCCESS',
  CHECK_INVOICES_ERROR: 'CHECK_INVOICES_ERROR',

  PATCH_INVOICE_ZONE_BEGIN: 'PATCH_INVOICE_ZONE_BEGIN',
  PATCH_INVOICE_ZONE_SUCCESS: 'PATCH_INVOICE_ZONE_SUCCESS',
  PATCH_INVOICE_ZONE_ERROR: 'PATCH_INVOICE_ZONE_ERROR',

  DOWNLOAD_UBF_DRAFT_BEGIN: 'DOWNLOAD_UBF_DRAFT_BEGIN',
  DOWNLOAD_UBF_DRAFT_SUCCESS: 'DOWNLOAD_UBF_DRAFT_SUCCESS',
  DOWNLOAD_UBF_DRAFT_ERROR: 'DOWNLOAD_UBF_DRAFT_ERROR',

  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE', // Reduxdan Hata Temizlemektedir.

  createInvoiceBegin: () => {
    return {
      type: actions.CREATE_INVOICE_BEGIN,
    };
  },

  createInvoiceSuccess: data => {
    return {
      type: actions.CREATE_INVOICE_SUCCESS,
      data,
    };
  },

  createInvoiceError: err => {
    return {
      type: actions.CREATE_INVOICE_ERROR,
      err,
    };
  },

  patchInvoiceBegin: () => {
    return {
      type: actions.PATCH_INVOICE_BEGIN,
    };
  },

  patchInvoiceSuccess: data => {
    return {
      type: actions.PATCH_INVOICE_SUCCESS,
      data,
    };
  },

  patchInvoiceError: err => {
    return {
      type: actions.PATCH_INVOICE_ERROR,
      err,
    };
  },

  updateInvoiceBegin: () => {
    return {
      type: actions.UPDATE_INVOICE_BEGIN,
    };
  },

  updateInvoiceSuccess: data => {
    return {
      type: actions.UPDATE_INVOICE_SUCCESS,
      data,
    };
  },

  updateInvoiceError: err => {
    return {
      type: actions.UPDATE_INVOICE_ERROR,
      err,
    };
  },

  getInvoicesBegin: () => {
    return {
      type: actions.GET_INVOICES_BEGIN,
    };
  },

  getInvoicesSuccess: (data, page) => {
    return {
      type: actions.GET_INVOICES_SUCCESS,
      data,
      page,
    };
  },

  getInvoicesError: err => {
    return {
      type: actions.GET_INVOICES_ERROR,
      err,
    };
  },

  getOneInvoiceBegin: () => {
    return {
      type: actions.GET_ONE_INVOICE_BEGIN,
    };
  },

  getOneInvoiceSuccess: data => {
    return {
      type: actions.GET_ONE_INVOICE_SUCCESS,
      data,
    };
  },

  getOneInvoiceError: err => {
    return {
      type: actions.GET_ONE_INVOICE_ERROR,
      err,
    };
  },

  deleteInvoiceBegin: () => {
    return {
      type: actions.DELETE_INVOICE_BEGIN,
    };
  },

  deleteInvoiceSuccess: (data, id) => {
    return {
      type: actions.DELETE_INVOICE_SUCCESS,
      data,
      id,
    };
  },

  deleteInvoiceError: err => {
    return {
      type: actions.DELETE_INVOICE_ERROR,
      err,
    };
  },

  downloadWorkOrderBegin: () => {
    return {
      type: actions.DOWNLOAD_WORK_ORDER_BEGIN,
    };
  },

  downloadWorkOrderSuccess: data => {
    return {
      type: actions.DOWNLOAD_WORK_ORDER_SUCCESS,
      data,
    };
  },

  downloadWorkOrderError: err => {
    return {
      type: actions.DOWNLOAD_WORK_ORDER_ERROR,
      err,
    };
  },

  downloadWorkOrderByPDFBegin: () => {
    return {
      type: actions.DOWNLOAD_WORK_ORDER_BY_PDF_BEGIN,
    };
  },

  downloadWorkOrderByPDFSuccess: data => {
    return {
      type: actions.DOWNLOAD_WORK_ORDER_BY_PDF_SUCCESS,
      data,
    };
  },

  downloadWorkOrderByPDFError: err => {
    return {
      type: actions.DOWNLOAD_WORK_ORDER_BY_PDF_ERROR,
      err,
    };
  },

  downloadDraftWinSBBUPBegin: () => {
    return {
      type: actions.DOWNLOAD_DRAFT_WINSBBUP_BEGIN,
    };
  },

  downloadDraftWinSBBUPSuccess: data => {
    return {
      type: actions.DOWNLOAD_DRAFT_WINSBBUP_SUCCESS,
      data,
    };
  },

  downloadDraftWinSBBUPError: err => {
    return {
      type: actions.DOWNLOAD_DRAFT_WINSBBUP_ERROR,
      err,
    };
  },

  updateWinSBBUPInvoiceBegin: () => {
    return {
      type: actions.UPDATE_WINSBBUP_INVOICE_BEGIN,
    };
  },

  updateWinSBBUPInvoiceSuccess: data => {
    return {
      type: actions.UPDATE_WINSBBUP_INVOICE_SUCCESS,
      data,
    };
  },

  updateWinSBBUPInvoiceError: err => {
    return {
      type: actions.UPDATE_WINSBBUP_INVOICE_ERROR,
      err,
    };
  },

  createFileEvrimBegin: () => {
    return {
      type: actions.CREATE_FILE_EVRIM_BEGIN,
    };
  },

  createFileEvrimSuccess: data => {
    return {
      type: actions.CREATE_FILE_EVRIM_SUCCESS,
      data,
    };
  },

  createFileEvrimError: err => {
    return {
      type: actions.CREATE_FILE_EVRIM_ERROR,
      err,
    };
  },

  getShippingBegin: () => {
    return {
      type: actions.GET_SHIPPING_BEGIN,
    };
  },

  getShippingSuccess: data => {
    return {
      type: actions.GET_SHIPPING_SUCCESS,
      data,
    };
  },

  getShippingError: err => {
    return {
      type: actions.GET_SHIPPING_ERROR,
      err,
    };
  },

  createShippingBegin: () => {
    return {
      type: actions.CREATE_SHIPPING_BEGIN,
    };
  },

  createShippingSuccess: data => {
    return {
      type: actions.CREATE_SHIPPING_SUCCESS,
      data,
    };
  },

  createShippingError: err => {
    return {
      type: actions.CREATE_SHIPPING_ERROR,
      err,
    };
  },

  getSBStockBegin: () => {
    return {
      type: actions.GET_SB_STOCK_BEGIN,
    };
  },

  getSBStockSuccess: data => {
    return {
      type: actions.GET_SB_STOCK_SUCCESS,
      data,
    };
  },

  getSBStockError: err => {
    return {
      type: actions.GET_SB_STOCK_ERROR,
      err,
    };
  },

  createSBStockBegin: () => {
    return {
      type: actions.CREATE_SB_STOCK_BEGIN,
    };
  },

  createSBStockSuccess: data => {
    return {
      type: actions.CREATE_SB_STOCK_SUCCESS,
      data,
    };
  },

  createSBStockError: err => {
    return {
      type: actions.CREATE_SB_STOCK_ERROR,
      err,
    };
  },

  createRefInvoiceBegin: () => {
    return {
      type: actions.CREATE_REF_INVOICE_BEGIN,
    };
  },

  createRefInvoiceSuccess: data => {
    return {
      type: actions.CREATE_REF_INVOICE_SUCCESS,
      data,
    };
  },

  createRefInvoiceError: err => {
    return {
      type: actions.CREATE_REF_INVOICE_ERROR,
      err,
    };
  },

  updateRefInvoiceBegin: () => {
    return {
      type: actions.UPDATE_REF_INVOICE_BEGIN,
    };
  },

  updateRefInvoiceSuccess: data => {
    return {
      type: actions.UPDATE_REF_INVOICE_SUCCESS,
      data,
    };
  },

  updateRefInvoiceError: err => {
    return {
      type: actions.UPDATE_REF_INVOICE_ERROR,
      err,
    };
  },

  createManuelInvoiceBegin: () => {
    return {
      type: actions.CREATE_MANUEL_INVOICE_BEGIN,
    };
  },

  createManuelInvoiceSuccess: data => {
    return {
      type: actions.CREATE_MANUEL_INVOICE_SUCCESS,
      data,
    };
  },

  createManuelInvoiceError: err => {
    return {
      type: actions.CREATE_MANUEL_INVOICE_ERROR,
      err,
    };
  },

  updateInvoiceItemBegin: () => {
    return {
      type: actions.UPDATE_INVOICE_ITEM_BEGIN,
    };
  },

  updateInvoiceItemSuccess: data => {
    return {
      type: actions.UPDATE_INVOICE_ITEM_SUCCESS,
      data,
    };
  },

  updateInvoiceItemError: err => {
    return {
      type: actions.UPDATE_INVOICE_ITEM_ERROR,
      err,
    };
  },

  getInvoiceItemBegin: () => {
    return {
      type: actions.GET_INVOICE_ITEM_BEGIN,
    };
  },

  getInvoiceItemSuccess: data => {
    return {
      type: actions.GET_INVOICE_ITEM_SUCCESS,
      data,
    };
  },

  getInvoiceItemError: err => {
    return {
      type: actions.GET_INVOICE_ITEM_ERROR,
      err,
    };
  },

  checkInvoiceBegin: () => {
    return {
      type: actions.CHECK_INVOICES_BEGIN,
    };
  },

  checkInvoiceSuccess: data => {
    return {
      type: actions.CHECK_INVOICES_SUCCESS,
      data,
    };
  },

  checkInvoiceError: err => {
    return {
      type: actions.CHECK_INVOICES_ERROR,
      err,
    };
  },

  patchInvoiceZoneBegin: () => {
    return {
      type: actions.PATCH_INVOICE_ZONE_BEGIN,
    };
  },

  patchInvoiceZoneSuccess: data => {
    return {
      type: actions.PATCH_INVOICE_ZONE_SUCCESS,
      data,
    };
  },

  patchInvoiceZoneError: err => {
    return {
      type: actions.PATCH_INVOICE_ZONE_ERROR,
      err,
    };
  },

  downloadUBFDraftBegin: () => {
    return {
      type: actions.DOWNLOAD_UBF_DRAFT_BEGIN,
    };
  },

  downloadUBFDraftSuccess: data => {
    return {
      type: actions.DOWNLOAD_UBF_DRAFT_SUCCESS,
      data,
    };
  },

  downloadUBFDraftError: err => {
    return {
      type: actions.DOWNLOAD_UBF_DRAFT_ERROR,
      err,
    };
  },

  resetErrorMessage: () => {
    return {
      type: actions.RESET_ERROR_MESSAGE,
    };
  },
};

export default actions;
