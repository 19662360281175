import actions from './actions';

const {
  changeLayoutBegin,
  changeLayoutSuccess,
  changeLayoutErr,
  changeRtlBegin,
  changeRtlSuccess,
  changeRtlErr,
  changeMenuBegin,
  changeMenuSuccess,
  changeMenuErr,
  changeMenuCollapsedBegin,
  changeMenuCollapsedSuccess,
  changeMenuCollapsedErr,
  changeLanguageBegin,
  changeLanguageSuccess,
  changeLanguageErr,
} = actions;

const changeLayoutMode = value => {
  return async dispatch => {
    try {
      dispatch(changeLayoutBegin());
      dispatch(changeLayoutSuccess(value));
    } catch (err) {
      dispatch(changeLayoutErr(err));
    }
  };
};

const changeRtlMode = value => {
  return async dispatch => {
    try {
      dispatch(changeRtlBegin());
      dispatch(changeRtlSuccess(value));
    } catch (err) {
      dispatch(changeRtlErr(err));
    }
  };
};

const changeMenuMode = value => {
  return async dispatch => {
    try {
      dispatch(changeMenuBegin());
      dispatch(changeMenuSuccess(value));
    } catch (err) {
      dispatch(changeMenuErr(err));
    }
  };
};

const changeMenuCollapsed = value => {
  return async dispatch => {
    try {
      dispatch(changeMenuCollapsedBegin());
      dispatch(changeMenuCollapsedSuccess(value));
    } catch (err) {
      dispatch(changeMenuCollapsedErr(err));
    }
  };
};

const changeLanguage = value => {
  return async dispatch => {
    try {
      dispatch(changeLanguageBegin());
      dispatch(changeLanguageSuccess(value));
    } catch (err) {
      dispatch(changeLanguageErr(err));
    }
  };
};

export {
  changeLayoutMode,
  changeRtlMode,
  changeMenuMode,
  changeMenuCollapsed,
  changeLanguage,
};
