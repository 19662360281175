export default {
  gatesTitle: 'Gates Consensus Report',
  company: 'Company Select',
  uploadButtonName: 'Select xlsx, xls only',
  excelErrorMessage: 'Please Select Excel',
  errorReportTypeMessage: 'Please Select Report Type',
  errorCompanyMessage:'Please Select Company',
  reportTypeLabel: 'Report Type',
  columnLabel: 'Please Select Column',
  uploadError: '{{ fileName }} is not a xlsx or xls file',
  anyBeforeUploadError: '{{fileName}} is not a xlsx,xls or pdf file',
};
