import { lazy } from 'react';

const ExportBookingScreen = lazy(() =>
  import('../../../screen/Acente/ExportBookingScreen/ExportBookingScreen')
);

const ExportScreen = lazy(() =>
  import('../../../screen/Acente/ExportScreen/ExportScreen')
);

const VesselInformations = lazy(() =>
  import('../../../screen/Acente/VesselInformations/VesselInformations')
);

const ImportUploadScreen = lazy(() =>
  import('../../../screen/Acente/ImportUploadScreen/ImportUploadScreen')
);

const ImportEntryScreen = lazy(() =>
  import('../../../screen/Acente/ImportEntryScreen/ImportEntry')
);

const ImportExitScreen = lazy(() =>
  import('../../../screen/Acente/ImportExitScreen/ImportExitScreen')
);

const ImportRoad = lazy(() =>
  import('../../../screen/Acente/ImportRoad/ImportRoad')
);

const ContainerMove = lazy(() =>
  import('../../../screen/Acente/ContainerMove/ContainerMove')
);

const VesselMove = lazy(() =>
  import('../../../screen/Acente/VesselMove/VesselMove')
);

const AddVesselMove = lazy(() =>
  import('../../../screen/Acente/AddVesselMove/AddVesselMove')
);

const PoUploadScreen = lazy(() =>
  import('../../../screen/Acente/PoUploadScreen/PoUploadScreen')
);

const PoListScreen = lazy(() =>
  import('../../../screen/Acente/PoListScreen/PoListScreen')
);

export {
  ExportBookingScreen,
  ExportScreen,
  ImportUploadScreen,
  ImportEntryScreen,
  ImportExitScreen,
  ImportRoad,
  ContainerMove,
  VesselInformations,
  VesselMove,
  AddVesselMove,
  PoUploadScreen,
  PoListScreen,
};
