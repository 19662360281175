import React from 'react';
import { Row, Col } from 'antd';
// import { Aside } from './overview/style';
// import Heading from '../../../components/heading/heading';

const AuthLayout = (WraperContent) => {
  return () => {
    return (
      <Row
        className="justify-content-center flex-direction-column full-height"
        style={{ backgroundColor: '#f4f5f7' }}
      >
        <Col xxl={24} xl={24} lg={24} md={24} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
