import actions from './actions';
import _ from 'lodash';

const {
  GET_SHIP_BEGIN,
  GET_SHIP_SUCCESS,
  GET_SHIP_ERROR,
  CREATE_SHIP_BEGIN,
  CREATE_SHIP_SUCCESS,
  CREATE_SHIP_ERROR,
  UPDATE_SHIP_BEGIN,
  UPDATE_SHIP_SUCCESS,
  UPDATE_SHIP_ERROR,
  DELETE_SHIP_BEGIN,
  DELETE_SHIP_SUCCESS,
  DELETE_SHIP_ERROR,
  GET_CONTAINER_BEGIN,
  GET_CONTAINER_SUCCESS,
  GET_CONTAINER_ERROR,
  CREATE_CONTAINER_BEGIN,
  CREATE_CONTAINER_SUCCESS,
  CREATE_CONTAINER_ERROR,
  UPDATE_CONTAINER_BEGIN,
  UPDATE_CONTAINER_SUCCESS,
  UPDATE_CONTAINER_ERROR,
  PATCH_SO_CONTAINER_BEGIN,
  PATCH_SO_CONTAINER_SUCCESS,
  PATCH_SO_CONTAINER_ERROR,
  DELETE_CONTAINER_BEGIN,
  DELETE_CONTAINER_SUCCESS,
  DELETE_CONTAINER_ERROR,
  GET_STOCK_BEGIN,
  GET_STOCK_SUCCESS,
  GET_STOCK_ERROR,
  CREATE_STOCK_BEGIN,
  CREATE_STOCK_SUCCESS,
  CREATE_STOCK_ERROR,
  GET_CUSTOMER_BEGIN,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_ERROR,
  CREATE_CUSTOMER_BEGIN,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_ERROR,
  GET_PORT_BEGIN,
  GET_PORT_SUCCESS,
  GET_PORT_ERROR,
  GET_CUSTOMS_BEGIN,
  GET_CUSTOMS_SUCCESS,
  GET_CUSTOMS_ERROR,
  CREATE_DEMAND_BEGIN,
  CREATE_DEMAND_SUCCESS,
  CREATE_DEMAND_ERROR,
  UPDATE_DEMAND_BEGIN,
  UPDATE_DEMAND_SUCCESS,
  UPDATE_DEMAND_ERROR,
  GET_DEMAND_BEGIN,
  GET_DEMAND_SUCCESS,
  GET_DEMAND_ERROR,
  GET_ONE_DEMAND_BEGIN,
  GET_ONE_DEMAND_SUCCESS,
  GET_ONE_DEMAND_ERROR,
  GET_DUZELTME_TURU_BEGIN,
  GET_DUZELTME_TURU_SUCCESS,
  GET_DUZELTME_TURU_ERROR,
  POST_DOC_TO_PDF_BEGIN,
  POST_DOC_TO_PDF_SUCCESS,
  POST_DOC_TO_PDF_ERROR,
  GET_CONFIRMATION_BEGIN,
  GET_CONFIRMATION_SUCCESS,
  GET_CONFIRMATION_ERROR,
  PUT_CONFIRMATION_BEGIN,
  PUT_CONFIRMATION_SUCCESS,
  PUT_CONFIRMATION_ERROR,
  POST_DEMAND_RESULT_BEGIN,
  POST_DEMAND_RESULT_SUCCESS,
  POST_DEMAND_RESULT_ERROR,
  GET_DEMAND_RESULT_BEGIN,
  GET_DEMAND_RESULT_SUCCESS,
  GET_DEMAND_RESULT_ERROR,
  GET_CONTAINER_BY_BOOKING_NO_BEGIN,
  GET_CONTAINER_BY_BOOKING_NO_SUCCESS,
  GET_CONTAINER_BY_BOOKING_NO_ERROR,
  GET_LINE_BEGIN,
  GET_LINE_SUCCESS,
  GET_LINE_ERROR,
  GET_EVACUATION_BEGIN,
  GET_EVACUATION_SUCCESS,
  GET_EVACUATION_ERROR,
  GET_LOADING_BEGIN,
  GET_LOADING_SUCCESS,
  GET_LOADING_ERROR,
  GET_VESSEL_MOVE_BEGIN,
  GET_VESSEL_MOVE_SUCCESS,
  GET_VESSEL_MOVE_ERROR,
  CREATE_VESSEL_MOVE_BEGIN,
  CREATE_VESSEL_MOVE_SUCCESS,
  CREATE_VESSEL_MOVE_ERROR,
  DELETE_VESSEL_MOVE_BEGIN,
  DELETE_VESSEL_MOVE_SUCCESS,
  DELETE_VESSEL_MOVE_ERROR,
  UPDATE_VESSEL_MOVE_BEGIN,
  UPDATE_VESSEL_MOVE_SUCCESS,
  UPDATE_VESSEL_MOVE_ERROR,
  GET_TARIFF_BEGIN,
  GET_TARIFF_SUCCESS,
  GET_TARIFF_ERROR,
  CREATE_COST_BEGIN,
  CREATE_COST_SUCCESS,
  CREATE_COST_ERROR,
  GET_COST_BY_TYPEID_BEGIN,
  GET_COST_BY_TYPEID_SUCCESS,
  GET_COST_BY_TYPEID_ERROR,
  CREATE_STAMP_DUTY_BEGIN,
  CREATE_STAMP_DUTY_SUCCESS,
  CREATE_STAMP_DUTY_ERROR,
  GET_PO_FILE_BEGIN,
  GET_PO_FILE_SUCCESS,
  GET_PO_FILE_ERROR,
  CREATE_PO_LIST_BEGIN,
  CREATE_PO_LIST_SUCCESS,
  CREATE_PO_LIST_ERROR,
  POST_CHECK_PO_BEGIN,
  POST_CHECK_PO_SUCCESS,
  POST_CHECK_PO_ERROR,
  GET_KTF_INFO_BEGIN,
  GET_KTF_INFO_SUCCESS,
  GET_KTF_INFO_ERROR,
  GET_PO_LIST_BEGIN,
  GET_PO_LIST_SUCCESS,
  GET_PO_LIST_ERROR,
  GET_PO_LIST_DETAIL_BEGIN,
  GET_PO_LIST_DETAIL_SUCCESS,
  GET_PO_LIST_DETAIL_ERROR,
  PATCH_APPROVAL_PO_BEGIN,
  PATCH_APPROVAL_PO_SUCCESS,
  PATCH_APPROVAL_PO_ERROR,
  PATCH_APPROVAL_CANCEL_PO_BEGIN,
  PATCH_APPROVAL_CANCEL_PO_SUCCESS,
  PATCH_APPROVAL_CANCEL_PO_ERROR,
  PATCH_EVRIM_PO_BEGIN,
  PATCH_EVRIM_PO_SUCCESS,
  PATCH_EVRIM_PO_ERROR,
  PATCH_PO_NO_BEGIN,
  PATCH_PO_NO_SUCCESS,
  PATCH_PO_NO_ERROR,
  PATCH_APPROVAL_PARTIAL_PO_BEGIN,
  PATCH_APPROVAL_PARTIAL_PO_SUCCESS,
  PATCH_APPROVAL_PARTIAL_PO_ERROR,
  PATCH_CANCEL_PARTIAL_PO_BEGIN,
  PATCH_CANCEL_PARTIAL_PO_SUCCESS,
  PATCH_CANCEL_PARTIAL_PO_ERROR,
  RESET_ERROR_MESSAGE,
} = actions;

const initState = {
  customer: [],
  ship: [],
  container: [],
  containerByBookingNo: [],
  stock: [],
  port: [],
  customs: [],
  lines: [],
  demands: [],
  confirmationName: [],
  confirmationMail: [],
  evacuationList: [],
  vesselMove: [],
  tariff: [],
  duzeltmeTurleri: [],
  cost: {},
  poList: [],
  poListDetail: [],
  poManifestList: [],
  loading: false,
  error: null,
};

const acenteReducer = (state = initState, action) => {
  const { type, data, page, err, paramsType } = action;
  switch (type) {
    case GET_SHIP_BEGIN:
    case CREATE_SHIP_BEGIN:
    case UPDATE_SHIP_BEGIN:
    case DELETE_SHIP_BEGIN:
    case GET_CONTAINER_BEGIN:
    case CREATE_CONTAINER_BEGIN:
    case UPDATE_CONTAINER_BEGIN:
    case DELETE_CONTAINER_BEGIN:
    case GET_STOCK_BEGIN:
    case CREATE_STOCK_BEGIN:
    case GET_CUSTOMER_BEGIN:
    case CREATE_CUSTOMER_BEGIN:
    case GET_PORT_BEGIN:
    case GET_CUSTOMS_BEGIN:
    case GET_DUZELTME_TURU_BEGIN:
    case CREATE_DEMAND_BEGIN:
    case GET_DEMAND_BEGIN:
    case GET_ONE_DEMAND_BEGIN:
    case POST_DOC_TO_PDF_BEGIN:
    case PATCH_SO_CONTAINER_BEGIN:
    case GET_CONFIRMATION_BEGIN:
    case PUT_CONFIRMATION_BEGIN:
    case POST_DEMAND_RESULT_BEGIN:
    case GET_DEMAND_RESULT_BEGIN:
    case GET_CONTAINER_BY_BOOKING_NO_BEGIN:
    case GET_LINE_BEGIN:
    case GET_EVACUATION_BEGIN:
    case GET_LOADING_BEGIN:
    case UPDATE_DEMAND_BEGIN:
    case GET_VESSEL_MOVE_BEGIN:
    case CREATE_VESSEL_MOVE_BEGIN:
    case DELETE_VESSEL_MOVE_BEGIN:
    case UPDATE_VESSEL_MOVE_BEGIN:
    case GET_TARIFF_BEGIN:
    case CREATE_COST_BEGIN:
    case GET_COST_BY_TYPEID_BEGIN:
    case CREATE_STAMP_DUTY_BEGIN:
    case GET_PO_FILE_BEGIN:
    case CREATE_PO_LIST_BEGIN:
    case POST_CHECK_PO_BEGIN:
    case GET_KTF_INFO_BEGIN:
    case GET_PO_LIST_BEGIN:
    case GET_PO_LIST_DETAIL_BEGIN:
    case PATCH_APPROVAL_PO_BEGIN:
    case PATCH_APPROVAL_CANCEL_PO_BEGIN:
    case PATCH_EVRIM_PO_BEGIN:
    case PATCH_PO_NO_BEGIN:
    case PATCH_APPROVAL_PARTIAL_PO_BEGIN:
    case PATCH_CANCEL_PARTIAL_PO_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SHIP_SUCCESS:
      if (page > 1) {
        return {
          ...state,
          loading: false,
          ship: [...state.ship, ...data],
        };
      } else {
        return {
          ...state,
          loading: false,
          ship: data,
        };
      }
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: data,
      };
    case CREATE_SHIP_SUCCESS:
      state.ship.push(data);
      return {
        ...state,
        loading: false,
        ship: state.ship,
      };
    case UPDATE_SHIP_SUCCESS:
      const shipIndex = state.ship.findIndex(s => s.id === data.id);
      state.ship[shipIndex] = data;
      return {
        ...state,
        loading: false,
        ship: state.ship,
      };
    case DELETE_SHIP_SUCCESS:
      const shipIndex2 = state.ship.findIndex(gemi => gemi.id === data.id);
      state.ship.splice(shipIndex2, 1);
      return {
        ...state,
        loading: false,
        ship: state.ship,
      };
    case GET_CONTAINER_SUCCESS:
      if (page > 1) {
        return {
          ...state,
          loading: false,
          container: [...state.container, ...data],
        };
      } else {
        return {
          ...state,
          loading: false,
          container: data,
        };
      }
    case CREATE_CONTAINER_SUCCESS:
      const prevStateContainer = state.container;
      const containerIndex = prevStateContainer.findIndex(
        c => c.id === data.id
      );
      if (containerIndex < 0) prevStateContainer.push(data);
      else prevStateContainer[containerIndex] = data;
      return {
        ...state,
        loading: false,
        container: prevStateContainer,
      };
    case UPDATE_CONTAINER_SUCCESS:
      const prevStateContainer2 = state.container;
      const containerIndex2 = prevStateContainer2.findIndex(
        c => c.id === data.id
      );
      prevStateContainer2[containerIndex2] = data;
      return {
        ...state,
        loading: false,
        container: prevStateContainer2,
      };
    case PATCH_SO_CONTAINER_SUCCESS:
      const manipulatedContainerList = state.container.map(item => ({
        ...item,
        ...data.find(newItem => newItem.id === item.id),
      }));
      return {
        ...state,
        loading: false,
        container: manipulatedContainerList,
      };
    case DELETE_CONTAINER_SUCCESS:
      const prevStateContainer3 = state.container;
      const containerIndex3 = prevStateContainer3.findIndex(c => c.id === data);
      prevStateContainer3.splice(containerIndex3, 1);
      return {
        ...state,
        loading: false,
        container: prevStateContainer3,
      };
    case GET_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        stock: data,
      };
    case CREATE_STOCK_SUCCESS:
      let { id, kodu, adi } = data;
      const oldStockCodeIndex = state.stock.findIndex(s => s.kodu === kodu);
      if (oldStockCodeIndex) {
        state.stock.splice(oldStockCodeIndex, 1);
      }
      state.stock.push({ id, kodu, adi });
      return {
        ...state,
        loading: false,
        stock: state.stock,
      };
    case CREATE_CUSTOMER_SUCCESS:
      state.customer.push(data);
      return {
        ...state,
        loading: false,
        customer: state.customer,
      };
    case GET_PORT_SUCCESS:
      return {
        ...state,
        loading: false,
        port: data,
      };
    case GET_CUSTOMS_SUCCESS:
      return {
        ...state,
        loading: false,
        customs: data,
      };
    case GET_LINE_SUCCESS:
      return {
        ...state,
        loading: false,
        lines: data,
      };
    case GET_DUZELTME_TURU_SUCCESS:
      return {
        ...state,
        loading: false,
        duzeltmeTurleri: data,
      };
    case GET_DEMAND_SUCCESS:
      if (page > 1) {
        return {
          ...state,
          loading: false,
          demands: [...state.demands, ...data],
        };
      } else {
        return {
          ...state,
          loading: false,
          demands: data,
        };
      }
    case GET_CONFIRMATION_SUCCESS:
      if (paramsType === 1) {
        return {
          ...state,
          loading: false,
          confirmationName: data,
        };
      } else {
        return {
          ...state,
          loading: false,
          confirmationMail: data,
        };
      }
    case GET_CONTAINER_BY_BOOKING_NO_SUCCESS:
      return {
        ...state,
        containerByBookingNo: data,
        loading: false,
      };
    case GET_EVACUATION_SUCCESS:
      return {
        ...state,
        evacuationList: data,
        loading: false,
      };
    case GET_VESSEL_MOVE_SUCCESS:
      return {
        ...state,
        vesselMove: data,
        loading: false,
      };
    case GET_TARIFF_SUCCESS:
      return {
        ...state,
        tariff: data,
        loading: false,
      };
    case DELETE_VESSEL_MOVE_SUCCESS:
      const vesselMoveIndex = state.vesselMove.findIndex(
        ({ id }) => id === data.id
      );
      state.vesselMove.splice(vesselMoveIndex, 1);
      return {
        ...state,
        loading: false,
        vesselMove: state.vesselMove,
      };
    case CREATE_DEMAND_SUCCESS:
    case UPDATE_DEMAND_SUCCESS:
    case GET_ONE_DEMAND_SUCCESS:
    case POST_DOC_TO_PDF_SUCCESS:
    case PUT_CONFIRMATION_SUCCESS:
    case POST_DEMAND_RESULT_SUCCESS:
    case GET_DEMAND_RESULT_SUCCESS:
    case GET_LOADING_SUCCESS:
    case CREATE_VESSEL_MOVE_SUCCESS:
    case UPDATE_VESSEL_MOVE_SUCCESS:
    case CREATE_COST_SUCCESS:
    case CREATE_STAMP_DUTY_SUCCESS:
    case GET_PO_FILE_SUCCESS:
    case CREATE_PO_LIST_SUCCESS:
    case POST_CHECK_PO_SUCCESS:
    case GET_KTF_INFO_SUCCESS:
    case PATCH_APPROVAL_PO_SUCCESS:
    case PATCH_APPROVAL_CANCEL_PO_SUCCESS:
    case PATCH_EVRIM_PO_SUCCESS:
    case PATCH_PO_NO_SUCCESS:
    case PATCH_APPROVAL_PARTIAL_PO_SUCCESS:
    case PATCH_CANCEL_PARTIAL_PO_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_COST_BY_TYPEID_SUCCESS:
      return {
        ...state,
        loading: false,
        cost: data,
      };
    case GET_PO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        poList: _.chain(data)
          .groupBy('poId')
          .map(po => ({
            ...po[0],
            childs: po.map(({ order, ...o }) => o),
          }))
          .value(),
      };
    case GET_PO_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        poListDetail: _.chain(data)
          .groupBy('poId')
          .map(po => ({
            ...po[0],
            childs: po.map(({ order, ...o }) => o),
          }))
          .value(),
      };
    case GET_SHIP_ERROR:
    case CREATE_SHIP_ERROR:
    case UPDATE_SHIP_ERROR:
    case DELETE_SHIP_ERROR:
    case GET_CONTAINER_ERROR:
    case CREATE_CONTAINER_ERROR:
    case UPDATE_CONTAINER_ERROR:
    case DELETE_CONTAINER_ERROR:
    case GET_STOCK_ERROR:
    case CREATE_STOCK_ERROR:
    case GET_CUSTOMER_ERROR:
    case CREATE_CUSTOMER_ERROR:
    case GET_PORT_ERROR:
    case GET_CUSTOMS_ERROR:
    case GET_DUZELTME_TURU_ERROR:
    case CREATE_DEMAND_ERROR:
    case UPDATE_DEMAND_ERROR:
    case GET_DEMAND_ERROR:
    case GET_ONE_DEMAND_ERROR:
    case POST_DOC_TO_PDF_ERROR:
    case PATCH_SO_CONTAINER_ERROR:
    case GET_CONFIRMATION_ERROR:
    case PUT_CONFIRMATION_ERROR:
    case POST_DEMAND_RESULT_ERROR:
    case GET_CONTAINER_BY_BOOKING_NO_ERROR:
    case GET_LINE_ERROR:
    case GET_EVACUATION_ERROR:
    case GET_LOADING_ERROR:
    case GET_VESSEL_MOVE_ERROR:
    case CREATE_VESSEL_MOVE_ERROR:
    case DELETE_VESSEL_MOVE_ERROR:
    case UPDATE_VESSEL_MOVE_ERROR:
    case GET_TARIFF_ERROR:
    case CREATE_COST_ERROR:
    case GET_COST_BY_TYPEID_ERROR:
    case CREATE_STAMP_DUTY_ERROR:
    case GET_DEMAND_RESULT_ERROR:
    case GET_PO_FILE_ERROR:
    case CREATE_PO_LIST_ERROR:
    case POST_CHECK_PO_ERROR:
    case GET_KTF_INFO_ERROR:
    case GET_PO_LIST_ERROR:
    case GET_PO_LIST_DETAIL_ERROR:
    case PATCH_APPROVAL_PO_ERROR:
    case PATCH_APPROVAL_CANCEL_PO_ERROR:
    case PATCH_EVRIM_PO_ERROR:
    case PATCH_PO_NO_ERROR:
    case PATCH_APPROVAL_PARTIAL_PO_ERROR:
    case PATCH_CANCEL_PARTIAL_PO_ERROR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case RESET_ERROR_MESSAGE:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export { acenteReducer };
