const actions = {
  GET_SHIP_BEGIN: 'GET_SHIP_BEGIN',
  GET_SHIP_SUCCESS: 'GET_SHIP_SUCCESS',
  GET_SHIP_ERROR: 'GET_SHIP_ERROR',

  CREATE_SHIP_BEGIN: 'CREATE_SHIP_BEGIN',
  CREATE_SHIP_SUCCESS: 'CREATE_SHIP_SUCCESS',
  CREATE_SHIP_ERROR: 'CREATE_SHIP_ERROR',

  UPDATE_SHIP_BEGIN: 'UPDATE_SHIP_BEGIN',
  UPDATE_SHIP_SUCCESS: 'UPDATE_SHIP_SUCCESS',
  UPDATE_SHIP_ERROR: 'UPDATE_SHIP_ERROR',

  DELETE_SHIP_BEGIN: 'DELETE_SHIP_BEGIN',
  DELETE_SHIP_SUCCESS: 'DELETE_SHIP_SUCCESS',
  DELETE_SHIP_ERROR: 'DELETE_SHIP_ERROR',

  GET_CONTAINER_BEGIN: 'GET_CONTAINER_BEGIN',
  GET_CONTAINER_SUCCESS: 'GET_CONTAINER_SUCCESS',
  GET_CONTAINER_ERROR: 'GET_CONTAINER_ERROR',

  CREATE_CONTAINER_BEGIN: 'CREATE_CONTAINER_BEGIN',
  CREATE_CONTAINER_SUCCESS: 'CREATE_CONTAINER_SUCCESS',
  CREATE_CONTAINER_ERROR: 'CREATE_CONTAINER_ERROR',

  UPDATE_CONTAINER_BEGIN: 'UPDATE_CONTAINER_BEGIN',
  UPDATE_CONTAINER_SUCCESS: 'UPDATE_CONTAINER_SUCCESS',
  UPDATE_CONTAINER_ERROR: 'UPDATE_CONTAINER_ERROR',

  PATCH_SO_CONTAINER_BEGIN: 'PATCH_SO_CONTAINER_BEGIN',
  PATCH_SO_CONTAINER_SUCCESS: 'PATCH_SO_CONTAINER_SUCCESS',
  PATCH_SO_CONTAINER_ERROR: 'PATCH_SO_CONTAINER_ERROR',

  DELETE_CONTAINER_BEGIN: 'DELETE_CONTAINER_BEGIN',
  DELETE_CONTAINER_SUCCESS: 'DELETE_CONTAINER_SUCCESS',
  DELETE_CONTAINER_ERROR: 'DELETE_CONTAINER_ERROR',

  GET_STOCK_BEGIN: 'GET_STOCK_BEGIN',
  GET_STOCK_SUCCESS: 'GET_STOCK_SUCCESS',
  GET_STOCK_ERROR: 'GET_STOCK_ERROR',

  CREATE_STOCK_BEGIN: 'CREATE_STOCK_BEGIN',
  CREATE_STOCK_SUCCESS: 'CREATE_STOCK_SUCCESS',
  CREATE_STOCK_ERROR: 'CREATE_STOCK_ERROR',

  GET_CUSTOMER_BEGIN: 'GET_CUSTOMER_BEGIN',
  GET_CUSTOMER_SUCCESS: 'GET_CUSTOMER_SUCCESS',
  GET_CUSTOMER_ERROR: 'GET_CUSTOMER_ERROR',

  CREATE_CUSTOMER_BEGIN: 'CREATE_CUSTOMER_BEGIN',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_ERROR: 'CREATE_CUSTOMER_ERROR',

  GET_PORT_BEGIN: 'GET_PORT_BEGIN',
  GET_PORT_SUCCESS: 'GET_PORT_SUCCESS',
  GET_PORT_ERROR: 'GET_PORT_ERROR',

  GET_CUSTOMS_BEGIN: 'GET_CUSTOMS_BEGIN',
  GET_CUSTOMS_SUCCESS: 'GET_CUSTOMS_SUCCESS',
  GET_CUSTOMS_ERROR: 'GET_CUSTOMS_ERROR',

  CREATE_DEMAND_BEGIN: 'CREATE_DEMAND_BEGIN',
  CREATE_DEMAND_SUCCESS: 'CREATE_DEMAND_SUCCESS',
  CREATE_DEMAND_ERROR: 'CREATE_DEMAND_ERROR',

  UPDATE_DEMAND_BEGIN: 'UPDATE_DEMAND_BEGIN',
  UPDATE_DEMAND_SUCCESS: 'UPDATE_DEMAND_SUCCESS',
  UPDATE_DEMAND_ERROR: 'UPDATE_DEMAND_ERROR',

  GET_DEMAND_BEGIN: 'GET_DEMAND_BEGIN',
  GET_DEMAND_SUCCESS: 'GET_DEMAND_SUCCESS',
  GET_DEMAND_ERROR: 'GET_DEMAND_ERROR',

  GET_ONE_DEMAND_BEGIN: 'GET_ONE_DEMAND_BEGIN',
  GET_ONE_DEMAND_SUCCESS: 'GET_ONE_DEMAND_SUCCESS',
  GET_ONE_DEMAND_ERROR: 'GET_ONE_DEMAND_ERROR',

  GET_DUZELTME_TURU_BEGIN: 'GET_DUZELTME_TURU_BEGIN',
  GET_DUZELTME_TURU_SUCCESS: 'GET_DUZELTME_TURU_SUCCESS',
  GET_DUZELTME_TURU_ERROR: 'GET_DUZELTME_TURU_ERROR',

  POST_DOC_TO_PDF_BEGIN: 'POST_DOC_TO_PDF_BEGIN',
  POST_DOC_TO_PDF_SUCCESS: 'POST_DOC_TO_PDF_SUCCESS',
  POST_DOC_TO_PDF_ERROR: 'POST_DOC_TO_PDF_ERROR',

  GET_CONFIRMATION_BEGIN: 'GET_CONFIRMATION_BEGIN',
  GET_CONFIRMATION_SUCCESS: 'GET_CONFIRMATION_SUCCESS',
  GET_CONFIRMATION_ERROR: 'GET_CONFIRMATION_ERROR',

  PUT_CONFIRMATION_BEGIN: 'PUT_CONFIRMATION_BEGIN',
  PUT_CONFIRMATION_SUCCESS: 'PUT_CONFIRMATION_SUCCESS',
  PUT_CONFIRMATION_ERROR: 'PUT_CONFIRMATION_ERROR',

  POST_DEMAND_RESULT_BEGIN: 'POST_DEMAND_RESULT_BEGIN',
  POST_DEMAND_RESULT_SUCCESS: 'POST_DEMAND_RESULT_SUCCESS',
  POST_DEMAND_RESULT_ERROR: 'POST_DEMAND_RESULT_ERROR',

  GET_DEMAND_RESULT_BEGIN: 'GET_DEMAND_RESULT_BEGIN',
  GET_DEMAND_RESULT_SUCCESS: 'GET_DEMAND_RESULT_SUCCESS',
  GET_DEMAND_RESULT_ERROR: 'GET_DEMAND_RESULT_ERROR',

  GET_CONTAINER_BY_BOOKING_NO_BEGIN: 'GET_CONTAINER_BY_BOOKING_NO_BEGIN',
  GET_CONTAINER_BY_BOOKING_NO_SUCCESS: 'GET_CONTAINER_BY_BOOKING_NO_SUCCESS',
  GET_CONTAINER_BY_BOOKING_NO_ERROR: 'GET_CONTAINER_BY_BOOKING_NO_ERROR',

  GET_LINE_BEGIN: 'GET_LINE_BEGIN',
  GET_LINE_SUCCESS: 'GET_LINE_SUCCESS',
  GET_LINE_ERROR: 'GET_LINE_ERROR',

  GET_EVACUATION_BEGIN: 'GET_EVACUATION_BEGIN',
  GET_EVACUATION_SUCCESS: 'GET_EVACUATION_SUCCESS',
  GET_EVACUATION_ERROR: 'GET_EVACUATION_ERROR',

  GET_LOADING_BEGIN: 'GET_LOADING_BEGIN',
  GET_LOADING_SUCCESS: 'GET_LOADING_SUCCESS',
  GET_LOADING_ERROR: 'GET_LOADING_ERROR',

  GET_VESSEL_MOVE_BEGIN: 'GET_VESSEL_MOVE_BEGIN',
  GET_VESSEL_MOVE_SUCCESS: 'GET_VESSEL_MOVE_SUCCESS',
  GET_VESSEL_MOVE_ERROR: 'GET_VESSEL_MOVE_ERROR',

  CREATE_VESSEL_MOVE_BEGIN: 'CREATE_VESSEL_MOVE_BEGIN',
  CREATE_VESSEL_MOVE_SUCCESS: 'CREATE_VESSEL_MOVE_SUCCESS',
  CREATE_VESSEL_MOVE_ERROR: 'CREATE_VESSEL_MOVE_ERROR',

  DELETE_VESSEL_MOVE_BEGIN: 'DELETE_VESSEL_MOVE_BEGIN',
  DELETE_VESSEL_MOVE_SUCCESS: 'DELETE_VESSEL_MOVE_SUCCESS',
  DELETE_VESSEL_MOVE_ERROR: 'DELETE_VESSEL_MOVE_ERROR',

  UPDATE_VESSEL_MOVE_BEGIN: 'UPDATE_VESSEL_MOVE_BEGIN',
  UPDATE_VESSEL_MOVE_SUCCESS: 'UPDATE_VESSEL_MOVE_SUCCESS',
  UPDATE_VESSEL_MOVE_ERROR: 'UPDATE_VESSEL_MOVE_ERROR',

  GET_TARIFF_BEGIN: 'GET_TARIFF_BEGIN',
  GET_TARIFF_SUCCESS: 'GET_TARIFF_SUCCESS',
  GET_TARIFF_ERROR: 'GET_TARIFF_ERROR',

  CREATE_COST_BEGIN: 'CREATE_COST_BEGIN',
  CREATE_COST_SUCCESS: 'CREATE_COST_SUCCESS',
  CREATE_COST_ERROR: 'CREATE_COST_ERROR',

  GET_COST_BY_TYPEID_BEGIN: 'GET_COST_BY_TYPEID_BEGIN',
  GET_COST_BY_TYPEID_SUCCESS: 'GET_COST_BY_TYPEID_SUCCESS',
  GET_COST_BY_TYPEID_ERROR: 'GET_COST_BY_TYPEID_ERROR',

  CREATE_STAMP_DUTY_BEGIN: 'CREATE_STAMP_DUTY_BEGIN',
  CREATE_STAMP_DUTY_SUCCESS: 'CREATE_STAMP_DUTY_SUCCESS',
  CREATE_STAMP_DUTY_ERROR: 'CREATE_STAMP_DUTY_ERROR',

  GET_PO_FILE_BEGIN: 'GET_PO_FILE_BEGIN',
  GET_PO_FILE_SUCCESS: 'GET_PO_FILE_SUCCESS',
  GET_PO_FILE_ERROR: 'GET_PO_FILE_ERROR',

  CREATE_PO_LIST_BEGIN: 'CREATE_PO_LIST_BEGIN',
  CREATE_PO_LIST_SUCCESS: 'CREATE_PO_LIST_SUCCESS',
  CREATE_PO_LIST_ERROR: 'CREATE_PO_LIST_ERROR',

  POST_CHECK_PO_BEGIN: 'POST_CHECK_PO_BEGIN',
  POST_CHECK_PO_SUCCESS: 'POST_CHECK_PO_SUCCESS',
  POST_CHECK_PO_ERROR: 'POST_CHECK_PO_ERROR',

  GET_KTF_INFO_BEGIN: 'GET_KTF_INFO_BEGIN',
  GET_KTF_INFO_SUCCESS: 'GET_KTF_INFO_SUCCESS',
  GET_KTF_INFO_ERROR: 'GET_KTF_INFO_ERROR',

  GET_PO_LIST_BEGIN: 'GET_PO_LIST_BEGIN',
  GET_PO_LIST_SUCCESS: 'GET_PO_LIST_SUCCESS',
  GET_PO_LIST_ERROR: 'GET_PO_LIST_ERROR',

  GET_PO_LIST_DETAIL_BEGIN: 'GET_PO_LIST_DETAIL_BEGIN',
  GET_PO_LIST_DETAIL_SUCCESS: 'GET_PO_LIST_DETAIL_SUCCESS',
  GET_PO_LIST_DETAIL_ERROR: 'GET_PO_LIST_DETAIL_ERROR',

  PATCH_APPROVAL_PO_BEGIN: 'PATCH_APPROVAL_PO_BEGIN',
  PATCH_APPROVAL_PO_SUCCESS: 'PATCH_APPROVAL_PO_SUCCESS',
  PATCH_APPROVAL_PO_ERROR: 'PATCH_APPROVAL_PO_ERROR',

  PATCH_APPROVAL_CANCEL_PO_BEGIN: 'PATCH_APPROVAL_CANCEL_PO_BEGIN',
  PATCH_APPROVAL_CANCEL_PO_SUCCESS: 'PATCH_APPROVAL_CANCEL_PO_SUCCESS',
  PATCH_APPROVAL_CANCEL_PO_ERROR: 'PATCH_APPROVAL_CANCEL_PO_ERROR',

  PATCH_EVRIM_PO_BEGIN: 'PATCH_EVRIM_PO_BEGIN',
  PATCH_EVRIM_PO_SUCCESS: 'PATCH_EVRIM_PO_SUCCESS',
  PATCH_EVRIM_PO_ERROR: 'PATCH_EVRIM_PO_ERROR',

  PATCH_PO_NO_BEGIN: 'PATCH_PO_NO_BEGIN',
  PATCH_PO_NO_SUCCESS: 'PATCH_PO_NO_SUCCESS',
  PATCH_PO_NO_ERROR: 'PATCH_PO_NO_ERROR',

  PATCH_APPROVAL_PARTIAL_PO_BEGIN: 'PATCH_APPROVAL_PARTIAL_PO_BEGIN',
  PATCH_APPROVAL_PARTIAL_PO_SUCCESS: 'PATCH_APPROVAL_PARTIAL_PO_SUCCESS',
  PATCH_APPROVAL_PARTIAL_PO_ERROR: 'PATCH_APPROVAL_PARTIAL_PO_ERROR',

  PATCH_CANCEL_PARTIAL_PO_BEGIN: 'PATCH_CANCEL_PARTIAL_PO_BEGIN',
  PATCH_CANCEL_PARTIAL_PO_SUCCESS: 'PATCH_CANCEL_PARTIAL_PO_SUCCESS',
  PATCH_CANCEL_PARTIAL_PO_ERROR: 'PATCH_CANCEL_PARTIAL_PO_ERROR',

  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE', // Reduxdan Hata Temizlemektedir.

  getShipBegin: () => {
    return {
      type: actions.GET_SHIP_BEGIN,
    };
  },

  getShipSuccess: (data, page) => {
    return {
      type: actions.GET_SHIP_SUCCESS,
      data,
      page,
    };
  },

  getShipError: err => {
    return {
      type: actions.GET_SHIP_ERROR,
      err,
    };
  },

  createShipBegin: () => {
    return {
      type: actions.CREATE_SHIP_BEGIN,
    };
  },

  createShipSuccess: data => {
    return {
      type: actions.CREATE_SHIP_SUCCESS,
      data,
    };
  },

  createShipError: err => {
    return {
      type: actions.CREATE_SHIP_ERROR,
      err,
    };
  },

  updateShipBegin: () => {
    return {
      type: actions.UPDATE_SHIP_BEGIN,
    };
  },

  updateShipSuccess: data => {
    return {
      type: actions.UPDATE_SHIP_SUCCESS,
      data,
    };
  },

  updateShipError: err => {
    return {
      type: actions.UPDATE_SHIP_ERROR,
      err,
    };
  },

  deleteShipBegin: () => {
    return {
      type: actions.DELETE_SHIP_BEGIN,
    };
  },

  deleteShipSuccess: data => {
    return {
      type: actions.DELETE_SHIP_SUCCESS,
      data,
    };
  },

  deleteShipError: err => {
    return {
      type: actions.DELETE_SHIP_ERROR,
      err,
    };
  },

  getContainerBegin: () => {
    return {
      type: actions.GET_CONTAINER_BEGIN,
    };
  },

  getContainerSuccess: (data, page) => {
    return {
      type: actions.GET_CONTAINER_SUCCESS,
      data,
      page,
    };
  },

  getContainerError: err => {
    return {
      type: actions.GET_CONTAINER_ERROR,
      err,
    };
  },

  createContainerBegin: () => {
    return {
      type: actions.CREATE_CONTAINER_BEGIN,
    };
  },

  createContainerSuccess: data => {
    return {
      type: actions.CREATE_CONTAINER_SUCCESS,
      data,
    };
  },

  createContainerError: err => {
    return {
      type: actions.CREATE_SHIP_ERROR,
      err,
    };
  },

  updateContainerBegin: () => {
    return {
      type: actions.UPDATE_CONTAINER_BEGIN,
    };
  },

  updateContainerSuccess: data => {
    return {
      type: actions.UPDATE_CONTAINER_SUCCESS,
      data,
    };
  },

  updateContainerError: err => {
    return {
      type: actions.UPDATE_CONTAINER_ERROR,
      err,
    };
  },

  patchSoContainerBegin: () => {
    return {
      type: actions.PATCH_SO_CONTAINER_BEGIN,
    };
  },

  patchSoContainerSuccess: data => {
    return {
      type: actions.PATCH_SO_CONTAINER_SUCCESS,
      data,
    };
  },

  patchSoContainerError: err => {
    return {
      type: actions.PATCH_SO_CONTAINER_ERROR,
      err,
    };
  },

  deleteContainerBegin: () => {
    return {
      type: actions.DELETE_CONTAINER_BEGIN,
    };
  },

  deleteContainerSuccess: data => {
    return {
      type: actions.DELETE_CONTAINER_SUCCESS,
      data,
    };
  },

  deleteContainerError: err => {
    return {
      type: actions.DELETE_CONTAINER_ERROR,
      err,
    };
  },

  getStockBegin: () => {
    return {
      type: actions.GET_STOCK_BEGIN,
    };
  },

  getStockSuccess: data => {
    return {
      type: actions.GET_STOCK_SUCCESS,
      data,
    };
  },

  getStockError: err => {
    return {
      type: actions.GET_STOCK_ERROR,
      err,
    };
  },

  createStockBegin: () => {
    return {
      type: actions.CREATE_STOCK_BEGIN,
    };
  },

  createStockSuccess: data => {
    return {
      type: actions.CREATE_STOCK_SUCCESS,
      data,
    };
  },

  createStockError: err => {
    return {
      type: actions.CREATE_STOCK_ERROR,
      err,
    };
  },

  getCustomerBegin: () => {
    return {
      type: actions.GET_CUSTOMER_BEGIN,
    };
  },

  getCustomerSuccess: data => {
    return {
      type: actions.GET_CUSTOMER_SUCCESS,
      data,
    };
  },

  getCustomerError: err => {
    return {
      type: actions.GET_CUSTOMER_ERROR,
      err,
    };
  },

  createCustomerBegin: () => {
    return {
      type: actions.CREATE_CUSTOMER_BEGIN,
    };
  },

  createCustomerSuccess: data => {
    return {
      type: actions.CREATE_CUSTOMER_SUCCESS,
      data,
    };
  },

  createCustomerError: err => {
    return {
      type: actions.CREATE_CUSTOMER_ERROR,
      err,
    };
  },

  getPortBegin: () => {
    return {
      type: actions.GET_PORT_BEGIN,
    };
  },

  getPortSuccess: data => {
    return {
      type: actions.GET_PORT_SUCCESS,
      data,
    };
  },

  getPortError: err => {
    return {
      type: actions.GET_PORT_ERROR,
      err,
    };
  },

  getCustomsBegin: () => {
    return {
      type: actions.GET_CUSTOMS_BEGIN,
    };
  },

  getCustomsSuccess: data => {
    return {
      type: actions.GET_CUSTOMS_SUCCESS,
      data,
    };
  },

  getCustomsError: err => {
    return {
      type: actions.GET_CUSTOMS_ERROR,
      err,
    };
  },

  createDemandBegin: () => {
    return {
      type: actions.CREATE_DEMAND_BEGIN,
    };
  },

  createDemandSuccess: data => {
    return {
      type: actions.CREATE_DEMAND_SUCCESS,
      data,
    };
  },

  createDemandError: err => {
    return {
      type: actions.CREATE_DEMAND_ERROR,
      err,
    };
  },

  updateDemandBegin: () => {
    return {
      type: actions.UPDATE_DEMAND_BEGIN,
    };
  },

  updateDemandSuccess: data => {
    return {
      type: actions.UPDATE_DEMAND_SUCCESS,
      data,
    };
  },

  updateDemandError: err => {
    return {
      type: actions.UPDATE_DEMAND_ERROR,
      err,
    };
  },

  getDemandBegin: () => {
    return {
      type: actions.GET_DEMAND_BEGIN,
    };
  },

  getDemandSuccess: (data, page) => {
    return {
      type: actions.GET_DEMAND_SUCCESS,
      data,
      page,
    };
  },

  getDemandError: err => {
    return {
      type: actions.GET_DEMAND_ERROR,
      err,
    };
  },

  getOneDemandBegin: () => {
    return {
      type: actions.GET_ONE_DEMAND_BEGIN,
    };
  },

  getOneDemandSuccess: data => {
    return {
      type: actions.GET_ONE_DEMAND_SUCCESS,
      data,
    };
  },

  getOneDemandError: err => {
    return {
      type: actions.GET_ONE_DEMAND_ERROR,
      err,
    };
  },

  getDuzeltmeTuruBegin: () => {
    return {
      type: actions.GET_DUZELTME_TURU_BEGIN,
    };
  },

  getDuzeltmeTuruSuccess: data => {
    return {
      type: actions.GET_DUZELTME_TURU_SUCCESS,
      data,
    };
  },

  getDuzeltmeTuruError: err => {
    return {
      type: actions.GET_DUZELTME_TURU_ERROR,
      err,
    };
  },

  postDocToPdfBegin: () => {
    return {
      type: actions.POST_DOC_TO_PDF_BEGIN,
    };
  },

  postDocToPdfSuccess: data => {
    return {
      type: actions.POST_DOC_TO_PDF_SUCCESS,
      data,
    };
  },

  postDocToPdfError: err => {
    return {
      type: actions.POST_DOC_TO_PDF_ERROR,
      err,
    };
  },

  getConfirmationBegin: () => {
    return {
      type: actions.GET_CONFIRMATION_BEGIN,
    };
  },

  getConfirmationSuccess: (data, type) => {
    return {
      type: actions.GET_CONFIRMATION_SUCCESS,
      data,
      paramsType: type,
    };
  },

  getConfirmationError: err => {
    return {
      type: actions.GET_CONFIRMATION_ERROR,
      err,
    };
  },

  putConfirmationBegin: () => {
    return {
      type: actions.PUT_CONFIRMATION_BEGIN,
    };
  },

  putConfirmationSuccess: data => {
    return {
      type: actions.PUT_CONFIRMATION_SUCCESS,
      data,
    };
  },

  putConfirmationError: err => {
    return {
      type: actions.PUT_CONFIRMATION_ERROR,
      err,
    };
  },

  postDemandResultBegin: () => {
    return {
      type: actions.POST_DEMAND_RESULT_BEGIN,
    };
  },

  postDemandResultSuccess: data => {
    return {
      type: actions.POST_DEMAND_RESULT_SUCCESS,
      data,
    };
  },

  postDemandResultError: err => {
    return {
      type: actions.POST_DEMAND_RESULT_ERROR,
      err,
    };
  },

  getDemandResultBegin: () => {
    return {
      type: actions.GET_DEMAND_RESULT_BEGIN,
    };
  },

  getDemandResultSuccess: data => {
    return {
      type: actions.GET_DEMAND_RESULT_SUCCESS,
      data,
    };
  },

  getDemandResultError: err => {
    return {
      type: actions.GET_DEMAND_RESULT_ERROR,
      err,
    };
  },

  getContainerByBookingNoBegin: () => {
    return {
      type: actions.GET_CONTAINER_BY_BOOKING_NO_BEGIN,
    };
  },

  getContainerByBookingNoSuccess: data => {
    return {
      type: actions.GET_CONTAINER_BY_BOOKING_NO_SUCCESS,
      data,
    };
  },

  getContainerByBookingNoError: err => {
    return {
      type: actions.GET_CONTAINER_BY_BOOKING_NO_ERROR,
      err,
    };
  },

  getLineBegin: () => {
    return {
      type: actions.GET_LINE_BEGIN,
    };
  },

  getLineSuccess: data => {
    return {
      type: actions.GET_LINE_SUCCESS,
      data,
    };
  },

  getLineError: err => {
    return {
      type: actions.GET_LINE_ERROR,
      err,
    };
  },

  getEvacuationBegin: () => {
    return {
      type: actions.GET_EVACUATION_BEGIN,
    };
  },

  getEvacuationSuccess: data => {
    return {
      type: actions.GET_EVACUATION_SUCCESS,
      data,
    };
  },

  getEvacuationError: err => {
    return {
      type: actions.GET_EVACUATION_ERROR,
      err,
    };
  },

  getLoadingBegin: () => {
    return {
      type: actions.GET_LOADING_BEGIN,
    };
  },

  getLoadingSuccess: data => {
    return {
      type: actions.GET_LOADING_SUCCESS,
      data,
    };
  },

  getLoadingError: err => {
    return {
      type: actions.GET_LOADING_ERROR,
      err,
    };
  },

  getVesselMoveBegin: () => {
    return {
      type: actions.GET_VESSEL_MOVE_BEGIN,
    };
  },

  getVesselMoveSuccess: data => {
    return {
      type: actions.GET_VESSEL_MOVE_SUCCESS,
      data,
    };
  },

  getVesselMoveError: err => {
    return {
      type: actions.GET_VESSEL_MOVE_ERROR,
      err,
    };
  },

  createVesselMoveBegin: () => {
    return {
      type: actions.CREATE_VESSEL_MOVE_BEGIN,
    };
  },

  createVesselMoveSuccess: data => {
    return {
      type: actions.CREATE_VESSEL_MOVE_SUCCESS,
      data,
    };
  },

  createVesselMoveError: err => {
    return {
      type: actions.CREATE_VESSEL_MOVE_ERROR,
      err,
    };
  },

  deleteVesselMoveBegin: () => {
    return {
      type: actions.DELETE_VESSEL_MOVE_BEGIN,
    };
  },

  deleteVesselMoveSuccess: data => {
    return {
      type: actions.DELETE_VESSEL_MOVE_SUCCESS,
      data,
    };
  },

  deleteVesselMoveError: err => {
    return {
      type: actions.DELETE_VESSEL_MOVE_ERROR,
      err,
    };
  },

  updateVesselMoveBegin: () => {
    return {
      type: actions.UPDATE_VESSEL_MOVE_BEGIN,
    };
  },

  updateVesselMoveSuccess: data => {
    return {
      type: actions.UPDATE_VESSEL_MOVE_SUCCESS,
      data,
    };
  },

  updateVesselMoveError: err => {
    return {
      type: actions.UPDATE_VESSEL_MOVE_ERROR,
      err,
    };
  },

  getTariffBegin: () => {
    return {
      type: actions.GET_TARIFF_BEGIN,
    };
  },

  getTariffSuccess: data => {
    return {
      type: actions.GET_TARIFF_SUCCESS,
      data,
    };
  },

  getTariffError: err => {
    return {
      type: actions.GET_TARIFF_ERROR,
      err,
    };
  },

  createCostBegin: () => {
    return {
      type: actions.CREATE_COST_BEGIN,
    };
  },

  createCostSuccess: data => {
    return {
      type: actions.CREATE_COST_SUCCESS,
      data,
    };
  },

  createCostError: err => {
    return {
      type: actions.CREATE_COST_ERROR,
      err,
    };
  },

  getCostByTypeIdBegin: () => {
    return {
      type: actions.GET_COST_BY_TYPEID_BEGIN,
    };
  },

  getCostByTypeIdSucces: data => {
    return {
      type: actions.GET_COST_BY_TYPEID_SUCCESS,
      data,
    };
  },

  getCostByTypeIdError: err => {
    return {
      type: actions.GET_COST_BY_TYPEID_ERROR,
      err,
    };
  },

  createStampDutyBegin: () => {
    return {
      type: actions.CREATE_STAMP_DUTY_BEGIN,
    };
  },

  createStampDutySuccess: data => {
    return {
      type: actions.CREATE_STAMP_DUTY_SUCCESS,
      data,
    };
  },

  createStampDutyError: err => {
    return {
      type: actions.CREATE_STAMP_DUTY_ERROR,
      err,
    };
  },

  getPoFileBegin: () => {
    return {
      type: actions.GET_PO_FILE_BEGIN,
    };
  },

  getPoFileSuccess: data => {
    return {
      type: actions.GET_PO_FILE_SUCCESS,
      data,
    };
  },

  getPoFileError: err => {
    return {
      type: actions.GET_PO_FILE_ERROR,
      err,
    };
  },

  createPoListBegin: () => {
    return {
      type: actions.CREATE_PO_LIST_BEGIN,
    };
  },

  createPoListSuccess: data => {
    return {
      type: actions.CREATE_PO_LIST_SUCCESS,
      data,
    };
  },

  createPoListError: err => {
    return {
      type: actions.CREATE_PO_LIST_ERROR,
      err,
    };
  },

  postPoCheckBegin: () => {
    return {
      type: actions.POST_CHECK_PO_BEGIN,
    };
  },

  postPoCheckSuccess: data => {
    return {
      type: actions.POST_CHECK_PO_SUCCESS,
      data,
    };
  },

  postPoCheckError: err => {
    return {
      type: actions.POST_CHECK_PO_ERROR,
      err,
    };
  },

  getKtfInfoBegin: () => {
    return {
      type: actions.GET_KTF_INFO_BEGIN,
    };
  },

  getKtfInfoSuccess: () => {
    return {
      type: actions.GET_KTF_INFO_SUCCESS,
    };
  },

  getKtfInfoError: err => {
    return {
      type: actions.GET_KTF_INFO_ERROR,
      err,
    };
  },

  getPoListBegin: () => {
    return {
      type: actions.GET_PO_LIST_BEGIN,
    };
  },

  getPoListSuccess: data => {
    return {
      type: actions.GET_PO_LIST_SUCCESS,
      data,
    };
  },

  getPoListError: err => {
    return {
      type: actions.GET_PO_LIST_ERROR,
      err,
    };
  },

  getPoListDetailBegin: () => {
    return {
      type: actions.GET_PO_LIST_DETAIL_BEGIN,
    };
  },

  getPoListDetailSuccess: data => {
    return {
      type: actions.GET_PO_LIST_DETAIL_SUCCESS,
      data,
    };
  },

  getPoListDetailError: err => {
    return {
      type: actions.GET_PO_LIST_DETAIL_ERROR,
      err,
    };
  },

  patchApprovalPoBegin: () => {
    return {
      type: actions.PATCH_APPROVAL_PO_BEGIN,
    };
  },

  patchApprovalPoSuccess: data => {
    return {
      type: actions.PATCH_APPROVAL_PO_SUCCESS,
      data,
    };
  },

  patchApprovalPoError: err => {
    return {
      type: actions.PATCH_APPROVAL_PO_ERROR,
      err,
    };
  },

  patchApprovalCancelPoBegin: () => {
    return {
      type: actions.PATCH_APPROVAL_CANCEL_PO_BEGIN,
    };
  },

  patchApprovalCancelPoSuccess: data => {
    return {
      type: actions.PATCH_APPROVAL_CANCEL_PO_SUCCESS,
      data,
    };
  },

  patchApprovalCancelPoError: err => {
    return {
      type: actions.PATCH_APPROVAL_CANCEL_PO_ERROR,
      err,
    };
  },

  patchEvrimPoBegin: () => {
    return {
      type: actions.PATCH_EVRIM_PO_BEGIN,
    };
  },

  patchEvrimPoSuccess: data => {
    return {
      type: actions.PATCH_EVRIM_PO_SUCCESS,
      data,
    };
  },

  patchEvrimPoError: err => {
    return {
      type: actions.PATCH_EVRIM_PO_ERROR,
      err,
    };
  },

  patchPoNoBegin: () => {
    return {
      type: actions.PATCH_PO_NO_BEGIN,
    };
  },

  patchPoNoSuccess: data => {
    return {
      type: actions.PATCH_PO_NO_SUCCESS,
      data,
    };
  },

  patchPoNoError: err => {
    return {
      type: actions.PATCH_PO_NO_ERROR,
      err,
    };
  },

  patchApprovalPartialPoBegin: () => {
    return {
      type: actions.PATCH_APPROVAL_PARTIAL_PO_BEGIN,
    };
  },

  patchApprovalPartialPoSuccess: data => {
    return {
      type: actions.PATCH_APPROVAL_PARTIAL_PO_SUCCESS,
      data,
    };
  },

  patchApprovalPartialPoError: err => {
    return {
      type: actions.PATCH_APPROVAL_PARTIAL_PO_ERROR,
      err,
    };
  },

  patchCancelPartialPoBegin: () => {
    return {
      type: actions.PATCH_CANCEL_PARTIAL_PO_BEGIN,
    };
  },

  patchCancelPartialPoSuccess: data => {
    return {
      type: actions.PATCH_CANCEL_PARTIAL_PO_SUCCESS,
      data,
    };
  },

  patchCancelPartialPoError: err => {
    return {
      type: actions.PATCH_CANCEL_PARTIAL_PO_ERROR,
      err,
    };
  },

  resetErrorMessage: () => {
    return {
      type: actions.RESET_ERROR_MESSAGE,
    };
  },
};

export default actions;
