export default {
  exportBookingTitle: 'Gemi Data Yükleme',
  totalBooking: 'Toplam Booking: ',
  totalShip: 'Toplam Gemi: ',
  errorSelectFile: 'Lütfen Zip Dosyası Seçiniz',
  ship: 'Gemi',
  expeditionNo: 'Sefer No',
  acenteUploadError: '{{ fileName }} dosyası yüklenemedi',
  acenteUploadWarning: '{{ fileName }} bir zip dosyası değil',
  acenteUploadSuccess: '{{ fileName }} dosyası başarıyla yüklendi',
  addKonteyner: 'Konteyner Ekle',
  konteynerNo: 'Konteyner No',
  konteynerTipi: 'Boyut',
  konteynerDara: 'Dara',
  konteynerKiymet: 'Kıymeti',
  konteynerSoMu: 'So Mu?',
  kTarih: 'Kayıt Tarihi',
  dTarih: 'Düzenleme Tarihi',
  totalKontainer: 'Toplam Konteyner: ',
  konteynerSilmeMesaji:
    '{{konteynerNo}} nolu konteyner kaydınız silmek istediğiniz emin misiniz?',
  konteynerBilgileri: 'Konteyner Bilgileri',
  exportUploadConfirm:
    '{{ fileName }} isimli booking dosyası yüklüdür.Düzeltme işlemi yapmak istiyor musunuz?',
  selectShipPlaceholder: 'Gemi Seçiniz',
  expeditionNoPlaceholder: 'Sefer No Giriniz',
  exportEntryTitle: 'İhracat Giriş',
  exportErrorMessage: 'Lütfen Gemi ve Sefer No alanlarını doldurunuz',
  exportCopyMessage: 'Lütfen Kopyalanacak satırı seçiniz.',
  exportValidationMessage: 'Lütfen Mal Kodu kolonunu doldurunuz.',
  exportValidationMessage2: 'Lütfen Geçerli Konteyner No Giriniz.',
  addRow: 'Satır Ekle',
  declarationCount: 'Beyanname Sayısı: {{ count }}',
  total: 'Toplam: {{ count }}',
  entered: 'Girilen: {{ count }}',
  missing: 'Eksik: {{ count }}',
  new: 'Yeni: {{ count }}',
  edit: 'Düzenlenen: {{ count }}',
  deleted: 'Silindi: {{ count }}',
  copyRow: 'Satır Kopyala',
  footerInfo:
    'Müşteri Adı: {{ musteriAdi }}, Kap Adet: {{ kapAdet }}, Ağırlık: {{ kapAgirlik }}, B/L Ağırlık: {{ blAgirlik }}, B/L Adet: {{ blMiktar }}',
  shipTitle: 'Gemi Listesi',
  addShip: 'Gemi Ekle',
  deleteShipMessage: '{{shipNo}} nolu Gemi Kaydını silmek istiyor musunuz?',
  deleteContainerMessage:
    '{{ containerId }} nolu Konteyner Kaydını silmek istiyor musunuz?',
  shipCode: 'Gemi Kodu',
  shipName: 'Gemi Adı',
  shipValidationMessage: 'Lütfen Gemi Kodu Giriniz.',
  shipValidationMessage2: 'Gemi Kodu 3 karakter olmalı.',
  shipValidationMessage3: 'Lütfen Gemi Adı Giriniz',
  containerTitle: 'Konteyner Listesi',
  shipInfo: 'Gemi Bilgileri',
  containerValidationMessage: 'Lütfen Geçerli Bir Konteyner No Giriniz.',
  containerTypeValidationMessage: '20, 40, 45 haricinde boyut giremezsiniz.',
  addStock: 'Stok Ekle',
  addStockMessage: 'Ekle {{ stock }}',
  orderNo: 'Sıra No',
  exitVessel: 'Gemiden Çıkış',
  deleteRow: 'Satır Sil',
  customerName: 'Müşteri Adı:',
  blNumber: 'B/L Adet:',
  blWeight: 'B/L Ağırlık:',
  country: 'Ülke:',
  capNumber: 'Kap Adet:',
  capWeight: 'Kap Ağırlık:',
  targetShip: 'Hedef Gemi',
  sourceShip: 'Kaynak Gemi',
  fileDownloadError: 'Dosya indirilirken hata oluştu.',
  transferErrorMessage: 'Lütfen Hedef Gemi ve Sefer No Giriniz.',
  transferError: 'Transfer işlemi gerçekleştirilirken hata oluştu.',
  transferFromPool: 'Havuzdan Aktar',
  bookingByPool: 'Havuzdaki Bookingler (Konteyner Noya Göre Eşleşenler)',
  deletedBooking: 'Düzeltme ile Silinen Bookingler',
  revertMessage: 'Yeniden listeye eklemek istediğinize emin misiniz',
  importEntry: 'Ithalat Giriş',
  importList: 'Ithalat Liste',
  importExit: 'Ithalat Çıkış',
  importArrivalDate: 'Geliş Tarihi ve Saati',
  startedPort: 'Tahliye Limanı',
  arrivalCustoms: 'Giriş Gümrük',
  sumDeclarationNo: 'Özet Beyan No',
  sumDeclarationNoPlaceHolder: 'Özet Beyan No Giriniz',
  enterBookingNo: 'Booking No Giriniz',
  enterContainerNo: 'Konteyner No Giriniz',
  ktfFile: 'KTF Dosya Indir',
  importExitData: 'Çıkış Tarih ve Saati',
  importLoadingPort: 'Yükleme Limanı',
  importExitCustoms: 'Çıkış Gümrük',
  importExitRegNo: 'Çıkış Tescil Numarası',
  importExitRegNoPlaceholder: 'Çıkış Tescil Numarası Giriniz',
  shipSelectValidationMessage: 'Lütfen Gemi Seçiniz',
  expeditionNoValidationMessage: 'Lütfen Sefer No Giriniz',
  registryTitle: 'Tescil Giriş',
  registryNo: 'Tescil No',
  registryNoPlaceHolder: 'Tescil No Giriniz',
  registryNoValidationMessage: 'Lütfen Tescil No Giriniz.Örn 12345678GG123456',
  exitRegistryNoValidationMessage:
    'Lütfen Tescil No Giriniz.Örn 12345678GC123456',
  registryDate: 'Tescil Tarihi',
  registryDateValidationMessage: 'Lütfen Tescil Tarihi Seçiniz',
  rowValidationMessage: 'Lütfen Kayıt Seçiniz',
  importEntryTitle: 'Ithalat Giriş',
  importExitTitle: 'Ithalat Çıkış',
  registrySuccessMessage: 'Tescil İşlemi Başarılı',
  registryErrorMessage: 'Tescil İşlemi Gerçekleştirilirken Hata Oluştu',
  correctionCorrectPlaceholder: 'Doğru {{ key }} Giriniz',
  correctionWrongPlaceholder: 'Yanlış {{ key }} Giriniz',
};
