import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import FontAwesome from 'react-fontawesome';
import loadable from '@loadable/component';

const DynamicIconView = ({ icon }) => {
  const [iconKey, setIconKey] = useState('ant');
  const [iconName, setIconName] = useState(null);

  useEffect(() => {
    if (icon) {
      const iconArray = icon.split('||');
      if (iconArray.length !== 1) {
        setIconKey(iconArray[0]);
        setIconName(iconArray[1]);
      }
    }
  }, [icon]);

  return iconName && renderIconSwitch(iconKey, iconName);
};

DynamicIconView.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]),
};

// 3 icon kütüphanesini ayrı ayrı çağırmamak için oluşturuldu.Menuye icon eklerken bir tek feather icon kullanılmalı.
const renderIconSwitch = (key, iconName) => {
  switch (key) {
    case 'ant':
      return <DynamicIcon type={iconName} />;
    case 'fth':
      return <FeatherIcon icon={iconName} />;
    case 'fa':
      return <FontAwesome name={iconName} />;
    default:
      return null;
  }
};

const DynamicIcon = loadable(props =>
  import(`@ant-design/icons/es/icons/${props.type}.js`).catch(err =>
    import(`@ant-design/icons/es/icons/WarningOutlined.js`)
  )
);

export { DynamicIconView };
