import React, { useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import Home from './home';
import PrivateReportRoutes from './PrivateReport/privateReport';
import AcenteRoutes from './Acente/acente';
import AcenteTalepRoutes from './AcenteTalep/acenteTalep';
import AntrepoStok from './AntrepoStok/antrepoStok';
import GatesRoutes from './Gates/gates';
import CommonRoutes from './Common/common';
import withAdminLayout from '../../layout/withAdminLayout';
import { AlertView } from '../../components/alertView/alertView';
import { getMenu, getRole, getUser } from '../../redux/users/actionCreator';
import { logOut } from '../../redux/authentication/actionCreator';
import actions from '../../redux/users/actions';
import Cookies from 'js-cookie';

const { clearError } = actions;

// Parent Menu Bazlı Eklenmektedir.
const parentRoutes = {
  tanimlar: CommonRoutes,
  ihracatIslemleri: AcenteRoutes,
  ithalatIslemleri: AcenteRoutes,
  acenteTalepler: AcenteTalepRoutes,
  ozelRapor: PrivateReportRoutes,
  antrepoStokYonetim: AntrepoStok,
  gitIslemleri: AntrepoStok,
  operasyonIslemleri: AntrepoStok,
  urunYonetimi: AntrepoStok,
  antrepoStokRaporlar: AntrepoStok,
  sureliIslemler: AntrepoStok,
  gumruk: AntrepoStok,
  siparis: AntrepoStok,
  gates: GatesRoutes,
  acentePo: AcenteRoutes,
  beyanname: AntrepoStok,
};

const Admin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const error = useSelector(state => state.users.error);
  const menuData = useSelector(state => state.users.menu);

  useEffect(() => {
    if (!Cookies.get('version')) {
      window.location.reload();
      Cookies.set('version', process.env.REACT_APP_VERSION);
    } else {
      const version = Cookies.get('version');
      if (process.env.REACT_APP_VERSION > version) {
        window.location.reload();
        Cookies.set('version', process.env.REACT_APP_VERSION);
      }
    }
  }, [location]);

  useEffect(() => {
    dispatch(getMenu());
    dispatch(getRole());
    dispatch(getUser());
  }, [dispatch]);

  // Hata modalı acıldıktan sonra reduxdan hata silinmektedir.
  useEffect(() => {
    if (error) {
      dispatch(clearError());
    }
  }, [dispatch, error]);

  const redirectLogin = () => {
    if (error.status === 401) {
      dispatch(logOut());
    }
  };

  const wrapComp = (menuKey, parentMenu) => {
    if (parentMenu.length !== 0) {
      const TargetComp = parentRoutes[menuKey];
      return TargetComp && <TargetComp menu={parentMenu} />;
    } else return null;
  };

  return (
    <Switch>
      {error && (
        <AlertView
          title={t('error')}
          content={error.statusText}
          onHandleOk={redirectLogin}
          onHandleCancel={redirectLogin}
        />
      )}
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        {/* Parent Menu Bazlı Oluşturmaktadır */}
        <Route path={'/'} component={Home} />
        {menuData.map(({ id, key, parentMenu }) => {
          return <Route key={id} render={() => wrapComp(key, parentMenu)} />;
        })}
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
