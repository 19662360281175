import React from 'react';
import { useSelector } from 'react-redux';
import { Menu, Skeleton } from 'antd';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DynamicIconView } from '../components/dynamicIconView/dynamicIconView';

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();

  const menu = useSelector(state => state.users.menu);
  const loading = useSelector(state => state.users.loading);

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`]
      : []
  );

  const onOpenChange = keys => {
    setOpenKeys(
      keys[keys.length - 1] && keys[keys.length - 1].indexOf('subMenu') > 0
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? 'home'
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`]
          : []
      }
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item
        key="dashboard"
        icon={!topMenu && <DynamicIconView icon={'fth||home'} />}
      >
        <NavLink className="pl-20" onClick={toggleCollapsed} to={path}>
          {t('dashboard')}
        </NavLink>
      </Menu.Item>
      {!loading ? (
        Object.keys(menu).length !== 0 ? (
          menu.map(m => {
            return m.parentMenu.length !== 0 ? (
              <SubMenu
                key={m.key}
                icon={<DynamicIconView icon={m.icon} />}
                title={m.adi}
              >
                {m.parentMenu.map(pm => {
                  return pm.parentMenu.length !== 0 ? (
                    <SubMenu
                      key={pm.key}
                      icon={pm.icon && <DynamicIconView icon={pm.icon} />}
                      title={pm.adi}
                    >
                      <Menu.Item
                        key={pm.key}
                        icon={pm.icon && <DynamicIconView icon={pm.icon} />}
                      >
                        <NavLink onClick={toggleCollapsed} to={pm.link}>
                          {pm.adi}
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key={pm.id + pm.key}
                      icon={pm.icon && <DynamicIconView icon={pm.icon} />}
                      onClick={() => {
                        if (pm.link.indexOf(':') > -1 && pm.linkId) {
                          /* console.log(
                            'mahmyt 22',
                            `${pm.link.split(':')[0]}${pm.link.split(':')[1]}`
                          );
                          console.log('pm.linkId', pm.linkId); */
                          /* history.push(
                            `${pm.link.split(':')[0]}${pm.link.split(':')[1]}`
                          ); */
                          /* let id = 'e8123cad-b58c-4386-87ed-e71919f1a134'; */

                          history.push(`${pm.link.split(':')[0]}${pm.linkId}`);
                          //
                        }
                      }}
                    >
                      <NavLink onClick={toggleCollapsed} to={pm.link}>
                        {pm.adi}
                      </NavLink>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            ) : (
              <Menu.Item
                key={m.key}
                icon={m.icon && <DynamicIconView icon={m.icon} />}
              >
                <NavLink
                  onClick={toggleCollapsed}
                  to={m.link !== null ? m.link : ''}
                >
                  {m.adi}
                </NavLink>
              </Menu.Item>
            );
          })
        ) : null
      ) : (
        <Skeleton active />
      )}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
