import { lazy } from 'react';

const DemandScreen = lazy(() =>
  import('../../../screen/AcenteTalep/Demand/Demand')
);

const DemandListScreen = lazy(() =>
  import('../../../screen/AcenteTalep/DemandList/DemandList')
);

const DemandDetailScreen = lazy(() =>
  import('../../../screen/AcenteTalep/DemandDetail/DemandDetail')
);

export { DemandScreen, DemandListScreen, DemandDetailScreen };
