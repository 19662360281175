import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { GatesConsensusReport } from './index';

const privateReportRoutes = {
  gatesIthalat: GatesConsensusReport,
};

const PrivateReportRoutes = ({ menu }) => {
  return (
    <Switch>
      {/** Burada children menüye göre düzenleme yapılacak  */}
      {/** Menüde görünen routeları render etmektedir.  */}
      {menu.map(({ id, link, key }) => {
        return (
          <Route
            key={id}
            path={link}
            component={privateReportRoutes[key]}
            exact
          />
        );
      })}
      {/**  Child Routeları render etmektedir.Menude listelenmemektedir. */}
      {menu.map(({ childRoute }) => {
        return (
          childRoute.length !== 0 &&
          childRoute.map(({ id, link, key }) => {
            return (
              <Route
                key={id}
                path={link}
                component={privateReportRoutes[key]}
                exact
              />
            );
          })
        );
      })}
    </Switch>
  );
};

export default PrivateReportRoutes;
