const actions = {
  GET_DASHBOARDS_BEGIN: 'GET_DASHBOARDS_BEGIN',
  GET_DASHBOARDS_SUCCESS: 'GET_DASHBOARDS_SUCCESS',
  GET_DASHBOARDS_ERROR: 'GET_DASHBOARDS_ERROR',

  GET_INVOICESDATA_BEGIN: 'GET_INVOICESDATA_BEGIN',
  GET_INVOICESDATA_SUCCESS: 'GET_INVOICESDATA_SUCCESS',
  GET_INVOICESDATA_ERROR: 'GET_INVOICESDATA_ERROR',

  GET_SHIPMENTDATA_BEGIN: 'GET_SHIPMENTDATA_BEGIN',
  GET_SHIPMENTDATA_SUCCESS: 'GET_SHIPMENTDATA_SUCCESS',
  GET_SHIPMENTDATA_ERROR: 'GET_SHIPMENTDATA_ERROR',

  GET_PRODUCTDECLARATION_BEGIN: 'GET_PRODUCTDECLARATION_BEGIN',
  GET_PRODUCTDECLARATION_SUCCESS: 'GET_PRODUCTDECLARATION_SUCCESS',
  GET_PRODUCTDECLARATION_ERROR: 'GET_PRODUCTDECLARATION_ERROR',

  GET_ETA_BEGIN: 'GET_ETA_BEGIN',
  GET_ETA_SUCCESS: 'GET_ETA_SUCCESS',
  GET_ETA_ERROR: 'GET_ETA_ERROR',

  GET_TALEP_BEGIN: 'GET_TALEP_BEGIN',
  GET_TALEP_SUCCESS: 'GET_TALEP_SUCCESS',
  GET_TALEP_ERROR: 'GET_TALEP_ERROR',

  GET_PRODUCT_BEGIN: 'GET_PRODUCT_BEGIN',
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
  GET_PRODUCT_ERROR: 'GET_PRODUCT_ERROR',

  GET_NEW_ORDERS_WIDGET_BEGIN: 'GET_NEW_ORDERS_WIDGET_BEGIN',
  GET_NEW_ORDERS_WIDGET_SUCCESS: 'GET_NEW_ORDERS_WIDGET_SUCCESS',
  GET_NEW_ORDERS_WIDGET_ERROR: 'GET_NEW_ORDERS_WIDGET_ERROR',

  GET_DELAYED_ORDERS_WIDGET_BEGIN: 'GET_DELAYED_ORDERS_WIDGET_BEGIN',
  GET_DELAYED_ORDERS_WIDGET_SUCCESS: 'GET_DELAYED_ORDERS_WIDGET_SUCCESS',
  GET_DELAYED_ORDERS_WIDGET_ERROR: 'GET_DELAYED_ORDERS_WIDGET_ERROR',

  GET_UNAPPROVED_PRODUCTS_WIDGET_BEGIN: 'GET_UNAPPROVED_PRODUCTS_WIDGET_BEGIN',
  GET_UNAPPROVED_PRODUCTS_WIDGET_SUCCESS:
    'GET_UNAPPROVED_PRODUCTS_WIDGET_SUCCESS',
  GET_UNAPPROVED_PRODUCTS_WIDGET_ERROR: 'GET_UNAPPROVED_PRODUCTS_WIDGET_ERROR',

  GET_EXPIRED_DOCUMENTS_WIDGET_BEGIN: 'GET_EXPIRED_DOCUMENTS_WIDGET_BEGIN',
  GET_EXPIRED_DOCUMENTS_WIDGET_SUCCESS: 'GET_EXPIRED_DOCUMENTS_WIDGET_SUCCESS',
  GET_EXPIRED_DOCUMENTS_WIDGET_ERROR: 'GET_EXPIRED_DOCUMENTS_WIDGET_ERROR',

  GET_ORDERS_RATE_CHART_BEGIN: 'GET_ORDERS_RATE_CHART_BEGIN',
  GET_ORDERS_RATE_CHART_ERROR: 'GET_ORDERS_RATE_CHART_ERROR',
  GET_ORDERS_RATE_CHART_SUCCESS: 'GET_ORDERS_RATE_CHART_SUCCESS',

  GET_RATE_OF_VENDORS_CHART_BEGIN: 'GET_RATE_OF_VENDORS_CHART_BEGIN',
  GET_RATE_OF_VENDORS_CHART_SUCCESS: 'GET_RATE_OF_VENDORS_CHART_SUCCESS',
  GET_RATE_OF_VENDORS_CHART_ERROR: 'GET_RATE_OF_VENDORS_CHART_ERROR',

  GET_MOST_ORDERED_PRODUCTS_BEGIN: 'GET_MOST_ORDERED_PRODUCTS_BEGIN',
  GET_MOST_ORDERED_PRODUCTS_SUCCESS: 'GET_MOST_ORDERED_PRODUCTS_SUCCESS',
  GET_MOST_ORDERED_PRODUCTS_ERROR: 'GET_MOST_ORDERED_PRODUCTS_ERROR',

  GET_CUSTOM_BROKERS_CHART_BEGIN: 'GET_CUSTOM_BROKERS_CHART_BEGIN',
  GET_CUSTOM_BROKERS_CHART_SUCCESS: 'GET_CUSTOM_BROKERS_CHART_SUCCESS',
  GET_CUSTOM_BROKERS_CHART_ERROR: 'GET_CUSTOM_BROKERS_CHART_ERROR',

  GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_BEGIN:
    'GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_BEGIN',
  GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_SUCCESS:
    'GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_SUCCESS',
  GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_ERROR:
    'GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_ERROR',

  getDashboardsBegın: () => {
    return {
      type: actions.GET_DASHBOARDS_BEGIN,
    };
  },

  getDashboardsSuccess: data => {
    return {
      type: actions.GET_DASHBOARDS_SUCCESS,
      data,
    };
  },

  getDashboardsError: err => {
    return {
      type: actions.GET_DASHBOARDS_ERROR,
      err,
    };
  },

  getInvoicesDataBegin: () => {
    return {
      type: actions.GET_INVOICESDATA_BEGIN,
    };
  },

  getInvoicesDataSuccess: data => {
    return {
      type: actions.GET_INVOICESDATA_SUCCESS,
      data,
    };
  },

  getInvoicesDataError: err => {
    return {
      type: actions.GET_INVOICESDATA_SUCCESS,
      err,
    };
  },

  getShipmentDataBegin: () => {
    return {
      type: actions.GET_SHIPMENTDATA_BEGIN,
    };
  },

  getShipmentDataSuccess: data => {
    return {
      type: actions.GET_SHIPMENTDATA_SUCCESS,
      data,
    };
  },

  getShipmentDataError: err => {
    return {
      type: actions.GET_SHIPMENTDATA_ERROR,
    };
  },

  getProductDeclarationBegin: () => {
    return {
      type: actions.GET_PRODUCTDECLARATION_BEGIN,
    };
  },

  getProductDeclarationSuccess: data => {
    return {
      type: actions.GET_PRODUCTDECLARATION_SUCCESS,
      data,
    };
  },

  getProductDeclarationsError: err => {
    return {
      type: actions.GET_DASHBOARDS_ERROR,
      err,
    };
  },

  getEtaBegin: () => {
    return {
      type: actions.GET_ETA_BEGIN,
    };
  },

  getEtaSuccess: data => {
    return {
      type: actions.GET_ETA_SUCCESS,
      data,
    };
  },

  getEtaError: err => {
    return {
      type: actions.GET_ETA_ERROR,
      err,
    };
  },

  getTalepBegin: () => {
    return {
      type: actions.GET_TALEP_BEGIN,
    };
  },

  getTalepSuccess: data => {
    return {
      type: actions.GET_TALEP_SUCCESS,
      data,
    };
  },

  getTalepError: err => {
    return {
      type: actions.GET_TALEP_ERROR,
      err,
    };
  },

  getProductBegin: () => {
    return {
      type: actions.GET_PRODUCT_BEGIN,
    };
  },

  getProductSuccess: data => {
    return {
      type: actions.GET_PRODUCT_SUCCESS,
      data,
    };
  },

  getProductError: err => {
    return {
      type: actions.GET_PRODUCT_ERROR,
      err,
    };
  },

  getNewOrdersWidgetBegin: () => {
    return {
      type: actions.GET_NEW_ORDERS_WIDGET_BEGIN,
    };
  },

  getNewOrdersWidgetSuccess: data => {
    return {
      type: actions.GET_NEW_ORDERS_WIDGET_SUCCESS,
      data,
    };
  },

  getNewOrdersWidgetError: err => {
    return {
      type: actions.GET_NEW_ORDERS_WIDGET_ERROR,
      err,
    };
  },

  getDelayedOrdersWidgetBegin: () => {
    return {
      type: actions.GET_DELAYED_ORDERS_WIDGET_BEGIN,
    };
  },

  getDelayedOrdersWidgetSuccess: data => {
    return {
      type: actions.GET_DELAYED_ORDERS_WIDGET_SUCCESS,
      data,
    };
  },

  getDelayedOrdersWidgetError: err => {
    return {
      type: actions.GET_DELAYED_ORDERS_WIDGET_ERROR,
      err,
    };
  },

  getUnapprovedProductsWidgetBegin: () => {
    return {
      type: actions.GET_UNAPPROVED_PRODUCTS_WIDGET_BEGIN,
    };
  },

  getUnapprovedProductsWidgetSuccess: data => {
    return {
      type: actions.GET_UNAPPROVED_PRODUCTS_WIDGET_SUCCESS,
      data,
    };
  },

  getUnapprovedProductsWidgetError: err => {
    return {
      type: actions.GET_UNAPPROVED_PRODUCTS_WIDGET_ERROR,
      err,
    };
  },

  getExpiredDocumentsWidgetBegin: () => {
    return {
      type: actions.GET_EXPIRED_DOCUMENTS_WIDGET_BEGIN,
    };
  },

  getExpiredDocumentsWidgetSuccess: data => {
    return {
      type: actions.GET_EXPIRED_DOCUMENTS_WIDGET_SUCCESS,
      data,
    };
  },

  getExpiredDocumentsWidgetError: err => {
    return {
      type: actions.GET_EXPIRED_DOCUMENTS_WIDGET_ERROR,
      err,
    };
  },
  getOrdersRateChartBegin: () => {
    return {
      type: actions.GET_ORDERS_RATE_CHART_BEGIN,
    };
  },

  getOrdersRateChartSuccess: data => {
    return {
      type: actions.GET_ORDERS_RATE_CHART_SUCCESS,
      data,
    };
  },

  getOrdersRateChartError: err => {
    return {
      type: actions.GET_ORDERS_RATE_CHART_ERROR,
      err,
    };
  },
  getRateOfVendorsChartBegin: () => {
    return {
      type: actions.GET_RATE_OF_VENDORS_CHART_BEGIN,
    };
  },

  getRateOfVendorsChartSuccess: data => {
    return {
      type: actions.GET_RATE_OF_VENDORS_CHART_SUCCESS,
      data,
    };
  },

  getRateOfVendorsChartError: err => {
    return {
      type: actions.GET_RATE_OF_VENDORS_CHART_ERROR,
      err,
    };
  },
  getMostOrderedProductsBegin: () => {
    return {
      type: actions.GET_MOST_ORDERED_PRODUCTS_BEGIN,
    };
  },

  getMostOrderedProductsSuccess: data => {
    return {
      type: actions.GET_MOST_ORDERED_PRODUCTS_SUCCESS,
      data,
    };
  },

  getMostOrderedProductsError: err => {
    return {
      type: actions.GET_MOST_ORDERED_PRODUCTS_ERROR,
      err,
    };
  },
  getCustomBrokersChartBegin: () => {
    return {
      type: actions.GET_CUSTOM_BROKERS_CHART_BEGIN,
    };
  },

  getCustomBrokersChartSuccess: data => {
    return {
      type: actions.GET_CUSTOM_BROKERS_CHART_SUCCESS,
      data,
    };
  },

  getCustomBrokersChartError: err => {
    return {
      type: actions.GET_CUSTOM_BROKERS_CHART_ERROR,
      err,
    };
  },

  getDelayedDepartureAndArrivalChartBegin: () => {
    return {
      type: actions.GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_BEGIN,
    };
  },

  getDelayedDepartureAndArrivalChartSuccess: data => {
    return {
      type: actions.GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_SUCCESS,
      data,
    };
  },

  getDelayedDepartureAndArrivalChartError: err => {
    return {
      type: actions.GET_DELAYED_DEPARTURE_AND_ARRIVAL_CHART_ERROR,
      err,
    };
  },
};

export default actions;
