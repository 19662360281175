const actions = {
  UPLOAD_ZIP_BEGIN: 'UPLOAD_ZIP_BEGIN',
  UPLOAD_ZIP_SUCCESS: 'UPLOAD_ZIP_SUCCESS',
  UPLOAD_ZIP_ERROR: 'UPLOAD_ZIP_ERROR',

  DOWNLOAD_FILE_BEGIN: 'DOWNLOAD_FILE_BEGIN',
  DOWNLOAD_FILE_SUCCESS: 'DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_ERROR: 'DOWNLOAD_FILE_ERROR',

  DOWNLOAD_EXCEL_BEGIN: 'DOWNLOAD_EXCEL_BEGIN',
  DOWNLOAD_EXCEL_SUCCESS: 'DOWNLOAD_EXCEL_SUCCESS',
  DOWNLOAD_EXCEL_ERROR: 'DOWNLOAD_EXCEL_ERROR',

  BOOKING_COMPLETE_BEGIN: 'BOOKING_COMPLETE_BEGIN',
  BOOKING_COMPLETE_SUCCESS: 'BOOKING_COMPLETE_SUCCESS',
  BOOKING_COMPLETE_ERROR: 'BOOKING_COMPLETE_ERROR',

  BOOKING_CHECK_BEGIN: 'BOOKING_CHECK_BEGIN',
  BOOKING_CHECK_SUCCESS: 'BOOKING_CHECK_SUCCESS',
  BOOKING_CHECK_ERROR: 'BOOKING_CHECK_ERROR',

  BOOKING_FILE_CHECK_BEGIN: 'BOOKING_FILE_CHECK_BEGIN',
  BOOKING_FILE_CHECK_SUCCESS: 'BOOKING_FILE_CHECK_SUCCESS',
  BOOKING_FILE_CHECK_ERROR: 'BOOKING_FILE_CHECK_ERROR',

  BOOKING_LIST_BEGIN: 'BOOKING_LIST_BEGIN',
  BOOKING_LIST_SUCCESS: 'BOOKING_LIST_SUCCESS',
  BOOKING_LIST_ERROR: 'BOOKING_LIST_ERROR',

  BOOKING_UPDATE_OR_CREATE_BEGIN: 'BOOKING_UPDATE_OR_CREATE_BEGIN',
  BOOKING_UPDATE_OR_CREATE_SUCCESS: 'BOOKING_UPDATE_OR_CREATE_SUCCESS',
  BOOKING_UPDATE_OR_CREATE_ERROR: 'BOOKING_UPDATE_OR_CREATE_ERROR',

  BOOKING_TRANSFER_BEGIN: 'BOOKING_TRANSFER_BEGIN',
  BOOKING_TRANSFER_SUCCESS: 'BOOKING_TRANSFER_SUCCESS',
  BOOKING_TRANSFER_ERROR: 'BOOKING_TRANSFER_ERROR',

  BOOKING_DELETE_BEGIN: 'BOOKING_DELETE_BEGIN',
  BOOKING_DELETE_SUCCESS: 'BOOKING_DELETE_SUCCESS',
  BOOKING_DELETE_ERROR: 'BOOKING_DELETE_ERROR',

  BOOKING_BEYANNAME_KONTROL_BEGIN: 'BOOKING_BEYANNAME_KONTROL_BEGIN',
  BOOKING_BEYANNAME_KONTROL_SUCCESS: 'BOOKING_BEYANNAME_KONTROL_SUCCESS',
  BOOKING_BEYANNAME_KONTROL_ERROR: 'BOOKING_BEYANNAME_KONTROL_ERROR',

  BOOKING_BEYANNAME_INFO_BEGIN: 'BOOKING_BEYANNAME_INFO_BEGIN',
  BOOKING_BEYANNAME_INFO_SUCCESS: 'BOOKING_BEYANNAME_INFO_SUCCESS',
  BOOKING_BEYANNAME_INFO_ERROR: 'BOOKING_BEYANNAME_INFO_ERROR',

  BOOKING_BEYANNAME_BEGIN: 'BOOKING_BEYANNAME_BEGIN',
  BOOKING_BEYANNAME_SUCCESS: 'BOOKING_BEYANNAME_SUCCESS',
  BOOKING_BEYANNAME_ERROR: 'BOOKING_BEYANNAME_ERROR',

  GET_SETTINGS_BEGIN: 'GET_SETTINGS_BEGIN',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_ERROR: 'GET_SETTINGS_ERROR',

  uploadZipBegin: () => {
    return {
      type: actions.UPLOAD_ZIP_BEGIN,
    };
  },

  uploadZipSuccess: data => {
    return {
      type: actions.UPLOAD_ZIP_SUCCESS,
      data,
    };
  },

  uploadZipError: err => {
    return {
      type: actions.UPLOAD_ZIP_ERROR,
      err,
    };
  },

  downloadExcelBegin: () => {
    return {
      type: actions.DOWNLOAD_EXCEL_BEGIN,
    };
  },

  downloadExcelSuccess: data => {
    return {
      type: actions.DOWNLOAD_EXCEL_SUCCESS,
      data,
    };
  },

  downloadExcelError: err => {
    return {
      type: actions.DOWNLOAD_EXCEL_ERROR,
      err,
    };
  },

  downloadFileBegin: () => {
    return {
      type: actions.DOWNLOAD_FILE_BEGIN,
    };
  },

  downloadFileSuccess: data => {
    return {
      type: actions.DOWNLOAD_FILE_SUCCESS,
      data,
    };
  },

  downloadFileError: err => {
    return {
      type: actions.DOWNLOAD_FILE_ERROR,
      err,
    };
  },

  bookingCompleteBegin: () => {
    return {
      type: actions.BOOKING_COMPLETE_BEGIN,
    };
  },

  bookingCompleteSuccess: () => {
    return {
      type: actions.BOOKING_COMPLETE_SUCCESS,
    };
  },

  bookingCompleteError: err => {
    return {
      type: actions.BOOKING_COMPLETE_ERROR,
      err,
    };
  },

  bookingCheckBegin: () => {
    return {
      type: actions.BOOKING_CHECK_BEGIN,
    };
  },

  bookingCheckSuccess: () => {
    return {
      type: actions.BOOKING_CHECK_SUCCESS,
    };
  },

  bookingCheckError: () => {
    return {
      type: actions.BOOKING_CHECK_ERROR,
    };
  },

  bookingFileCheckBegin: () => {
    return {
      type: actions.BOOKING_FILE_CHECK_BEGIN,
    };
  },

  bookingFileCheckSuccess: () => {
    return {
      type: actions.BOOKING_FILE_CHECK_SUCCESS,
    };
  },

  bookingFileCheckError: () => {
    return {
      type: actions.BOOKING_FILE_CHECK_ERROR,
    };
  },

  bookingListBegin: () => {
    return {
      type: actions.BOOKING_LIST_BEGIN,
    };
  },

  bookingListSuccess: data => {
    return {
      type: actions.BOOKING_LIST_SUCCESS,
      data,
    };
  },

  bookingListError: err => {
    return {
      type: actions.BOOKING_LIST_ERROR,
      err,
    };
  },

  bookingUpdateOrCreateBegin: () => {
    return {
      type: actions.BOOKING_UPDATE_OR_CREATE_BEGIN,
    };
  },

  bookingUpdateOrCreateSuccess: data => {
    return {
      type: actions.BOOKING_UPDATE_OR_CREATE_SUCCESS,
      data,
    };
  },

  bookingUpdateOrCreateError: err => {
    return {
      type: actions.BOOKING_UPDATE_OR_CREATE_ERROR,
      err,
    };
  },

  bookingTransferBegin: () => {
    return {
      type: actions.BOOKING_TRANSFER_BEGIN,
    };
  },

  bookingTransferSuccess: data => {
    return {
      type: actions.BOOKING_TRANSFER_SUCCESS,
      data,
    };
  },

  bookingTransferError: err => {
    return {
      type: actions.BOOKING_TRANSFER_ERROR,
      err,
    };
  },

  bookingDeleteBegin: () => {
    return {
      type: actions.BOOKING_COMPLETE_BEGIN,
    };
  },

  bookingDeleteSuccess: data => {
    return {
      type: actions.BOOKING_DELETE_SUCCESS,
      data,
    };
  },

  bookingDeleteError: err => {
    return {
      type: actions.BOOKING_DELETE_ERROR,
      err,
    };
  },

  bookingBeyannameKontrolBegin: () => {
    return {
      type: actions.BOOKING_BEYANNAME_KONTROL_BEGIN,
    };
  },

  bookingBeyannameKontrolSuccess: data => {
    return {
      type: actions.BOOKING_BEYANNAME_KONTROL_SUCCESS,
      data,
    };
  },

  bookingBeyannameKontrolError: err => {
    return {
      type: actions.BOOKING_BEYANNAME_KONTROL_ERROR,
      err,
    };
  },

  bookingBeyannameInfoBegin: () => {
    return {
      type: actions.BOOKING_BEYANNAME_INFO_BEGIN,
    };
  },

  bookingBeyannameInfoSuccess: data => {
    return {
      type: actions.BOOKING_BEYANNAME_INFO_SUCCESS,
      data,
    };
  },

  bookingBeyannameInfoError: err => {
    return {
      type: actions.BOOKING_BEYANNAME_INFO_ERROR,
      err,
    };
  },

  bookingBeyannnameBegin: () => {
    return {
      type: actions.BOOKING_BEYANNAME_BEGIN,
    };
  },

  bookingBeyannameSuccess: data => {
    return {
      type: actions.BOOKING_BEYANNAME_SUCCESS,
      data,
    };
  },

  bookingBeyannameError: err => {
    return {
      type: actions.BOOKING_BEYANNAME_ERROR,
      err,
    };
  },

  getSettingsBegin: () => {
    return {
      type: actions.GET_SETTINGS_BEGIN,
    };
  },

  getSettingsSuccess: data => {
    return {
      type: actions.GET_SETTINGS_SUCCESS,
      data,
    };
  },

  getSettingsError: err => {
    return {
      type: actions.GET_SETTINGS_ERROR,
      err,
    };
  },
};

export default actions;
