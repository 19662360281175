export default {
  gatesTitle: 'Mutabakat Raporu',
  company: 'Firma Seçim',
  uploadButtonName: 'Sadece xlsx, xls seçin',
  excelErrorMessage: 'Lütfen Excel Seçiniz',
  errorReportTypeMessage: 'Lütfen Rapor Tipi Seçiniz.',
  errorCompanyMessage: 'Lütfen Firma Seçiniz.',
  reportTypeLabel: 'Rapor Tipi',
  columnLabel: 'Lütfen Kolon Seçiniz',
  uploadError: '{{ fileName }} bir xlsx veya xls dosyası değil',
  anyBeforeUploadError: '{{fileName}} bir xlsx,xls veya pdf dosyası değil. ',
};
