import actions from './actions';

const {
  POST_PRIVATE_GATES_BEGIN,
  POST_PRIVATE_GATES_SUCCESS,
  POST_PRIVATE_GATES_ERROR,
  GET_PRIVATE_GATES_DOWNLOAD_BEGIN,
  GET_PRIVATE_GATES_DOWNLOAD_SUCCESS,
  GET_PRIVATE_GATES_DOWNLOAD_ERROR,
} = actions;

const initState = {
  privateGatesReportColumn: [],
  loading: false,
  error: null,
};

const reportReducer = (state = initState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case POST_PRIVATE_GATES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case POST_PRIVATE_GATES_SUCCESS:
      return {
        ...state,
        loading: false,
        privateGatesReportColumn: data,
      };
    case POST_PRIVATE_GATES_ERROR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_PRIVATE_GATES_DOWNLOAD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PRIVATE_GATES_DOWNLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_PRIVATE_GATES_DOWNLOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    default:
      return state;
  }
};

export { reportReducer };
