const actions = {
  GET_MENU_BEGIN: 'GET_MENU_BEGIN',
  GET_MENU_SUCCESS: 'GET_MENU_SUCCESS',
  GET_MENU_ERROR: 'GET_MENU_ERROR',

  GET_ROLE_BEGIN: 'GET_ROLE_BEGIN',
  GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
  GET_ROLE_ERROR: 'GET_ROLE_ERROR',

  GET_USER_BEGIN: 'GET_USER_BEGIN',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_ERROR: 'GET_USER_ERROR',

  CLEAR_ERROR: 'CLEAR_ERROR',

  getMenuBegin: () => {
    return {
      type: actions.GET_MENU_BEGIN,
    };
  },

  getMenuSuccess: data => {
    return {
      type: actions.GET_MENU_SUCCESS,
      data,
    };
  },

  getMenuError: err => {
    return {
      type: actions.GET_MENU_ERROR,
      err,
    };
  },

  getRoleBegin: () => {
    return {
      type: actions.GET_ROLE_BEGIN,
    };
  },

  getRoleSuccess: data => {
    return {
      type: actions.GET_ROLE_SUCCESS,
      data,
    };
  },

  getRoleError: err => {
    return {
      type: actions.GET_ROLE_ERROR,
      err,
    };
  },

  getUserBegin: () => {
    return {
      type: actions.GET_USER_BEGIN,
    };
  },

  getUserSuccess: data => {
    return {
      type: actions.GET_USER_SUCCESS,
      data,
    };
  },

  getUserError: err => {
    return {
      type: actions.GET_USER_ERROR,
      err,
    };
  },

  clearError: () => {
    return {
      type: actions.CLEAR_ERROR,
    };
  },
};

export default actions;
