import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { Popover } from '../../popup/popup';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { updateUserPassword } from '../../../redux/common/actionCreator';
import { useDispatch } from 'react-redux';
import { logOut } from '../../../redux/authentication/actionCreator';

const SifreGuncelle = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onFinish = async values => {
    let result = await dispatch(
      updateUserPassword({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        newPasswordAgain: values.newPasswordAgain,
      })
    );

    if (result.status === 200) {
      message.success('Kullanıcı bilgileri güncellendi!');
      setTimeout(() => {
        setLoading(false);
        dispatch(logOut());
      }, 2000);
    } else {
      message.warning(result.statusText);
    }
  };

  const onFinishFailed = errorInfo => {
    message.error('Lütfen Formu Doldurunuz.');
  };

  const content = (
    <Form
      form={form}
      name="update_user"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      style={{ maxWidth: '400px', margin: '0 auto' }}
    >
      <Form.Item
        label="Mevcut Şifre"
        name="currentPassword"
        rules={[{ required: true, message: 'Mevcut şifrenizi girin!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Yeni Şifre"
        name="newPassword"
        rules={[
          { required: true, message: 'Yeni şifrenizi girin!' },
          { min: 6, message: 'Şifre en az 6 karakter olmalıdır.' },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Şifre Tekrar"
        name="newPasswordAgain"
        rules={[
          { required: true, message: 'Yeni şifrenizi girin!' },
          { min: 6, message: 'Şifre en az 6 karakter olmalıdır.' },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Güncelle
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <div className="notification">
      <Popover placement="leftBottom" content={content} action="click">
        <Link to="#" className="head-example">
          <FeatherIcon icon="user" size={20} /> Şifre İşlemleri
        </Link>
      </Popover>
    </div>
  );
};

export default SifreGuncelle;
