import React from 'react';
import PropTypes from 'prop-types';
import { ButtonStyledGroup, ButtonStyled } from './styled';

// @Todo props spreading

const ButtonComponent = props => {
  const {
    type,
    shape,
    icon,
    style,
    size,
    outlined,
    ghost,
    transparented,
    raised,
    squared,
    color,
    social,
    load,
    onHandle,
    children,
    disabled,
    buttonRef,
    ...rest
  } = props;

  // const [state, setState] = useState({
  //   loading: false,
  // });

  // const enterLoading = () => {
  //   setState({ loading: true });
  // };
  return (
    <ButtonStyled
      squared={squared}
      outlined={outlined ? 1 : 0}
      ghost={ghost}
      transparent={transparented ? 1 : 0}
      raised={raised ? 1 : 0}
      data={type}
      size={size}
      shape={shape}
      type={type}
      icon={icon}
      style={style}
      color={color}
      social={social}
      onClick={() => onHandle()}
      loading={load}
      disabled={disabled}
      ref={buttonRef}
      {...rest}
    >
      {children}
    </ButtonStyled>
  );
};

ButtonComponent.defaultProps = {
  type: 'default',
};

ButtonComponent.propTypes = {
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'link',
    'dark',
    'light',
    'white',
    'dashed',
    'error',
    'default',
  ]),
  shape: PropTypes.string,
  icon: PropTypes.object,
  style: PropTypes.object,
  size: PropTypes.string,
  color: PropTypes.string,
  outlined: PropTypes.bool,
  transparented: PropTypes.bool,
  raised: PropTypes.bool,
  squared: PropTypes.bool,
  social: PropTypes.bool,
  onHandle: PropTypes.func.isRequired,
  load: PropTypes.bool,
  ghost: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
  buttonRef: PropTypes.object,
};

const BtnGroup = ({ children }) => {
  return <ButtonStyledGroup>{children}</ButtonStyledGroup>;
};

BtnGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export { ButtonComponent, BtnGroup };
