import React from 'react';
import { useSelector } from 'react-redux';

const hasPermission = (roles, roleGroupId, roleLevel) => {
  if (
    !roles.find(
      role => role.grup.id === roleGroupId // roleGrup hiç bulunmuyor ise full yetkilidir ondan dolayı önyüzde roleGroup doğru verilmeli
    )
  ) {
    return true;
  } else if (
    roleLevel !== 0 &&
    roles.find(
      role => role.grup.id === roleGroupId && role.rolSeviye === roleLevel // Roles tablosundaki seviye prop olarak gönderilmeli seviye 0 ise yetkisizdir
    )
  ) {
    return true;
  } else {
    return false;
  }
};

const Permission = ({ roleGroupId, roleLevel, children }) => {
  const roles = useSelector(state => state.users.role);

  const hasPermissionResult = hasPermission(roles, roleGroupId, roleLevel);

  if (!hasPermissionResult) return <></>;

  return children;
};

export { hasPermission, Permission };
