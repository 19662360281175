import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import trTR from 'antd/lib/locale/tr_TR';
import store from './redux/store';
import Admin from './routes/Menus';
import Auth from './routes/auth';
import './../src/translations/i18n';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';

const { theme } = config;

const ProviderConfig = () => {
  const { rtl, isLoggedIn, topMenu, darkMode, language } = useSelector(
    state => {
      return {
        darkMode: state.ChangeLayoutMode.data,
        rtl: state.ChangeLayoutMode.rtlData,
        topMenu: state.ChangeLayoutMode.topMenu,
        isLoggedIn: state.auth.login,
        language: state.ChangeLayoutMode.language,
      };
    }
  );

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    // Login Logout yönlendirmeme problemi bu koşulun 52. satırda sağlanmamasından kaynaklı olduğundan
    // Bu koşul true değilse path / ile güncellenmekte.
    // `${process.env.PUBLIC_URL}/` değişkenin değeri /'dır.
    if (!(path === `${process.env.PUBLIC_URL}/`)) {
      setPath(path);
    }
  }, [path, setPath]);

  return (
    <ConfigProvider
      locale={language === 'tr-TR' ? trTR : enUS}
      direction={rtl ? 'rtl' : 'ltr'}
    >
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={'/'}>
          {!isLoggedIn ? (
            <Route path={'/'} component={Auth} />
          ) : (
            <>
              <ProtectedRoute path={'/'} component={Admin} />
            </>
          )}
          {isLoggedIn &&
            (path === process.env.PUBLIC_URL ||
              path === `${process.env.PUBLIC_URL}/`) && <Redirect to="/" />}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}
export default hot(App);
