import common from './common';
import privateReport from './privateReport';
import acente from './acente';
import antrepoStok from './antrepoStok';

export const TRANSLATIONS_EN = {
  ...common,
  ...privateReport,
  ...acente,
  ...antrepoStok,
};
