import actions from './actions';

const {
  UPLOAD_ZIP_BEGIN,
  UPLOAD_ZIP_SUCCESS,
  UPLOAD_ZIP_ERROR,
  DOWNLOAD_FILE_BEGIN,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_ERROR,
  DOWNLOAD_EXCEL_BEGIN,
  DOWNLOAD_EXCEL_SUCCESS,
  DOWNLOAD_EXCEL_ERROR,
  BOOKING_COMPLETE_BEGIN,
  BOOKING_COMPLETE_SUCCESS,
  BOOKING_COMPLETE_ERROR,
  BOOKING_CHECK_BEGIN,
  BOOKING_CHECK_SUCCESS,
  BOOKING_CHECK_ERROR,
  BOOKING_FILE_CHECK_BEGIN,
  BOOKING_FILE_CHECK_SUCCESS,
  BOOKING_FILE_CHECK_ERROR,
  BOOKING_LIST_BEGIN,
  BOOKING_LIST_SUCCESS,
  BOOKING_LIST_ERROR,
  BOOKING_UPDATE_OR_CREATE_BEGIN,
  BOOKING_UPDATE_OR_CREATE_SUCCESS,
  BOOKING_UPDATE_OR_CREATE_ERROR,
  BOOKING_TRANSFER_BEGIN,
  BOOKING_TRANSFER_SUCCESS,
  BOOKING_TRANSFER_ERROR,
  BOOKING_DELETE_BEGIN,
  BOOKING_DELETE_SUCCESS,
  BOOKING_DELETE_ERROR,
  BOOKING_BEYANNAME_KONTROL_BEGIN,
  BOOKING_BEYANNAME_KONTROL_SUCCESS,
  BOOKING_BEYANNAME_KONTROL_ERROR,
  BOOKING_BEYANNAME_INFO_BEGIN,
  BOOKING_BEYANNAME_INFO_SUCCESS,
  BOOKING_BEYANNAME_INFO_ERROR,
  BOOKING_BEYANNAME_BEGIN,
  BOOKING_BEYANNAME_SUCCESS,
  BOOKING_BEYANNAME_ERROR,
  GET_SETTINGS_BEGIN,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_ERROR,
} = actions;

const initState = {
  data: [],
  loading: false,
  updateLoading: false,
  error: null,
};

const exportReducer = (state = initState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case UPLOAD_ZIP_BEGIN:
    case BOOKING_COMPLETE_BEGIN:
    case BOOKING_CHECK_BEGIN:
    case BOOKING_TRANSFER_BEGIN:
    case BOOKING_DELETE_BEGIN:
    case GET_SETTINGS_BEGIN:
    case BOOKING_FILE_CHECK_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case BOOKING_LIST_BEGIN:
    case BOOKING_UPDATE_OR_CREATE_BEGIN:
    case BOOKING_BEYANNAME_KONTROL_BEGIN:
    case BOOKING_BEYANNAME_INFO_BEGIN:
    case BOOKING_BEYANNAME_BEGIN:
    case DOWNLOAD_FILE_BEGIN:
    case DOWNLOAD_EXCEL_BEGIN:
      return {
        ...state,
        updateLoading: true,
        error: null,
      };
    case UPLOAD_ZIP_SUCCESS:
    case BOOKING_COMPLETE_SUCCESS:
    case BOOKING_CHECK_SUCCESS:
    case BOOKING_TRANSFER_SUCCESS:
    case BOOKING_DELETE_SUCCESS:
    case BOOKING_FILE_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: data,
      };
    case BOOKING_BEYANNAME_INFO_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        info: data,
      };
    case BOOKING_BEYANNAME_KONTROL_SUCCESS:
    case BOOKING_BEYANNAME_SUCCESS:
      return {
        ...state,
        updateLoading: false,
      };
    case DOWNLOAD_FILE_SUCCESS:
    case DOWNLOAD_EXCEL_SUCCESS:
    case BOOKING_LIST_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        data: data,
      };
    case BOOKING_UPDATE_OR_CREATE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        data: data,
      };
    case UPLOAD_ZIP_ERROR:
    case DOWNLOAD_FILE_ERROR:
    case DOWNLOAD_EXCEL_ERROR:
    case BOOKING_COMPLETE_ERROR:
    case BOOKING_CHECK_ERROR:
    case BOOKING_LIST_ERROR:
    case BOOKING_UPDATE_OR_CREATE_ERROR:
    case BOOKING_TRANSFER_ERROR:
    case BOOKING_DELETE_ERROR:
    case BOOKING_BEYANNAME_KONTROL_ERROR:
    case BOOKING_BEYANNAME_INFO_ERROR:
    case BOOKING_BEYANNAME_ERROR:
    case GET_SETTINGS_ERROR:
    case BOOKING_FILE_CHECK_ERROR:
      return {
        ...state,
        loading: false,
        updateLoading: false,
        error: err,
      };
    default:
      return state;
  }
};

export { exportReducer };
