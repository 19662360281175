export default {
  exportBookingTitle: 'Vessel Data Upload',
  totalBooking: 'Total Booking: ',
  totalShip: 'Total Ship: ',
  errorSelectFile: 'Please Select Zip File',
  ship: 'Ship',
  expeditionNo: 'Expedition No',
  acenteUploadError: '{{ fileName }} file uploaded successfully',
  acenteUploadWarning: '{{ fileName }} is not a zip file',
  acenteUploadSuccess: '{{ fileName }} file uploaded successfully',
  addKonteyner: 'Add Container',
  konteynerNo: 'Container No',
  konteynerTipi: 'Size',
  konteynerDara: 'Tare',
  konteynerKiymet: 'Value',
  konteynerSoMu: 'So?',
  kTarih: 'Create Date',
  dTarih: 'Edit Date',
  totalKontainer: 'Total Container: ',
  konteynerSilmeMesaji:
    'Are you sure you want to delete your container number {{konteynerNo}} ?',
  konteynerBilgileri: 'Container Info',
  exportUploadConfirm:
    'The booking file named {{ fileName }} is installed.Do you want to make a correction?',
  selectShipPlaceholder: 'Select Ship',
  expeditionNoPlaceholder: 'Enter the Expedition Number',
  exportEntryTitle: 'Export Entry',
  exportErrorMessage: 'Please fill in the Ship and Expedition No fields',
  exportCopyMessage: 'Please select the line to copy.',
  exportValidationMessage: 'Please fill in your Goods Code column',
  exportValidationMessage2:
    'Please Enter Valid Container No. {{ containerNo }}',
  addRow: 'Add Row',
  declarationCount: 'Declaration Count: {{ count }}',
  total: 'Total: {{ count }}',
  entered: 'Entered: {{ count }}',
  missing: 'Missing: {{ count }}',
  new: 'New: {{ count }}',
  edit: 'Edit: {{ count }}',
  deleted: 'Deleted: {{ count }}',
  copyRow: 'Copy Row',
  footerInfo:
    'Customer Name: {{ musteriAdi }}, Container Number: {{ kapAdet }}, Weight: {{ kapAgirlik }}, B/L Weight: {{ blAgirlik }}, B/L Number: {{ blMiktar }}',
  shipTitle: 'Ship List',
  addShip: 'Add Ship',
  deleteShipMessage: 'Do you want to delete Ship Registry {{shipNo}}?',
  deleteContainerMessage:
    'Do you want to delete Container Registry {{ containerId }}?',
  shipCode: 'Ship Code',
  shipName: 'Ship Name',
  shipValidationMessage: 'Please Enter Ship Code',
  shipValidationMessage2: 'Ship Code must be 3 characters',
  shipValidationMessage3: 'Please Enter Ship Name',
  containerTitle: 'Container List',
  shipInfo: 'Ship Info',
  containerValidationMessage: 'Please Enter a Valid Container No.',
  containerTypeValidationMessage:
    'You cannot enter any size other than 20, 40, 45.',
  addStock: 'Add Stock',
  addStockMessage: 'Add {{ stock }}',
  orderNo: 'Order No',
  exitVessel: 'Exit Vessel',
  deleteRow: 'Delete Row',
  customerName: 'Customer Name:',
  blNumber: 'B/L Number:',
  blWeight: 'B/L Weight:',
  country: 'Country:',
  capNumber: 'Container Number:',
  capWeight: 'Container Weight:',
  targetShip: 'Target Ship',
  sourceShip: 'Source Ship',
  fileDownloadError: 'An error occurred while downloading the file.',
  transferErrorMessage: 'Please Enter Destination Ship and Expedition Number.',
  transferError: 'Transfer işlemi gerçekleştirilirken hata oluştu.',
  transferFromPool: 'Havuzdan Aktar',
  bookingByPool: 'Havuzdaki Bookingler (Konteyner Noya Göre Eşleşenler)',
  deletedBooking: 'Bookings Deleted by Fix',
  revertMessage: 'Are you sure you want to add it back to the list?',
  importEntry: 'Import Entry',
  importList: 'Import List',
  importExit: 'Import Exit',
  importArrivalDate: 'Arrival Date and Time',
  startedPort: 'Discharge Port',
  arrivalCustoms: 'Entry Customs',
  sumDeclarationNo: 'Summary Declaration No',
  sumDeclarationNoPlaceHolder: 'Enter Summary Declaration No',
  enterBookingNo: 'Enter Booking',
  enterContainerNo: 'Enter Konteyner No',
  ktfFile: 'KTF File Download',
  importExitData: 'Exit Date and Time',
  importLoadingPort: 'Loading Port',
  importExitCustoms: 'Exit Customs',
  importExitRegNo: 'Exit Registration Number',
  importExitRegNoPlaceholder: 'Enter the Exit Registration Number',
  shipSelectValidationMessage: 'Please Choose Ship',
  expeditionNoValidationMessage: 'Please Enter the Expedition No',
  registryTitle: 'Enter Registry',
  registryNo: 'Registry No',
  registryNoPlaceHolder: 'Enter Registry No',
  registryNoValidationMessage: 'Please Enter Registry No.Exp 12345678GG123456',
  exitRegistryNoValidationMessage:
    'Please Enter Registry No.Exp 12345678GG123456',
  registryDate: 'Registry Date',
  registryDateValidationMessage: 'Please Select Registry Date',
  rowValidationMessage: 'Please Select Row',
  importEntryTitle: 'Import Entry',
  importExitTitle: 'Import Exit',
  registrySuccessMessage: 'Registration Successful',
  registryErrorMessage: 'Error Occurred While Performing Registration',
  correctionCorrectPlaceholder: 'Doğru {{ key }} Giriniz',
  correctionWrongPlaceholder: 'Yanlış {{ key }} Giriniz',
};
