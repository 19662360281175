import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { DemandListScreen, DemandScreen, DemandDetailScreen } from './index';

const acenteTalepRoutes = {
  acenteTalep: DemandScreen,
  acenteTalepListesi: DemandListScreen,
  acenteTalepDetay: DemandDetailScreen,
  acenteTalepGuncelle: DemandScreen,
};

const AcenteTalepRoutes = ({ menu }) => {
  return (
    <Switch>
      {/** Burada children menüye göre düzenleme yapılacak  */}
      {/** Menüde görünen routeları render etmektedir.  */}
      {menu.map(({ id, link, key }) => {
        return (
          <Route
            key={id}
            path={link}
            component={acenteTalepRoutes[key]}
            exact
          />
        );
      })}
      {/**  Child Routeları render etmektedir.Menude listelenmemektedir. */}
      {menu.map(({ childRoute }) => {
        return (
          childRoute.length !== 0 &&
          childRoute.map(({ id, link, key }) => {
            return (
              <Route
                key={id}
                path={link}
                component={acenteTalepRoutes[key]}
                exact
              />
            );
          })
        );
      })}
    </Switch>
  );
};

export default AcenteTalepRoutes;
