import { DataService } from './../../config/dataService/dataService';
import { Endpoints } from '../../config/Endpoints/index';
import { Encryption } from '../../utility/cryptoControl';
import Cookies from 'js-cookie';
import actions from './actions';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
} = actions;

const login = (bodyData, remember) => {
  return async dispatch => {
    bodyData.email = bodyData.email.trim();
    bodyData.password = bodyData.password.trim();
    dispatch(loginBegin());
    const { data, status, statusText } = await DataService.post(
      Endpoints.Login,
      bodyData
    );

    if (remember) {
      Cookies.set('email', bodyData.email);
      Cookies.set('password', Encryption(bodyData.password, 'secret'));
    } else {
      Cookies.remove('email');
      Cookies.remove('password');
    }
    if (status === 200) {
      Cookies.set('displayName', data.displayName);
      Cookies.set('email', data.email);
      Cookies.set('phone', data.phone);
      Cookies.set('logedIn', true);
      Cookies.set('access_token', data.token, {
        expires: 7,
      });
      dispatch(loginSuccess(true));
    } else {
      dispatch(loginErr(statusText));
    }
  };
};

const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut };
