import Styled from 'styled-components';

import { Input } from 'antd';

const { TextArea } = Input;

const InputTextAreaStyle = Styled(TextArea)`
    
`;

export { InputTextAreaStyle };
