import React from 'react';
import PropTypes from 'prop-types';
import { InputTextAreaStyle } from './style';

const InputTextAreaView = ({
  rows,
  label,
  placeholder,
  name,
  value,
  onChange,
  disabled,
  style,
  containerStyle,
  autoFocus,
  suffix,
  labelStyle,
  inputRef,
  onPressEnter,
  prefix,
  onBlur,
}) => {
  const handleChange = e => {
    return onChange(name, e.target.value);
  };

  return (
    <>
      <div className="d-flex justify-content-between" style={containerStyle}>
        <span style={labelStyle ? labelStyle : { marginTop: 10 }}>{label}</span>
        <InputTextAreaStyle
          rows={rows || 4}
          placeholder={placeholder}
          style={style ? style : { maxWidth: 150 }}
          value={value || ''}
          onChange={handleChange}
          disabled={disabled}
          autoFocus={autoFocus || false}
          suffix={suffix}
          ref={inputRef}
          onPressEnter={onPressEnter}
          prefix={prefix || <></>}
          onBlur={onBlur}
        />
      </div>
    </>
  );
};

InputTextAreaView.propTypes = {
  rows: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  style: PropTypes.object,
  containerStyle: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onPressEnter: PropTypes.func,
  ref: PropTypes.object,
  label: PropTypes.string,
  labelStyle: PropTypes.object,
};

export { InputTextAreaView };
