import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'; // Dil desteği sağlayan kütüphanedir.
import LanguageDetector from 'i18next-browser-languagedetector'; // Tarayıcı dilini almayı sağlamaktadır.
import { TRANSLATIONS_TR } from './tr/translations';
import { TRANSLATIONS_EN } from './en/translations';

// Dil konfigürasyonunu yapılmaktadır.Bu konfigürasyon dosyasını App.js'in içine import etmek yeterlidir.
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources: {
        en: {
          translation: TRANSLATIONS_EN,
        },
        tr: {
          translation: TRANSLATIONS_TR,
        },
      },
      // debug: true,
    },
    err => {
      if (err) return console.log('something went wrong loading', err);
    }
  );

export default i18n;
