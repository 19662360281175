export default {
  gitReportTitle: 'GIT Raporu Yükleme',
  gitMoves: 'GIT Hareketleri',
  addNewDocument: 'Yeni Evrak Yükle',
  selectUploadFile:
    'Lütfen yüklemek istediğiniz evrakı seçiniz yada buraya sürükleyiniz.',
  anyUploadError: 'Döküman desteklenen formatlarda değil!',
  anyUploadSuccess: 'Döküman başarıyla yüklendi.',
  ggbsProductDeclarationNo: 'GGBS Ürün Bildirim No',
  ggbsProductDeclarationPassword: 'GGBS Ürün Bildirim Şifresi',
  componentListForm: 'Bileşen Listesi Formu',
  lastServiceRuntime: 'Servisin Son Çalışma Zamanı : ',
  ggbsCustomsApplyNo: ' GGBS Gümrük Başvuru No :',
  ggbsCustomsApplyDate: 'GGBS Gümrük Başvuru Tarihi :',
  healthCertificate: 'Sağlık Sertifikası :',
  gdoDeclaration: 'GDO Beyanı :',
  analyzeResult: 'Analiz Sonucu :',
  shipmentNotificationForm: 'Sevkiyat Bildirim Formu :',
  etaUpdate: 'ETA Güncelle',
  consignmentNoteTitle: 'Konşimento Takip',
  consignmentNoteNo: 'Konşimento No:',
  paymentDeclaration: 'Ödeme Bildirimi',
  paymentDeclarationNo: 'Transfer ID:',
};
