import React, { useState } from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Notification from './notification';
// import Message from './message';
// import Settings from './settings';
// import Support from './support';
import Calendar from './calendar';
import Profil from './profil';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { Permission } from '../../../components/permission/permission';
import Heading from '../../heading/heading';
import { useTranslation } from 'react-i18next';
import { logOut } from '../../../redux/authentication/actionCreator';
import { changeLanguage } from '../../../redux/themeLayout/actionCreator';
const AuthInfo = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    flag: localStorage.getItem('i18nextLng'),
  });
  const { flag } = state;

  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
  };

  const user = useSelector(state => state.users.user);

  const UserAvatar = ({ name }) => {
    if (name !== undefined) {
      // İsim ve soyismi boşluklardan ayırarak ilk harflerini alın
      const initials = name
        .split(' ')
        .map(word => word.charAt(0).toUpperCase())
        .join('');

      return (
        <Avatar
          style={{
            backgroundColor: '#00a2ae',
            paddingTop: 8,
          }}
        >
          {initials}
        </Avatar>
      );
    } else {
      return <Avatar style={{ backgroundColor: '#00a2ae' }} />;
    }
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img
            src={
              'https://cdn0.iconfinder.com/data/icons/seo-web-4-1/128/Vigor_User-Avatar-Profile-Photo-02-256.png'
            }
            height={30}
            alt=""
          />
          <figcaption>
            <Heading as="h5">{user?.displayName}</Heading>
            <p></p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Profil />
          </li>
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="settings" />
              {t('setting')}
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" />
          {t('logout')}
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    dispatch(changeLanguage(value));
    i18n.changeLanguage(value);
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle('en-US')} to="#">
        <img
          src={require('../../../static/img/flag/en-US.png')}
          alt=""
          style={{ height: 25, width: 25 }}
        />
        <span>{t('english')}</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('tr-TR')} to="#">
        <img
          src={require('../../../static/img/flag/tr-TR.png')}
          alt=""
          style={{ height: 25, width: 25 }}
        />
        <span>{t('turkey')}</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Message /> */}
      {/* <Settings /> */}
      {/* <Support /> */}
      <Permission roleGroupId={4} roleLevel={1}>
        <Calendar />
      </Permission>
      <Notification />
      <div className="nav-author">
        <Dropdown
          placement="bottomRight"
          content={country}
          trigger="click"
          style={{ width: '500px' }}
        >
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div>
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <UserAvatar name={user?.displayName} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
