import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

const TooltipView = ({ placement, title, content }) => {
  return (
    <>
      <Tooltip placement={placement} title={title}>
        {content}
      </Tooltip>
    </>
  );
};

TooltipView.defaultProps = {
  placement: 'topLeft',
};

TooltipView.propTypes = {
  placement: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export { TooltipView };
