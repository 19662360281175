import actions from './actions';

const {
  GET_MENU_BEGIN,
  GET_MENU_SUCCESS,
  GET_MENU_ERROR,
  GET_ROLE_BEGIN,
  GET_ROLE_SUCCESS,
  GET_ROLE_ERROR,
  GET_USER_BEGIN,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  CLEAR_ERROR,
} = actions;

const initState = {
  menu: [],
  role: [],
  user: [],
  loading: false,
  error: false,
};

const userReducer = (state = initState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case GET_MENU_BEGIN:
    case GET_USER_BEGIN:
    case GET_ROLE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        menu: data,
      };
    case GET_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        role: data,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: data,
      };
    case GET_MENU_ERROR:
    case GET_USER_ERROR:
    case GET_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export { userReducer };
