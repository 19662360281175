import { lazy } from 'react';

const GatesInvoiceScreen = lazy(() =>
  import('../../../screen/Gates/GatesInvoiceScreen/GatesInvoiceScreen')
);

const GatesInvoiceUpdateScreen = lazy(() =>
  import(
    '../../../screen/Gates/GatesInvoiceUpdateScreen/GatesInvoiceUpdateScreen'
  )
);

const GatesTransferredInvoiceScreen = lazy(() =>
  import(
    '../../../screen/Gates/GatesTransferredInvoiceScreen/GatesTransferredInvoiceScreen'
  )
);

const GatesUBFDraftScreen = lazy(() =>
  import('../../../screen/Gates/GatesUBFDraftScreen/GatesUBFDraftScreen')
);

export {
  GatesInvoiceScreen,
  GatesInvoiceUpdateScreen,
  GatesTransferredInvoiceScreen,
  GatesUBFDraftScreen,
};
