import { lazy } from 'react';

const ShipScreen = lazy(() =>
  import('../../../screen/Common/ShipScreen/ShipScreen')
);

const ContainerScreen = lazy(() =>
  import('../../../screen/Common/ContainerScreen/ContainerList')
);

const DocumentScreen = lazy(() =>
  import('../../../screen/Common/DocumentList/DocumentList')
);

const CompanyScreen = lazy(() =>
  import('../../../screen/Common/FirmaIslemleri/FirmaIslemleri')
);

const TaskTrackingScreen = lazy(() =>
  import('../../../screen/Common/TaskTrackingScreen/TaskTrackingScreen')
);

const UserListScreen = lazy(() =>
  import('../../../screen/Common/UserListScreen/UserListScreen')
);

const WarehouseDefinitions = lazy(() =>
  import(
    '../../../screen/AntrepoStok/WarehouseDefinitions/WarehouseDefinitions'
  )
);

const BankDefinitions = lazy(() =>
  import('../../../screen/Common/BankDefinitions/BankDefinitions')
);

const AgentDefinitions = lazy(() =>
  import('../../../screen/AntrepoStok/AgentDefinitions/AgentDefinitions')
);

const MasrafEtiket = lazy(() =>
  import('../../../screen/Common/MasrafEtiket/MasrafEtiket')
);

const DonusDepoList = lazy(() =>
  import('../../../screen/Common/DonusDepoList/DonusDepoList')
);
export {
  ShipScreen,
  ContainerScreen,
  DocumentScreen,
  CompanyScreen,
  TaskTrackingScreen,
  UserListScreen,
  WarehouseDefinitions,
  BankDefinitions,
  AgentDefinitions,
  MasrafEtiket,
  DonusDepoList,
};
